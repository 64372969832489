@import "../../scss/index.scss";

.video_modal {
    .modal-dialog{
        width: 100%;
        max-width: 900px;
        .modal-content{
            border-radius: 16px;
            overflow: hidden;
            flex-direction: initial;
        }
        .modal-body{
            background-color: $red-color;
            padding: 0;
            color: $red-color;
            .close_modal{
                text-align: center;
                position: absolute;
                right: 5px;
                top: -5px;
                font-size: 30px;
                cursor: pointer;
                z-index: 9;
                
            }
            video{
                width: 100%;
                height: 550px;
                object-fit: cover;
                @include break-point(mobile){
                    height: auto;
                }
            }
        }
    }
}