@import "../../../scss/index.scss";

.ShubhBharat {
	.banner {
		background-image: url("../../../assets/images/shubhBharatbg.png");
	}

	.currrent_affairs_card {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.content {
			position: relative;
			margin: 15px 10px;
			max-width: 48%;
			@include break-point(landscapeTab) {
				max-width: 30%;
			}
			@include break-point(miniTab) {
				max-width: 320px;
			}
			.bookmark {
				top: -11px;
				font-size: 24px;
			}
		}
	}
	.latest_articles {
		.form_field {
			text-align: right;
			@include break-point(mobile){
				text-align: center;
			}
			input {
				max-width: 300px;
			}
		}
		.container {
			max-width: 1200px;
		}
	}
	.trending_articles {
		.currrent_affairs_card {
			.content {
				max-width: 1200px;
				.image_wrap {
					border-radius: 16px;
					overflow: hidden;
				}
				h6 {
					font-size: 25px;
					color: $black-color;
					font-weight: bold;
				}
				.list {
					padding: 15px 0;
					span {
						img {
							width: 20px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}

	// ShubhBharat times Listing /////

	&.listing {
		.latest_articles {
			.search_wrap {
				.form_field {
					text-align: center;
					.input {
						max-width: 1200px;
						margin: 0 auto;
					}
				}
			}
			.filter_section {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
}

//

.newsarticle_detail {
	.article_details {
		.image_wrap {
			width: 100%;
			height: 340px;
			@include break-point(mobile) {
				height: 150px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 30px;
			}
		}
		.article_details {
			padding: 30px;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			border-radius: 16px;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			margin-top: -10px;
			margin-bottom: 15px;
			@include break-point(mobile) {
				padding: 30px 10px 10px;
			}
			.tag_details {
				display: flex;
				align-items: center;
				list-style: none;
				justify-content: space-around;
				padding-bottom: 20px;
				@include break-point(mobile) {
					flex-wrap: wrap;
				}
				li {
					padding-right: 20px;
					@include break-point(mobile) {
						width: 100%;
						max-width: 49%;
						padding: 0;
						margin-bottom: 10px;
					}
					img {
						width: 30px;
						margin-right: 10px;
						@include break-point(mobile) {
							width: 25px;
						}
					}
				}
			}
		}
	}

	.article_author {
		.article_author_card {
			width: 100%;
			max-width: 767px;
			margin: 0 auto;
			background: #ffffff;
			border: 1px solid rgba(223, 214, 229, 0.6);
			border-radius: 16px 16px;
			.item {
				display: flex;
				justify-content: space-between;
				@include break-point(mobile) {
					flex-direction: column;
				}
				.image_wrap {
					width: 100%;
					max-width: 155px;
					@include break-point(mobile) {
						max-width: 100%;
						text-align: center;
					}
				}
				.content {
					padding: 20px;
					@include break-point(mobile) {
						padding: 15px 10px;
					}
					h5 {
						margin: 0;
						@include break-point(mobile) {
							text-align: center;
						}
					}
					span {
						padding-bottom: 10px;
						font-weight: 500;
					}
				}
			}
			ul {
				display: flex;
				justify-content: space-between;
				border-bottom-right-radius: 16px;
				border-bottom-left-radius: 16px;
				li {
					background-color: $light-blue-color;
					width: 100%;
					text-align: center;
					padding: 15px;
					border-bottom-right-radius: 16px;
					&:first-child {
						margin-right: 2px;
						border-bottom-left-radius: 15px;
						border-bottom-right-radius: 0;
					}

					@include break-point(mobile) {
						padding: 5px 10px;
						font-size: 13px;
					}
				}
			}
		}
	}
}
