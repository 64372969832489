@import "../../scss/index.scss";

.ourSkilledFaculties {
	.faculties_slider {
		.faculties_card_active {
			width: 100%;
			max-width: 680px;
			border-radius: 16px;
			border: 1px solid #aaaaaa7a;
			overflow: hidden;
			margin: 0 15px;
			display: none;
			@include break-point(mobile) {
				margin: 0;
				display: block;
			}

			.contact_wrap {
				display: flex;
				justify-content: space-between;
				height: 270px;
				@include break-point(mobile) {
					flex-direction: column-reverse;
					height: 727px;
				}
				.faculty_detail {
					width: 100%;
					max-width: 65%;
					padding: 20px;
					@include break-point(desktop) {
						max-width: calc(100% - 170px);
					}
					@include break-point(landscapeTab) {
						padding-right: 10px;
						max-width: 100%;
					}
					@include break-point(mobile) {
						padding: 10px;
						min-height: 336px;
					}
					h4 {
						padding-bottom: 0;
						margin-bottom: 5px;
						
						@include break-point(mobile) {
							text-align: center;
						}
					}
					.designation {
						font-weight: 500;
						font-size: 16px;
						margin: 0;
						@include break-point(landscapeTab) {
							margin-bottom: 5px;
						}
					}
					.description {
						@include break-point(landscapeTab) {
							margin: 0;
						}
						p{
							margin-bottom: 0;
						}
					}
				}
				.faculty_image {
					width: 100%;
					max-width: 35%;
					text-align: right;
					// height: 270px;
					@include break-point(desktop) {
						max-width: 170px;
					}
					@include break-point(landscapeTab) {
						max-width: 150px;
					}
					@include break-point(mobile) {
						max-width: 90%;
						margin: 0 auto;
						margin-bottom: 10px;
						height: 353px;
					}
					img {
						width: 100%;
						max-width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 0;
					}
				}
			}
			ul.links {
				width: 100%;
				display: flex;
				justify-content: space-between;
				li {
					width: 100%;
					text-align: center;
					background-color: #ecf2fa;
					padding: 15px;
					@include break-point(desktop){
						padding: 10px;
					}
					a {
						color: #204581;
					}
				}
			}
		}

		.faculties_card {
			width: 100%;
			max-width: 200px;
			margin: 30px 8px 0 8px;
			display: block;
			@include break-point(desktop) {
				margin-top: 20px;
			}
			@include break-point(mobile) {
				display: none;
			}
			.image_wrap {
				text-align: center;
				height: 245px;
				
				a {
					display: block;
					height: 100%;
					width: 100%;
				}
				img {
					width: 100%;
					background-color: #d9d9d9;
					border-radius: 30px;
					object-fit: cover;
					height: 100%;
					cursor: pointer;
				}
			}
			.content {
				text-align: center;
				padding-top: 10px;
				h6 {
					padding: 0;
					margin-bottom: 5px;
				}
				p {
					font-size: 14px;
				}
			}
		}

		.slick-slider {
			.slick-slide {
				opacity: 0;
				@include break-point(mobile) {
					opacity: 1;
				}
			}
			.slick-active {
				width: 240px !important;
				opacity: 1;
				@include break-point(desktop) {
					width: 210px !important;
				}
				@include break-point(tablet) {
					width: 185px !important;
				}

				&.slick-current {
					width: 680px !important;
					animation: faculty_card 0.5s ease;
					opacity: 1;
					@include break-point(desktop) {
						width: 570px !important;
					}
					@include break-point(landscapeTab) {
						width: 550px !important;
					}
					@include break-point(mobile) {
						width: 330px !important;
					}
					.faculties_card_wrap {
						.faculties_card_active {
							display: block;
						}
						.faculties_card {
							display: none;
						}
					}
				}
			}
		}
	}
}

@keyframes faculty_card {
	0% {
		opacity: 0.2;
	}
	60% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.9;
	}
}
