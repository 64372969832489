@import "../../scss/index.scss";


.campus_gallery{
    .image_section{
        .react-photo-gallery--gallery{
            width: 100%;

            img{
                // width: 100%;
            }
        }
    }
}