@import "../../scss/index.scss";

.ourStory {
	.common_banner {
		background-image: url("../../assets/images/ourstory.png");
		background-position: center;
		min-height: 300px;
	}

	.about_us {
		.about_content {
			text-align: center;
			.image_wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				@include break-point(mobile) {
					flex-wrap: wrap;
				}
				img {
					border-radius: 16px;
					margin: 10px;
					width: 295px;
					height: 205px;
					@include break-point(tablet) {
						width: 100%;
						height: 150px;
						max-width: 220px;
					}
					@include break-point(miniTab) {
						height: 130px;
						max-width: 200px;
						margin: 5px;
					}
					@include break-point(mobile) {
						max-width: 100%;
						height: auto;
					}
					&:nth-child(2) {
						width: 402px;
						height: 246px;
						@include break-point(tablet) {
							width: 100%;
							height: 190px;
							max-width: 285px;
						}
						@include break-point(miniTab) {
							height: 170px;
							max-width: 265px;
						}

						@include break-point(mobile) {
							max-width: 100%;
							height: auto;
						}
					}
				}
			}
			p {
				width: 100%;
				max-width: 900px;
				margin: 0 auto;
				padding: 20px 15px;
			}
			p.about_us_detail {
				text-align: justify;
				h6{
					text-align: center;
				}
			}

		}
		.result_highlights {
			display: flex;
			justify-content: center;
			@include break-point(tablet) {
				flex-wrap: wrap;
				padding: 20px 0;
			}

			.padding {
				padding: 30px;
				width: 100%;
				max-width: 49%;
				background-color: $white-color;
				border: 1px solid #dfd6e5;
				border-radius: 16px;
				@include break-point(tablet) {
					max-width: 100%;
					margin-bottom: 15px;
				}
				@include break-point(miniTab) {
					padding: 20px;
				}

				h4 {
					text-align: center;
				}
			}
			.highlights {
				background-color: #ecf2fa;
				h4 {
					color: $blue-color;
				}

				ul {
					width: 100%;

					li {
						padding-left: 40px;
						position: relative;
						margin-bottom: 20px;
						&:after {
							content: "";
							position: absolute;
							left: 0;
							background-image: url("../../assets/icons/StarHalf.svg");
							background-size: 25px;
							width: 30px;
							height: 30px;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}
	}
	.number_data {
		background-color: $red-color;
		h3 {
			color: $white-color;
			text-align: center;
		}
		p {
			text-align: center;
			color: $white-color;
			color: $white-color;
		}
		ul {
			padding-top: 20px;
		}
	}

	.board_members {
		.board_members_card {
			display: flex;
			flex-direction: column;
			align-items: center;
			.board_members {
				width: 100%;
				max-width: 900px;
				display: flex;
				border: 1px solid rgba(223, 214, 229, 0.6);
				border-radius: 36px;
				margin-bottom: 15px;

				@include break-point(mobile) {
					flex-direction: column-reverse;
				}
				.content {
					padding: 40px;
					width: 65%;
					@include break-point(mobile) {
						width: 100%;
						padding: 15px;
					}
					h4 {
						margin-bottom: 0;
						color: $blue-color;
						text-transform: uppercase;
						@include break-point(mobile) {
							text-align: center;
						}
					}
					b {
						padding-bottom: 5px;
						display: block;
					}
					.button_wrap {
						text-align: left;
						padding-bottom: 0;
						@include break-point(mobile) {
							text-align: center;
						}
					}
				}
				.image_wrap {
					width: 35%;
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					@include break-point(mobile) {
						width: 100%;
						text-align: center;
					}
					img {
						width: 100%;
						max-width: 324px;
						@include break-point(mobile) {
							margin: 0 auto;
							max-width: 250px;
						}
					}
				}
				&:nth-child(even) {
					.image_wrap {
						order: 1;
						@include break-point(mobile) {
							order: 2;
						}
					}
					.content {
						order: 2;
						@include break-point(mobile) {
							order: 1;
						}
					}
				}
			}
		}
	}
	.our_beliefs {
		.our_beliefs_wrap {
			width: 100%;
			justify-content: center;
			display: flex;

			@include break-point(mobile){
				flex-direction: column;
			}
			.our_beliefs_card {
				width: 100%;
				max-width: 390px;
				padding: 30px;
				background-color: #fef3e9;
				border-radius: 16px;
				padding-right: 0;
				position: relative;
				&:not(:last-child) {
					margin-right: 20px;
				}

				@include break-point(tablet) {
					padding: 20px;
				}

				@include break-point(miniTab){
					padding: 15px;
				}
				@include break-point(mobile){
					margin-bottom: 10px;
				}

				&.online {
					background-color: #fef3e9;
				}
				&.classroom {
					background-color: #ecf2fa;
				}
				&.regional {
					background-color: #fff1f1;
				}

				h4{
					@include break-point(miniTab){
						font-size: 23px;
					}
				}

				.content {
					p {
						margin: 0;
						width: calc(100% - 180px);
						@include break-point(tablet){
							width: 100%;
							padding-bottom: 80px;
						}
						img {
							position: absolute;
							right: 0;
							bottom: 0;
							width: 190px;
							@include  break-point(tablet){
								width: 115px;
							}
						}
					}
				}
			}
		}
	}

	.gallery_videos {
		.slider_wrapper {
			position: relative;

			.slick-list {
				padding: 50px 0 !important;
				@include break-point(mobile){
					padding: 0 !important;
				}
				.slick-active {
					&.slick-center.slick-current {
						.item {
							transform: scale(1.6);

							@include break-point(mobile){
								transform: unset;
							}
							.playicon {
								opacity: 1;
								visibility: visible;
								.videoplay_icon {
									width: 40px;
									height: 40px;
									font-size: 20px;
								}
							}
						}
					}
				}
			}
			.item {
				width: 100%;
				max-width: 200px;
				position: relative;
				border-radius: 16px;
				overflow: hidden;
				transition: all 0.7s linear;
				@include break-point(mobile){
					width: 100%;
					max-width: 100%;
				}
				.playicon {
					opacity: 0;
					visibility: hidden;
				}
				img {
					width: 100%;
				}
			}
		}
	}

	.our_location {
		.our_location_content {
			display: flex;
			justify-content: space-between;
			border: 1px solid #dfd6e5;
			border-radius: 16px;
			@include break-point(miniTab){
				flex-direction: column;
			}
			.content_form {
				width: 100%;
				max-width: 50%;
				padding: 30px;
				@include break-point(miniTab){
					max-width: 100%;
					padding: 20px;
				}
				.address {
					p {
						width: 100%;
						max-width: 255px;
						line-height: 1.7;
						a {
							font-weight: bold;
						}
					}
				}
				.form {
					.input {
						width: 100%;
						padding: 10px;
						outline: none;
						width: 100%;
						background: rgba(73, 113, 178, 0.1);
						opacity: 0.5;
						border: 1px solid rgba(223, 214, 229, 0.6);
						border-radius: 4px;
						min-height: 50px;
						padding: 5px 15px;
						transition: all 0.3s linear;
					}
					.button_wrap {
						text-align: left;
					}
				}
			}
			.location_map {
				width: 100%;
				max-width: 50%;
				@include break-point(miniTab){
					max-width: 100%;
				}
				img {
					width: 100%;
					height: 517px;
					object-fit: cover;

					@include break-point(miniTab){
						height: auto;
					}
				}
			}
		}
	}
}
