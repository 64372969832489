@import "../../scss/index.scss";

.courser_report {
  .report_card_wrap {
    display: flex;
    margin-bottom: 25px;
    @include break-point(mobile){
      flex-wrap: wrap;
    }
    .report_cards {
      width: calc(100% / 4);
      padding-right: 20px;
      @include break-point(mobile){
        width: calc(100% / 2);
        padding-right: 10px;
        padding-bottom: 10px;
      }
      .item {
        padding: 25px;
        border-radius: 5px;
        background-color: $blue-color;
        @include break-point(mobile){
          padding: 15px;
        }
        &:first-child {
          background-color: $light-blue-color;
        }
        h5{
          @include break-point(mobile){
            font-size: 14px;
          }
        }
      }

      &:last-child {
        padding-right: 0;
        @include break-point(mobile){
          padding-right: 10px;
        }
        .item {
          background-color: $light-blue-color;
        }
      }
      &:nth-child(2) {
        .item {
          background-color: $light-yellow-color;
        }
      }
      &:nth-child(3) {
        .item {
          background-color: $light-pink-color;
        }
      }
      &:nth-child(4) {
        .item {
          background-color: $light-red-color;
        }
      }
    }
  }
  ._box {
    background-color: $white-color;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .filter_wrap {
      display: flex;
      align-items: center;
      @include break-point(mobile){
        flex-direction: column;
      }
      .form_field {
        padding-right: 20px;
        padding-bottom: 0;
        @include break-point(mobile){
          width: 100%;
          margin-bottom: 10px;
          padding: 0;
          .react-datepicker-wrapper{
            width: 100%;
          }
        }
      }
      .dashboard_button {
        margin-top: 21px;
        @include break-point(mobile){
          margin: 0 0 10px 0;
          width: 100%;
        }
      }
    }
  }

  .table_wrap{ 
    overflow-x: auto; 
    .table {
      @include break-point(mobile){
        min-width: 700px;
      }
    }
  }
}
