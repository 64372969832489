@import "../../scss/index.scss";

.modal-content {
  min-height: 0 !important;
  .welcome_wrapper {
    .welcome {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .image_wrap {
        width: 100%;
        overflow: hidden;
      }
      .detail_wrap {
        text-align: center;
        width: 65%;
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}
