.megamenu1 {
    display: none; /* Initially hide the megamenu */
    background: #fafafa;
    border: 1px solid #d6dbde;
    border-radius: 12px;
    box-shadow: 0 0 23px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
    padding: 5px 25px;
    color: #252424;
}

.has-menu.active .megamenu1.show {
    display: block; /* Show the megamenu when active */
}

.megamenu1 .flex-column .nav-link {
    text-align: justify;
    color: #252424; /* Ensure region names are black by default */
    height: 60px;
    display: flex;
    align-items: center; /* Center items vertically */
    padding: 30px 30px;
    margin: 0 -25px; /* Negative margin to offset the padding */
}

.megamenu1 .flex-column .nav-link:hover {
    border-radius: 12px;
    height: 60px;
    /* display: flex; */
    align-items: center; /* Center items vertically */
    color: #4971B2; /* Change color to blue on hover */
    background: rgba(73, 113, 178, 0.1);
    /* padding: 15px 15px; Maintain padding on hover */
    margin: 0 -25px; /* Maintain negative margin on hover */
}
