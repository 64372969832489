@import "./index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// html {
// 	scroll-behavior: smooth;
// }

body {
  font-family: "Red Hat Display";
  font-size: 15px;
  font-weight: 400;
  color: $text-color;
  letter-spacing: 0.01em;
  // overscroll-behavior: contain;

  @include break-point(desktop) {
    font-size: 14px;
  }
}

a {
  display: inline-block;
  color: $blue-color;
  @include transition;
  text-decoration: none;

  &:hover {
    color: $red-color;
  }
}

span {
  display: inline-block;
}

p {
  margin-bottom: 15px;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: $title-color;
  font-weight: 700;
  line-height: 1.4;
  font-family: "Red Hat Display";
  padding-bottom: 10px;
  margin-bottom: 0;
  @include break-point(mobile) {
    margin-bottom: 0;
  }
}
h5 {
  font-weight: 500;
}

h6 {
  font-weight: bold;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 45px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 27px;
}

@include break-point(desktop) {
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 22px;
  }
}

@include break-point(mobile) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 23px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
}

video {
  object-fit: cover;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  @include break-point(mobile) {
    height: 4px;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: $blue-color;
  outline: 1px solid $light-red-color;
  border-radius: 8px;
}


