@import "../../scss/index.scss";

.workshop_page {
	.common_banner {
		background-image: url("../../assets/images/workshop.png");
		ul {
			display: flex;
			justify-content: space-between;
			max-width: 440px;
			width: 100%;
			padding: 20px 0;
            @include break-point(mobile){
                flex-wrap: wrap;
                justify-content: unset;
            }
			li {
				font-weight: 500;
                @include break-point(mobile){
                    &:not(:last-child){
                        padding-right: 20px;
                    }
                }
				span {
					margin-top: 10px;
					display: block;
					color: $blue-color;
					svg {
						margin-right: 7px;
						font-size: 20px;
					}
				}
			}
		}
		.button_wrap {
			text-align: left;
		}
	}

	.content_box {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		padding: 20px;
        @include break-point(desktop){
            max-width: 95%;
        }
		@include break-point(miniTab) {
			padding: 20px 10px;
		}

		h3 {
			color: $blue-color;
			text-align: center;
		}
	}

	.annoucement {
		display: block;
		.card_wrap {
			display: block;
			max-width: 1100px;
			margin: 0 auto;

            .annoucement_card{
                margin: 5px;
            }
		}
	}
}
