@import "../../scss/index.scss";

.contactus_form {
	.form_content {
		display: flex;
		justify-content: space-between;
		background: #ffffff;
		border: 1px solid #ece6ef;
		border-radius: 16px;

		@include break-point(miniTab) {
			flex-wrap: wrap;
		}

		@include break-point(mobile){
			border: none;
		}
		.image_section {
			width: 100%;
			max-width: 40%;
			padding: 40px 50px;
			background: #ecf2fa;
			border: 1px solid #ece6ef;
			border-radius: 16px;
			text-align: center;
			font-size: 16px;

			@include break-point(desktop) {
				max-width: 500px;
				padding: 25px 20px;
			}

			@include break-point(tablet) {
				max-width: 50%;
			}
			@include break-point(miniTab){
				max-width: 400px;
				margin: 0 auto;
				margin-top: 10px;
			}

			@include break-point(mobile){
				border: 1px solid #ece6ef;
				border-radius: 16px;
				margin-bottom: 15px;
				padding: 18px 12px;
			}
			.image_wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				
				img {
					width: 100%;
					max-width: 150px;
					margin: 10px 20px;
					@include break-point(tablet) {
						max-width: 140px;
						margin: 10px 10px;
					}
					@include break-point(mobile){
						max-width: 110px;
					}
				}
			}
			span {
				padding: 15px 0;
				@include break-point(desktop){
					padding-bottom: 5px;
				}
			}
			h5 {
				text-transform: capitalize;
				padding: 0;
				margin: 0;
			}
			p {
				color: $blue-color;
				margin: 0;
				padding: 15px 52px;
				font-weight: 500;

				@include break-point(mobile){
					padding: 15px 30px;
				}
			}
		}

		.detail_form {
			width: 100%;
			max-width: 60%;
			padding: 30px 60px;
			@include break-point(desktop) {
				padding: 15px 50px;
				max-width: calc(100% - 501px);
			}
			@include break-point(tablet) {
				max-width: 50%;
				padding: 15px 15px;
			}

			@include break-point(miniTab) {
				max-width: 100%;
			}
			@include break-point(mobile){
				border: 1px solid #ece6ef;
				border-radius: 16px;
			}
			.form_title {
				text-align: center;
				padding: 15px 0 15px;

				h3 {
					margin: 0;
					padding-bottom: 0;
					font-size: 40px;
					text-transform: capitalize;
					@include break-point(desktop){
						font-size: 30px;
					}
					@include break-point(mobile){
						font-size: 27px;
						
					}
				}
				span {
					font-weight: bold;
					font-size: 16px;
					padding: 5px 0;
				}
			}
		}
	}
}
