@import "../../../scss/index.scss";

.section1-container {
    max-width: 1200px; /* Limit the width of the container */
    margin: -50px auto; /* Center the container */
    padding: 20px; /* Add some padding */
    text-align: left; /* Center the text */
  }
  
  .card-container {
    display: grid; /* Use CSS Grid for layout */
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 20px; /* Space between cards */
    margin-top: 20px; /* Add some space above the cards */
  }
  
  .card {
    background-color: #fff; /* Light background for the cards */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Inner spacing */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s; /* Smooth transition for hover effect */
    height: 120px; /* Fixed height for cards */
  }

  .card h3 svg {
    margin-right: 10px; /* Space between icon and title */
  }

  .cardh6{
    margin-left: 33px;
    }
  
  .card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
  }
  .card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    color: #3A69B0;
  }
  