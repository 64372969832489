@import "../../scss/index.scss";

.edit_profile {
	.edit_profile_content {
		display: flex;
		// justify-content: space-between;
		justify-content: center;
		@include break-point(tablet) {
			flex-direction: column;
		}
		.lefside_bar {
			width: 100%;
			max-width: 265px;
			padding: 30px;
			background-color: $red-color;
			color: $white-color;
			border-radius: 16px;
			height: calc(100vh - 109px);
			overflow-y: auto;
			position: relative;
			background-image: url("../../assets/images/editprofilebg.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: sticky;
			top: 130px;
			@include break-point(tablet) {
				padding: 15px;
				max-width: 100%;
				position: relative;
				margin-bottom: 15px;
				top: 0;
				height: auto;
			
			}
			@include break-point(miniTab) {
				height: auto;
			}
			.content {
				.profile_image {
					width: 150px;
					height: 150px;
					border-radius: 50%;
					margin: 0 auto;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.user_icon {
						svg {
							font-size: 150px;
						}
					}
					.edit_icon {
						position: absolute;
						right: 20px;
						top: 20px;
						font-size: 22px;
						padding: 3px 10px;
						background-color: $white-color;
						color: $red-color;
						line-height: 1;
						border-radius: 6px;
						cursor: pointer;
					}
				}
				h6 {
					font-weight: 600;
					margin: 0;
					padding: 15px 0;
					font-size: 20px;
				}
				span {
					display: block;
					padding: 2px 0;
				}
				hr {
					opacity: 1;
					border-color: $white-color;
					margin: 33px 0;
				}
				.address {
					h5 {
						color: $white-color;
						font-weight: bold;
						margin-bottom: 5px;
					}
				}
			}
			.button_wrap {
				padding-top: 25px;

				.button {
					width: 100%;
					&:hover {
						background-color: $white-color;
						color: $red-color;
					}
				}
			}
			&::-webkit-scrollbar-track {
				box-shadow: none;
			}
		}
		.profile_content {
			// width: calc(100% - 265px);
			width: calc(100%);
			padding-left: 25px;
			@include break-point(tablet) {
				width: 100%;
				padding-left: 0;
			}
			.upload_document {
				display: flex;
				justify-content: space-between;
				@include break-point(miniTab) {
					flex-direction: column;
				}
				.id_document {
					width: 30%;
					margin: 10px;
					padding: 20px 30px;
					background-color: $white-color;
					border-radius: 16px;
					box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
					border: 1px solid #f1f1f1;
					@include break-point(tablet) {
						padding: 15px;
						max-width: 95%;
					}
					@include break-point(miniTab) {
						max-width: 100%;
						width: 100%;
					}
					@include break-point(mobile) {
						width: 100%;
					}
					&:first-child {
						margin-right: 20px;

						@include break-point(tablet) {
							margin-right: 5px;
						}
						@include break-point(mobile) {
							margin: 0 0 5px 0;
						}
					}
					.content {
						margin: 20px 0;
						position: relative;
						height: 160px;
						.ewqTBN,
						.file_uploader {
							width: 100%;
							height: auto;
							padding: 20px 10px;
							flex-direction: column;
							justify-content: center;
							min-width: unset;
							max-width: 100%;
							input {
								width: 100%;
							}
							svg {
								width: 90px;
								height: 90px;
								margin-bottom: 10px;
							}
							.kyUZVo {
								align-items: center;
								span {
									font-family: inherit;
									font-size: 16px;
								}
							}
						}
						.guZdik {
							min-width: unset;
						}
						.document_image {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							width: 100%;
							height: 170px;
							background-color: #aaa;

							span.close_icon {
								position: absolute;
								padding: 1px 8px;
								right: 0;
								font-weight: bold;
								background: $red-color;
								color: $white-color;
								cursor: pointer;
							}
							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
					}
					p {
						margin: 0;
						b {
							color: $red-color;
							display: block;
						}
					}
				}
			}
			.course_package {
				padding: 20px;
				background-color: $white-color;
				border-radius: 16px;
				box-shadow: $box-shadow;
				margin: 35px 0;
				position: sticky;
				top: 80px;

				@include break-point(tablet) {
					overflow-x: auto;
					margin: 15px 0;
				}
				&::-webkit-scrollbar {
					height: 4px;
				}
				ul {
					height: 300px;
					overflow-y: auto;
					@include break-point(miniTab) {
						min-width: 750px;
					}
					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 15px 5px 15px 0;
						border-bottom: 1px solid #f1f1f1;
						h6 {
							font-size: 16px;
							width: 100%;
							max-width: 253px;
							margin-bottom: 0;
							@include break-point(desktop){
								font-size: 14px;
							}
						
						}
						.date {
							padding: 0 10px;
							span {
								display: block;
							}
						}
					}
				}
			}
		}
	}
}
