@import "../../scss/index.scss";

.login {
	.form_container {
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 15px;

		.content_section {
			width: 100%;
			display: flex;
			justify-content: space-between;
			background: #ffffff;
			border: 1px solid #ece6ef;
			border-radius: 16px;

			.content {
				width: 100%;
				max-width: 45%;
				border: 1px solid #ece6ef;
				border-radius: 16px;
				background: #ecf2fa;

				@include break-point(tablet) {
					display: none;
				}

				.image_wrap {
					width: 100%;
					height: 50%;
					overflow: hidden;
					border-radius: 16px 16px 0px 0px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.text {
					font-size: 18px;
					text-align: center;
					padding: 40px 40px 20px;
					height: 50%;
					display: flex;
					justify-content: center;
					align-items: center;

					p {
						margin: 0;
					}
				}

				.slick-slide {
					padding: 0;
				}

				.slick-slider {
					height: 100%;

					.slick-dots {
						position: absolute;
						bottom: 10px;
					}

					.slick-list {
						height: 100%;

						.slick-track {
							height: 100%;

							.slick-slide {
								> div {
									height: 100%;
								}
							}

							.item {
								height: 100%;

								.item_content {
									height: 100%;
								}
							}
						}
					}
				}
			}

			.form_section {
				padding: 45px;
				width: 100%;
				max-width: 55%;

				@include break-point(tablet) {
					max-width: 100%;
				}

				@include break-point(mobile) {
					padding: 20px 15px;
					text-align: center;
				}

				.form_title {
					text-align: center;
					padding-bottom: 20px;

					h3 {
						margin-bottom: 0;
					}

					&.padding {
						padding: 25px 0;
					}
					.text-with-lines {
						display: flex;
						align-items: center;
						text-align: center;
					}
					.text-with-lines::before,
					.text-with-lines::after {
						content: '';
						flex: 1;
						border-top: 1px solid #000;
					}
					.text-with-lines span {
						padding: 0 10px;
					}
				}

				.subtitle {
					line-height: 2;
				}

				.resend_email {
					font-weight: 500;
					color: $blue-color;
					border-bottom: 1px solid $blue-color;
				}

				.button_wrap {
					padding: 20px 0;
					text-align: center;
				}

				.divider {
					position: relative;
					text-align: center;
					opacity: 0.4;

					&::before {
						content: "";
						position: absolute;
						opacity: 0.4;
						border: 0.5px solid #181818;
						width: 100%;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						max-width: 44%;
					}

					&::after {
						content: "";
						position: absolute;
						opacity: 0.4;
						border: 0.5px solid #181818;
						width: 100%;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						max-width: 44%;
					}
				}

				.logwith {
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.01em;
					color: #181818;
					background: rgba(73, 113, 178, 0.1);
					border: 1px solid rgba(223, 214, 229, 0.6);
					border-radius: 4px;
					height: 50px;
					width: 100%;
					display: flex;
					text-align: center;
					align-items: center;
					justify-content: center;
					margin-bottom: 39px;
					margin-left: auto;
					margin-right: auto;
					max-width: 330px;

					img {
						margin-right: 20px;
					}
				}

				.signup_text {
					text-align: center;
					margin: 0;

					a {
						font-weight: bold;
						text-decoration: underline;
					}
				}

				ul.user_otp {
					margin-bottom: 25px;
					display: flex;
					justify-content: center;

					.user_image {
						width: 40px;
						height: 40px;
						border: 2px solid $blue-color;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 20px;

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}

					li {
						p {
							margin-bottom: 5px;
						}
					}
				}

				.optINput {
					width: 100%;
					justify-content: center;

					input {
						outline: none;
						background: rgba(73, 113, 178, 0.1);
						opacity: 0.5;
						border: 1px solid rgba(223, 214, 229, 0.6);
						border-radius: 4px;
						min-height: 50px;
						padding: 5px 15px;
						@include transition;
						width: 100% !important;
						max-width: 50px;
						color: $black-color;
						font-size: 20px;
						font-weight: bold;

						&:focus {
							box-shadow: 0 0 0px 1px $red-color;
						}
					}
				}

				.resend_button {
					text-align: right;
					padding-top: 15px;

					a {
						margin-right: 5px;
						font-weight: 500;
						text-decoration: underline;
					}
				}

				.otp_verified {
					text-align: center;
					padding: 10px 20px;

					img {
						width: 65px;
						height: 65px;
					}

					h3 {
						padding: 20px 0;
						color: $blue-color;
					}
				}
			}
		}

		.enter_otp {
			width: 100%;
			max-width: 310px;
			margin: 0 auto;

			hr {
				border: 0;
				padding: 5px 0;
			}
			
		}
	}

	&.signup {
		.form_container {
			@include break-point(tablet) {
				max-width: 500px;
			}

			.content_section {
				.content {
					max-width: 40%;
				}

				.form_section {
					padding: 35px 25px;
					max-width: 60%;

					@include break-point(tablet) {
						max-width: 100%;
					}

					.otp_verified {
						padding: 25px 60px;
					}
				}

				.enter_otp {
					width: 100%;
					max-width: 350px;
					margin: 0 auto;
				}
			}
		}
	}

	&.forget_password {
		.form_container {
			max-width: 550px;

			.content_section {
				.form_section {
					padding: 60px;
					max-width: 100%;
					@include break-point(mobile) {
						padding: 30px 20px;
					}
					.form_title {
						h3 {
							color: $blue-color;
							text-transform: capitalize;
						}
					}
				}
			}
		}
	}
}
