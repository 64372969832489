@import "../../scss/index.scss";

.event_calendar {
  padding: 30px 0;
  padding-bottom: 4px;
  @include break-point(mobile) {
    padding: 15px 0;
    padding-bottom: 4px;
  }
  .calendar_title {
    display: flex;
    justify-content: space-between;
    @include break-point(mobile) {
      flex-direction: column-reverse;
    }
    .control_buttons {
      display: flex;
      width: 100%;
      @include break-point(mobile) {
        margin-top: 10px;
      }
      // button {
      //   background-color: $blue-color;
      //   border: 1px solid $blue-color;
      //   color: $white-color;
      //   margin-right: 10px;
      //   padding: 5px 15px;
      //   border-radius: 5px;
      //   min-width: 100px;
      //   text-align: center;
      //   svg {
      //     font-size: 20px;
      //     display: none;
      //   }
      // }
    }
  }

  div[data-testid="date-navigator"],
  div[data-testid="view-navigator"] {
    padding-bottom: 20px;

    button {
      font-size: 17px;
      font-weight: bold;
    }
  }

  div[data-testid="date-navigator"] {
    button {
      color: $title-color;
    }
  }

  div[data-testid="view-navigator"] {
    padding-bottom: 20px;
  }
}

// _____________________________________ Calendar _____________________________
.css-96e9fn {
  .css-1j5p8wz {
    .rs__cell.rs__header {
      background: #fceeee;
      text-align: center;
      font-size: 17px;

      .css-1mdftu-MuiTypography-root {
        font-size: 18px;
        font-weight: 600 !important;
        font-family: inherit;
        color: $text-color;
      }

      &.rs__today_cell {
        background-color: $red-color;
        color: $white-color;

        .css-1mdftu-MuiTypography-root {
          color: $white-color;
        }
        .css-vkc4v8-MuiTypography-root {
          color: $white-color;
        }
      }
    }
  }
}
// _____________________________ Custom Calendar _________________________

.calender_wrapper {
  @include break-point(mobile) {
    overflow-x: auto;
  }
  .week_days {
    display: flex;
    border: 1px solid #ecf0f7;
    border-radius: 8px;

    @include break-point(mobile) {
      min-width: 350px;
    }
    > li {
      border-right: 1px solid #ecf0f7;
      width: calc(100% / 7);
    }
    .calendar_head {
      font-size: 16px;
      background-color: #ecf0f7;
      padding: 10px;
      text-align: center;
      border-right: 1px solid #aaa6;
      height: 87px;
      position: sticky;
      top: 72px;
      @include break-point(mobile) {
        font-size: 14px;
        padding: 6px;
        position: static;
      }
      .dayName {
        display: block;
        font-size: 13px;
        @include break-point(mobile) {
          font-size: 11px;
        }
      }

      &.active {
        b {
          background-color: $red-color;
          color: $white-color;
          width: 30px;
          border-radius: 50%;
          height: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .days_events {
      li {
        padding: 10px;
        border-bottom: 1px solid #ecf0f7;
        min-height: 90px;
        display: flex;
        align-items: center;
        @include break-point(mobile) {
          padding: 5px;
        }
        p {
          padding: 5px;
          background: #fceeee 0% 0% no-repeat padding-box;
          border: 1px solid #e78d8a;
          border-radius: 8px;
          margin: 0;
          font-size: 13px;
          width: 100%;
          text-align: center;
          min-height: 90px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @include break-point(mobile) {
            font-size: 10px;
          }
          span {
            display: block;
          }
        }
      }
    }
  }
  .listing_card {
    .image_wrap {
      img {
        max-width: 335px;
      }
    }
  }
}
