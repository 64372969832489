@import "../../../scss/index.scss";

.free_resources.articles {
	.banner {
		background-image: url("../../../assets/images/articleBanner.png");
	}
}

.articles {
	.articles_content {
		.card_wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			.articles_wrap {
				width: 100%;
				// max-width: 23%;
				margin: 10px;
				border-radius: 16px;
				background: rgba(221, 93, 89, 0.05);
				text-align: center;
				padding: 20px;
				min-width: 253px;
				flex: 1 1;

				@include break-point(miniTab) {
					min-width: 220px;
				}
				@include break-point(mobile) {
					min-width: 140px;
					margin: 10px 5px;
					padding: 15px 10px;
				}
				img {
					width: 64px;
					height: 64px;

					@include break-point(mobile) {
						width: 45px;
						height: 45px;
					}
				}
				h5 {
					padding: 20px 0px;
					margin: 0;
					color: $red-color;
					font-size: 18px;
					@include break-point(mobile) {
						padding: 5px 0;
                        font-size: 16px;
					}
				}

				.button_wrap {
					@include break-point(mobile) {
						.icon {
							display: none;
						}
					}
				}
			}
		}
	}


}
