@import "../../scss/index.scss";

.footer {
	background-color: #f5f5f5;
	padding: 20px 0;
	@include break-point(mobile) {
		padding: 20px 0;
	}
	.footer_top {
		.footer_logo {
			margin-bottom: 15px;
			width: 191px;
			@include break-point(mobile){
				margin: 0;
			}
		}

		.footer_content {
			display: flex;
			justify-content: space-between;
			width: 100%;
			// padding: 20px 0 30px;

			@include break-point(landscapeTab) {
				flex-wrap: wrap;
			}

            @include break-point(mobile){
                padding: 10px 0;
            }
		}

		.content_left {
			display: flex;
			width: 100%;
			justify-content: space-between;
			@include break-point(mobile) {
				flex-wrap: wrap;
			}
			ul {
				width: 100%;
				max-width: 20%;
				padding: 0 15px;

				@include break-point(landscapeTab) {
					padding: 0 15px 0 0;

					&:last-child {
						padding-right: 0;
					}
				}

				@include break-point(mobile) {
					max-width: 100%;
					padding: 10px 0;
					border-bottom: 1px solid rgba(24, 24, 24, 0.1);
				}

				li {
					&.title {
						@include title;
						padding-bottom: 10px;
						font-size: 17px;

						@include break-point(mobile){
							font-size: 15px;
							padding: 0;
						}
					}

					a {
						padding-bottom: 5px;
						color: #898483;

						&:hover {
							color: $red-color;
						}

						@include break-point(mobile) {
							padding: 5px 0;
						}
					}
				}

				&.text_content {
					max-width:35%;
					padding-left: 0;
					@include break-point(mobile) {
						max-width: 100%;
					}
					li {
						display: flex;
						align-items: flex-start;
						padding-bottom: 10px;

						img {
							padding-right: 14px;
						}

						p {
							margin: 0;
							color: $black-color;
						}

						a {
							color: $black-color;
						}
					}
				}
			}
		}

		.content_right {
			width: 100%;
			max-width: 40%;
			padding-left: 20px;
			@include break-point(desktop){
				max-width: 25%;
			}
			@include break-point(landscapeTab) {
				max-width: 100%;
				padding: 20px 0 0 0;
			}

			h5 {
				margin-bottom: 0;
				font-size: 17px;
			}

			.footer_form {
				padding: 17px 0 25px;

				form {
					display: flex;
					input {
						border: 1px solid #d6d6d6;
						border-radius: 6px;
						color: #898483;
						min-height: 50px;
						background-color: transparent;
						width: 100%;
						max-width: 300px;
						padding: 5px 15px;
						outline: none;
					}

					.button {
						margin-left: 10px;
						border: 1px solid #d6d6d6;
						border-radius: 6px;
						color: #898483;
						min-height: 50px;
						background: transparent;
						min-width: 100px;
					}
				}
			}

			.social_icons {
				@include break-point(landscapeTab){
					padding-bottom: 15px;
				}
				@include break-point(mobile){
					padding-bottom: 5px;
				}
			
				ul {
					display: flex;
					align-items: center;
					li {
						display: inline-block;
						margin-right: 20px;

						&:last-child {
							margin-right: 0;
						}

						button {
							color: $white-color;
							background-color: $blue-color;
							font-size: 19px;
							padding: 5px;
							border-radius: 50%;
							height: 40px;
							width: 40px;
							position: relative;
							@include transition;
							outline: none;
							border: 0;

							svg {
								@include transformCenter;
							}
							img{
								width: 100%;
								max-width: 19px;
							}

							&.facebook {
								background: $facebook-color;

								&:hover {
									box-shadow: 0 0.5em 0.5em -0.4em $facebook-color;
									transform: translateY(-0.25em);
								}
							}

							&.youtube {
								background: $youtube-color;

								&:hover {
									box-shadow: 0 0.5em 0.5em -0.4em $youtube-color;
									transform: translateY(-0.25em);
								}
							}

							&.linkedin {
								background: $likedin-color;

								&:hover {
									box-shadow: 0 0.6em 0.6em -0.3em $likedin-color;
									transform: translateY(-0.25em);
								}
							}

							&.twitter {
								background: $twitter-color;

								&:hover {
									box-shadow: 0 0.6em 0.6em -0.3em $twitter-color;
									transform: translateY(-0.25em);
								}
							}

							&.instagram {
								background: $instagram-color;

								&:hover {
									box-shadow: 0 0.6em 0.6em -0.3em #c13584;
									transform: translateY(-0.25em);
								}
							}

							&.telegram {
								background: $telegram-color;

								&:hover {
									box-shadow: 0 0.6em 0.6em -0.3em $telegram-color;
									transform: translateY(-0.25em);
								}
							}
						}
					}
				}
				img.GooglePlayStore{
					height: 40px;
				}
				img.GooglePlayStore:hover {
					box-shadow: 0 0.5em 0.5em -0.4em $facebook-color;
					transform: translateY(-0.25em);
					cursor: pointer;
				}
			}
		}
	}

	.footer_bottom {
		padding-top: 20px;
		border-top: 1px solid #e2e1e1;

		ul {
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				padding: 0 20px;

				@include break-point(mobile) {
					padding: 0 10px;
				}

				a {
					color: #898483;

					&:hover {
						color: $red-color;
					}
				}
			}
		}
	}
}
