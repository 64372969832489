@import "../../scss/index.scss";

.blogs {
	.common_banner {
		background-image: url("../../assets/images/blogBanner.png");
	}

	.three_cards {
		.card_wrap {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.blog_card {
				width: 100%;
				max-width: 320px;
				border: 1px solid #dfd6e5;
				border-radius: 16px;
				margin: 10px;
				.image_wrap {
					border-radius: 16px 16px 0 0;
					height: 190px;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.content {
					padding: 20px;
					h5 {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.blogcards_listing {
		.listing_card_wrap {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.listingcard {
				width: 100%;
				// max-width: 320px;
				display: flex;
				border: 1px solid rgba(223, 214, 229, 0.6);
				border-radius: 16px;
				min-width: 370px;
				flex: 1 1;
				margin: 10px;

				&:last-child {
					max-width: 450px;
				}
				.image_wrap {
					width: 100%;
					max-width: 115px;
					border-radius: 16px 0 0 16px;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.content {
					width: 100%;
					max-width: calc(100% - 115px);
					padding: 10px 20px;
					h5 {
						margin: 0;
					}
					p {
						margin: 0;
					}
				}
			}
		}
	}
}
