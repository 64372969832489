@import "../../scss/index.scss";

.teacher_profile {
	.profile_banner {
		background-image: url("../../assets/images/yellowbg.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center;
		text-align: center;
		padding-bottom: 100px;
		@include break-point(mobile) {
			background-size: cover;
			background: #fffbf4;
			padding-bottom: 30px;
		}
		.teacher_card {
			background-color: $white-color;
			display: flex;
			border-radius: 16px;
			width: 100%;
			max-width: 767px;
			margin: 0 auto;
			padding: 15px 0;
			@include break-point(mobile) {
				flex-direction: column;
			}
			.teacher_image {
				width: 100%;
				max-width: 320px;
				padding-right: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include break-point(mobile) {
					max-width: 100%;
					text-align: center;
					justify-content: center;
				}

				img {
					width: 100%;
					max-width: 240px;
					height: 290px;
					object-fit: cover;
					border-radius: 12px;
					@include break-point(mobile) {
						max-width: 160px;
						height: 215px;
					}
				}
			}
			.content {
				text-align: left;
				padding: 10px 20px;
				width: 100%;
				h4 {
					color: $blue-color;
					padding-bottom: 0;
					font-size: 25px;

					@include break-point(mobile) {
						font-size: 19px;
						margin-bottom: 5px;
						text-align: center;
					}
				}
				P{
					@include break-point(mobile){
						text-align: center;
					}
				}
				ul {
					li {
						padding-bottom: 10px;
						font-size: 16px;

						img {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
	.teacher_introduction {
		.video_modal {
			width: 100%;
			max-width: 690px;
			margin: 0 auto;

			video {
				width: 100%;
				object-fit: cover;
				border-radius: 16px;
			}
		}
	}
	.upcomming_classrooms {
		.coursecard_wrap {
			display: block;

			.slick-slide {
				text-align: center;
			}
			.course_card {
				max-width: 370px;
				text-align: left;

				@include break-point(mobile){
					max-width: 375px;
				}
			}
		}
	}
}


// ___________________ Shubhra madam Page _____________________ //

.social_handle{

	ul{
		display: flex;
		justify-content: center;
		li{
			margin: 10px;
			border: 1px solid #DFD6E599;
			padding: 20px;
			text-align: center;
			border-radius: 10px;
			a{
				display: flex;
				flex-direction: column;
				color: $text-color;
				align-items: center;
				.icon{
					width: 45px;
					height: 45px;
					background-color: #145ECD;
					color: $white-color;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					font-size: 20px;
					margin-bottom: 5px;
				}
			}
		}
	}
}