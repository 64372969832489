@import "../../scss/index.scss";

// Common Css

.compare_course {
  .feescard_wrap {
    display: flex;
    width: 100%;
    justify-content: center;

    @include break-point(miniTab) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @include break-point(mobile) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .feescard {
      width: 100%;
      max-width: 400px;
      border-radius: 16px;
      border: 1px solid rgba(223, 214, 229, 0.6);
      overflow: hidden;
      margin: 10px;
      @include break-point(miniTab) {
        max-width: 45%;
      }
      @include break-point(mobile) {
        max-width: 325px;
      }
      .card_header {
        background: #fef3e9;
        padding: 30px;
        min-height: 178px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        border-radius: 16px;

        @include break-point(landscapeTab) {
          padding: 20px;
        }
        @include break-point(tablet) {
          min-height: 165px;
        }
        @include break-point(mobile) {
          height: auto;
          min-height: unset;
        }
        h4 {
          margin-bottom: 0;
          padding: 5px 0;
          @include break-point(landscapeTab) {
            font-size: 23px;
          }
          @include break-point(tablet) {
            font-size: 20px;
          }
        }
        strong {
          font-size: 25px;
          color: #b39071;
          display: flex;
          align-items: center;
        }

        .recommended {
          position: absolute;
          top: 0;
          right: 23px;
          color: $white-color;
          background-color: $red-color;
          padding: 6px 8px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          @include break-point(tablet) {
            font-size: 12px;
            padding: 3px 6px;
          }
        }
      }

      .card_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 178px);
        padding: 20px 25px;

        @include break-point(tablet) {
          padding: 15px 15px;
        }

        ul {
          padding-bottom: 20px;

          li {
            padding-left: 30px;
            margin-bottom: 15px;
            position: relative;

            &::after {
              position: absolute;
              background-image: url("../../assets/icons/list-active.svg");
              content: "";
              top: 5px;
              left: 0;
              height: 15px;
              width: 15px;
            }
          }
        }
      }

      &:nth-child(2) {
        .card_header {
          background: #ecf2fa;

          strong {
            color: $blue-color;
          }
        }
      }
      &:nth-child(3) {
        .card_header {
          background: #fff1f1;
        }
        strong {
          color: $red-color;
        }
      }
    }
  }
}

////////////////

.course_listing_section {
  .title {
    text-align: center;
    padding-bottom: 15px;
    @include break-point(mobile) {
      margin-bottom: 0;
    }
  }

  .course_listing {
    .listing_header {
      display: flex;
      width: 100%;
      position: sticky;
      top: 80px;
      background: #fff;
      z-index: 9;
      padding-top: 15px;
      margin-bottom: 20px;
      @include break-point(desktop) {
        top: 60px;
      }
      @include break-point(tablet) {
        position: static;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      @include break-point(mobile) {
        flex-direction: column;
      }
      .filter_button_wrap {
        width: 155px;

        @include break-point(tablet) {
          width: 100%;
          padding-top: 20px;
          justify-content: space-between;
          .filter_button {
            font-size: 13px;
          }
        }
        @include break-point(mobile) {
          padding-top: 30px;
        }
      }
    }

    .currrent_affairs_card {
      padding: 30px 0;
      // position: relative;
      @media (max-width: 800px) {
        padding-top: 10px;
      }
      @include break-point(mobile) {
        padding-bottom: 0;
      }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .courseside_nav {
        max-width: 300px;
        background: rgba(73, 113, 178, 0.05);
        border: 1px solid rgba(223, 214, 229, 0.6);
        border-radius: 16px;
        padding: 30px 20px;
        position: sticky;
        top: 153px;
        @include break-point(landscapeTab) {
          width: 260px;
          max-width: unset;
        }
        @media (max-width: 800px) {
          position: fixed;
          // left: -100%;
          width: 300px;
          transform: translateX(-100%);
          left: 0;
          transition: all 0.3s linear;
          top: 80px;
          z-index: 9999;
          background-color: $white-color;
          overflow-y: auto;
        }
        h5 {
          color: $blue-color;
          font-size: 17px;
          text-align: center;
          padding-bottom: 15px;
        }

        .nav-item {
          width: 100%;
          a {
            font-size: 15px;
            color: $text-color;
            color: rgb(0, 0, 0, 0.7);
            display: flex;
            width: 100%;
            &.active {
              background: rgba(73, 113, 178, 0.15);
            }
            img {
              margin-right: 10px;
              width: 100%;
              max-width: 22px;
            }
          }
        }
      }

      .course_section {
        max-width: calc(100% - 320px);
        padding: 30px 20px;
        border-left: 2px solid rgb(24 24 34/20%);
        @include break-point(landscapeTab) {
          max-width: unset;
          width: calc(100% - 270px);
        }
        @media (max-width: 800px) {
          width: 100%;
          border: 0;
          padding: 20px 15px;
        }
        @include break-point(mobile) {
          padding-top: 0;
        }

        .title_bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include break-point(mobile) {
            flex-direction: column-reverse;
          }
          .title {
            h5 {
              margin: 0;
              padding-bottom: 3px;
              font-size: 18px;
              @include break-point(mobile) {
                font-size: 16px;
              }
            }
            span {
              font-weight: bold;
              color: $blue-color;
              text-align: left;
              padding: 0 15px;
              display: block;
              font-size: 16px;
            }
            @include break-point(mobile) {
              padding: 0;
              text-align: left;
              width: 100%;
              font-size: 14px;
            }
          }
          .search_bar {
            width: 100%;
            max-width: 300px;
            position: relative;

            @include break-point(mobile) {
              max-width: 100%;
              margin-bottom: 10px;
            }
            .input {
              width: 100%;
              min-height: 38px;
              padding: 3px 33px 3px 10px;
              background: rgba(73, 113, 178, 0.1);
              border: 1px solid rgba(223, 214, 229, 0.6);
              border-radius: 8px;
              outline: none;
            }
            svg {
              position: absolute;
              right: 6px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 28px;
              color: $white-color;
              padding: 2px;
            }
          }
        }
      }
    }
    .filter_section {
      @include break-point(mobile) {
        margin-top: 0;
        flex-wrap: wrap;
      }
      .multiselect_wrap {
        @include break-point(mobile) {
          padding-top: 0;
        }
      }
    }
  }
  .courseTitle {
    padding-right: 20px;
    width: calc(100% - 150px);
    padding-bottom: 0;
    @include break-point(tablet) {
      width: 100%;
      padding-right: 0;
    }
    .slick-slider {
      .slick-arrow {
        background-color: $white-color;
        padding: 0;
        @include break-point(mobile) {
          padding: 0;
        }
      }
    }
    .slick-list {
      padding: 0 30px;
      .slick-track {
        display: flex;
        justify-content: space-between;
      }
      .nav-item {
        margin: 0 13px;
        a {
          margin: 0 10px;
        }
      }
    }
  }
  .Subjects_button {
    display: none;
    @media (max-width: 800px) {
      display: block;
    }
    @include break-point(mobile) {
      font-size: 11px;
      padding: 10px;
      border-radius: 16px;
      min-width: unset;
    }
  }
  .overlay {
    display: none;

    @media (max-width: 800px) {
      display: block;
      position: fixed;
      width: 100%;
      min-height: 100vh;
      z-index: 99;
      background-color: #00000063;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
    }
  }
}

.course_card_section {
  padding-top: 25px;

  .card_wrapper {
    display: flex;
    flex-wrap: wrap;

    .listing_card {
      position: relative;
      border-radius: 16px;
      width: 100%;
      max-width: 45%;
      display: flex;
      margin: 10px 10px 20px;
      overflow: hidden;
      @include break-point(mobile) {
        max-width: 100%;
        margin: 5px 0;
      }
      .upcomingTag {
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: $green-color;
        color: $white-color;
        padding: 0 6px;
        border-radius: 2px;
        border-bottom-left-radius: 10px;
        margin: 0;
        padding-bottom: 2px;
        font-size: 11px;
      }

      .image_wrap {
        width: 165px;
        height: 120px;
        overflow: hidden;
        // padding-left: 24px;
        padding-left: 0;
        background: #4971b2;
        @include break-point(landscapeTab) {
          // padding-left: 18px;
        }
        // &:hover {
        // 	transform: scale(1.05);
        // }
        @include break-point(mobile) {
          width: 150px;
          height: 85px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include transition;
        }
      }

      .card_content {
        width: 100%;
        border: 1px solid rgba(223, 214, 229, 0.6);
        border-left: 0;
        border-radius: 16px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 18px 35px 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        @include break-point(landscapeTab) {
          padding: 7px 15px 7px 12px;
        }
        h5 {
          font-size: 17px;
          text-align: left;
          margin: 0;
          @include break-point(desktop) {
            font-size: 15px;
          }
          @include break-point(mobile) {
            font-size: 14px;
          }
          &:hover {
            color: $blue-color;
            @include transition;
          }
        }
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 14px;
            display: flex;
            align-items: center;
            @include break-point(mobile) {
              font-size: 13px;
            }
            img {
              margin-right: 5px;
            }
          }
        }

        .tagimg {
          position: absolute;
          right: 15px;
          top: -15px;
          font-size: 30px;
          color: #b6c6e0;
          font-weight: 400;
          cursor: pointer;
          background-color: $white-color;
          &._mark {
            color: $blue-color;
          }
          @include break-point(mobile) {
            font-size: 25px;
          }
        }
      }

      &:nth-child(2n + 0) {
        .image_wrap {
          background: #fdcf27;
        }
      }

      &:nth-child(3n + 0) {
        .image_wrap {
          background: #dd5d59;
        }
      }
      &:nth-child(5n + 0) {
        .image_wrap {
          background: #4971b2;
        }
      }
      &:nth-child(7n + 0) {
        .image_wrap {
          background: #fce4e3;
        }
      }

      &.not_found {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;

        .image_wrap {
          width: 100%;
          height: auto;
          max-width: 480px;
          padding: 0;
        }
        h5 {
          font-size: 18px;
        }
      }
    }
  }
}

.subjectMenu {
  overflow: hidden;
  .course_listing_section {
    .course_listing {
      .listing_header {
        z-index: 1;
      }
      .courseside_nav {
        left: 0;
        transition: all 0.3s linear;
        transform: translateX(0);
      }
    }
    .overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

//////////// Compare chosen courses   //////////////

.comapre_course {
  .title {
    text-align: center;
    h3 {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
    p {
      margin: 0;
    }
  }
}

/////////////////////// Compare course  //////////////

.other_courses {
  .other_course {
    .course_card_wrap {
      // display: flex;
      // justify-content: center;

      .other_course_card {
        border: 1px solid rgba(223, 214, 229, 0.6);
        border-radius: 16px;
        max-width: 450px;

        @include break-point(mobile) {
          max-width: 320px;
        }
        .image_wrap {
          width: 100%;
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
          overflow: hidden;
          height: 230px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          padding: 20px;
          position: relative;

          @include break-point(tablet) {
            padding: 10px;
          }

          h5 {
            padding-right: 35px;

            @include break-point(tablet) {
              padding-right: 20px;
            }
          }
          ul {
            display: flex;
            padding-bottom: 20px;
            justify-content: space-between;

            @include break-point(desktop) {
              flex-direction: column;
              padding-bottom: 0;
            }
            li {
              display: flex;
              align-items: center;
              font-size: 14px;
              @include break-point(desktop) {
                padding-bottom: 15px;
              }

              img {
                margin-right: 5px;
                width: 20px;
              }
              &:not(:last-child) {
                padding-right: 10px;
              }
            }
          }

          .button_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include break-point(desktop) {
              flex-direction: column;
              align-items: flex-start;
            }

            strong {
              @include break-point(desktop) {
                padding-bottom: 15px;
              }
            }
            .course_price {
              span {
                display: block;
                &.ammount {
                  font-size: 25px;
                  font-weight: bold;
                  color: $blue-color;
                }
                &.text {
                  color: $green-color;
                  text-align: center;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}

///////////// Course detail page //////////////

.course_detail_section {
  .banner {
    padding-top: 30px;
    @include break-point(mobile) {
      padding-top: 10px;
    }
    .banner_content {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      @include break-point(tablet) {
        flex-direction: column;
      }

      .content {
        width: 100%;
        max-width: 50%;
        padding-right: 35px;
        @include break-point(tablet) {
          max-width: 100%;
          padding: 0;
          margin-bottom: 15px;
        }

        ._tag {
          color: $blue-color;
          border: 2px solid $blue-color;
          border-radius: 4px;
          margin-bottom: 10px;
          padding: 3px 8px;
          font-weight: 500;
        }
        b {
          font-size: 18px;

          @include break-point(mobile) {
            font-size: 16px;
          }
        }

        .button_wrap {
          margin-top: 30px;
        }

        .read_more {
          color: $blue-color;
          cursor: pointer;
          border-bottom: 1px solid;
          font-weight: 500;
          &:hover {
            color: $red-color;
          }
        }
      }
      .video_section {
        width: 100%;
        max-width: 50%;
        padding-left: 35px;
        @include break-point(landscapeTab) {
          padding-left: 0;
        }
        @include break-point(tablet) {
          max-width: 100%;
          margin: 0;
        }
        video {
          width: 100%;
          border-radius: 16px;
          object-fit: cover;
        }
      }
    }
  }

  .recommended_corse_section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .course_card {
      max-width: 330px;
      .content {
        .course_detail {
          h5 {
            min-height: 48px;
          }
          ._tag {
            padding: 2px 4px;
            background-color: $red-color;
            color: $white-color;
            border-radius: 4px;
            font-size: 11px;
            margin-bottom: 10px;
            min-height: 20.5px;
            display: inline-flex;
            align-items: center;
            svg {
              margin-right: 5px;
            }
          }
          li {
            del {
              width: 100%;
              padding-bottom: 3px;
              font-weight: 500;
            }
            span {
              width: auto;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  .billing_summery {
    padding: 40px;
    background-color: $light-blue-color;
    border-radius: 16px;
    display: flex;
    @include break-point(landscapeTab) {
      padding: 15px;
    }
    @include break-point(tablet) {
      flex-wrap: wrap;
    }
    .billing_card {
      margin-right: 30px;
      background: #ffffff;
      border: 1px solid #dfd6e5;
      border-radius: 16px;
      padding: 25px;
      width: 40%;
      @include break-point(landscapeTab) {
        margin-right: 15px;
      }
      @include break-point(tablet) {
        width: 47%;
      }
      @include break-point(mobile) {
        margin: 0 0 15px 0;
        width: 100% !important;
      }
      .description {
        strong {
          font-size: 18px;
          margin-bottom: 18px;
          display: inline-block;
        }
        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 100%;
            max-width: 47%;
            padding-bottom: 15px;
            margin-right: 5px;
            font-size: 14px;
            display: flex;
            align-items: center;
            @include break-point(landscapeTab) {
              max-width: 100%;
            }
            b {
              font-size: inherit;
            }
            img {
              margin-right: 8px;
            }
            &.course_fee {
              max-width: 100%;
              flex-wrap: wrap;
              h6 {
                font-weight: bold;
                color: $blue-color;
                font-size: 20px;
                width: 100%;
                small {
                  color: $green-color;
                  font-weight: 400;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
      &.addons {
        .description {
          ul {
            li {
              align-items: flex-start;
              input {
                margin-right: 5px;
                margin-top: 2px;
              }
              label {
                line-height: 1;
                small {
                  display: block;
                  color: $blue-color;
                  padding: 5px 0;
                  font-size: 13px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
    .card_summery {
      font-weight: 600;
      font-size: 18px;
      @include break-point(tablet) {
        margin-top: 15px;
      }
      h6 {
        font-weight: bold;
        color: $blue-color;
        font-size: 28px;
        margin: 0;
        padding: 10px 0;
        @include break-point(landscapeTab) {
          font-size: 25px;
        }
      }
      small {
        color: $green-color;
      }
    }
  }

  .navtab_content_details {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .courseTitle {
      .nav-item {
        a {
          border: 0;

          &.active {
            border: 0;
            border-bottom: 1px solid $red-color;
            border-radius: 13px;
            color: $red-color;
            font-weight: bold;
          }
        }
      }
    }
    .lectures_numbers {
      ul {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .card_slider {
      // display: flex;
      // flex-wrap: wrap;
      .card_content {
        width: 100%;
        max-width: 340px;
        margin: 10px;
        border: 1px solid #dfd6e5;
        border-radius: 16px;

        .cover_image {
          width: 100%;
          height: 170px;
          overflow: hidden;
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .video_icon {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            top: 0;
            font-size: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .content {
          padding: 15px 15px;
          position: relative;
          h5 {
            margin-bottom: 0;
          }
          ul {
            display: flex;
            padding-bottom: 10px;
            li {
              display: flex;
              align-items: center;
              color: #000;
              img {
                margin-right: 7px;
              }

              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .multitab {
    .courseTitle {
      @include break-point(mobile) {
        .nav-pills {
          flex-wrap: nowrap;
          overflow-x: auto;
          .nav-item {
            white-space: nowrap;
            a {
              border: none;
              padding: 0;
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
  .navtab_content {
    padding: 0;
  }
}

.core_takeaways {
  .takeaways {
    ul {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      @include break-point(tablet) {
        background-color: #dfe8f4;
        border-radius: 16px;
      }

      li {
        display: flex;
        align-items: flex-start;
        align-items: center;
        width: 100%;
        max-width: 50%;
        margin-bottom: 15px;
        padding: 20px;
        @include break-point(tablet) {
          max-width: 100%;
          margin: 0;
        }
        @include break-point(mobile) {
          padding: 18px 10px;
        }
        p {
          margin: 0;
        }
        b {
          display: block;
          font-size: 22px;
          color: $blue-color;
          @include break-point(desktop) {
            font-size: 20px;
          }
          @include break-point(tablet) {
            font-size: 18px;
          }
          @include break-point(mobile) {
            font-size: 16px;
          }
        }
        img {
          margin-right: 15px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          @include break-point(tablet) {
            width: 65px;
            height: 65px;
          }
        }
      }
    }
  }
}

.teacher_card_wrap {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  .teacher_card {
    padding: 0;
    border-radius: 16px 16px 0 0;
    display: flex;
    border: 1px solid #dfd6e5;
    overflow: hidden;
    @include break-point(mobile) {
      flex-direction: column-reverse;
      padding: 10px;
    }
    .content {
      width: 60%;
      padding: 25px;
      @include break-point(mobile) {
        width: 100%;
        margin-top: 10px;
      }
      h4 {
        color: $blue-color;
      }
      ul {
        li {
          display: flex;
          padding-bottom: 15px;
          align-items: flex-start;

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .teacher_image {
      display: flex;
      align-items: flex-end;
      width: 40%;
      justify-content: flex-end;
      @include break-point(mobile) {
        width: 100%;
        max-width: 230px;
        margin: 0 auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .card_link {
    ul {
      display: flex;
      justify-content: space-between;

      li {
        text-align: center;
        padding: 15px;
        background-color: $light-blue-color;
        width: 100%;
        @include break-point(mobile) {
          padding: 8px;
        }
        &:first-child {
          border-bottom-left-radius: 16px;
          margin-right: 1px;
        }
        &:last-child {
          border-bottom-right-radius: 16px;
        }
      }
    }
  }
}

.all_faculties_listing {
  .item {
    width: 100%;
    max-width: 230px;
    border-radius: 12px;
    padding: 10px;
    position: relative;
    border: 1px solid #aaaa;
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
    }
    .image_wrap {
      height: 240px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: #aaa;
        border-radius: 16px;
      }
    }
    h5 {
      margin-top: 10px;
      text-align: center;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 18px;
      @include break-point(desktop) {
        font-size: 16px;
      }
    }
  }
  .slick-slider {
    .slick-track {
      margin: 0 auto;
    }
  }
}
