@import "../../../scss/index.scss";

.testPage {
  .dashboard_tab {
    .nav-pills {
      padding: 20px 0;

      .nav-link {
        border: 1px solid #e7e7e7;
        background-color: transparent;
        color: $text-color;
        margin-right: 5px;

        &.active {
          border-color: $red-color;
          color: $red-color;
        }
      }
    }
  }

  .testPage_banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @include boxShadow;
    padding: 10px 15px;

    @include break-point(miniTab) {
      flex-direction: column;
    }

    .image_wrap {
      width: 100%;
      max-width: 350px;
      padding: 10px;

      img {
        width: 100%;
      }

      @include break-point(mobile) {
        padding: 0;
      }
    }

    .content {
      width: 100%;
      max-width: calc(100% - 360px);
      padding: 10px;

      @include break-point(miniTab) {
        max-width: 100%;
      }

      @include break-point(mobile) {
        padding: 10px 0;
      }

      h4 {
        padding: 0;
      }

      ul {
        display: flex;
        padding: 20px 0;

        @include break-point(tablet) {
          flex-wrap: wrap;
        }
        @include break-point(mobile){
          padding: 10px 0;
        }

        li {
          display: flex;
          align-items: center;
          padding-right: 15px;
          margin-bottom: 5px;

          svg {
            margin-right: 8px;
            color: #7f9bc9;
            font-size: 18px;
          }
        }
      }

      .button_wrap {
        padding: 15px 0;

        .dashboard_button {
          margin: 5px;

          svg {
            font-size: 18px;
          }

          &.red {
            background-color: $red-color;
            border-color: $red-color;

            &.fill {
              background-color: $red-color;
              border-color: $red-color;
            }
          }

          &.blue {
            color: $blue-color;
            border-color: $blue-color;
            background-color: transparent;

            &.fill {
              background-color: $blue-color;
              border-color: $blue-color;
              color: $white-color;
            }
          }
        }
      }
    }
  }

  .testPage_content {
    .instruction {
      ul {
        margin-bottom: 20px;

        li {
          margin-bottom: 10px;
        }
      }

      h5 {
        font-weight: bold;
      }

      p.notice {
        border: 1px solid #f1bebc;
        padding: 11px 15px;
        border-radius: 8px;
        color: $black-color;
      }
    }
  }
}

/////////////////////// Test Result Overview ///////////////////

.tesResult_summery {
  .testOverview_content {
    .overview {
      ul {
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        li {
          width: 100%;
          max-width: 23%;
          margin: 10px;
          padding: 20px 15px;
          background-color: $white-color;
          @include boxShadow;
          @include break-point(tablet) {
            margin: 5px;
          }
          @include break-point(miniTab) {
            max-width: 29%;
          }
          @include break-point(mobile) {
            max-width: 46%;
            margin: 5px;
            padding: 10px;
          }

          p {
            margin: 0;
          }

          h6 {
            @include break-point(mobile) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .compare_progress {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @include break-point(mobile) {
      flex-direction: column;
    }

    .image_wrap {
      width: 300px;
      margin: 0 auto;
      text-align: center;
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;

      img {
        width: 100px;
        height: 100%;
        border-radius: 50%;
      }

      h5 {
        font-weight: bold;
        font-size: 20px;
      }
    }

    ul {
      margin-bottom: 20px;
      padding: 0 15px;

      li {
        padding: 10px 0;

        p {
          margin: 0;
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
        }

        .progress {
          height: 10px;

          &.success {
            .progress-bar {
              background-color: $green-color;
            }
          }

          &.danger {
            .progress-bar {
              background-color: $red-color;
            }
          }
        }
      }
    }

    .student_you {
      width: 49%;
      padding: 15px;
      padding: 20px;
      @include boxShadow;
      position: relative;
      padding-top: 90px;

      @include break-point(mobile) {
        width: 100%;
        margin-bottom: 55px;
      }
    }

    .student_topper {
      width: 49%;
      padding: 15px;
      padding: 20px;
      @include boxShadow;
      position: relative;
      padding-top: 90px;

      @include break-point(mobile) {
        width: 100%;
      }
    }
  }
}
