@import "../../scss/index.scss";

.thanks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  .icon {
    font-size: 140px;
    text-align: center;
    color: $red-color;
  }
}
