
// $text-color:#181818;
$text-color: rgba(0, 0, 0, 0.7);
$title-color: #181822;
$nav-color: #252424;
$red-color: #DD5D59;
$blue-color: #4971B2;
$white-color: #fff;
$black-color: #000;
$green-color:#00AA44;
$purple-color: #7986CB;


$light-yellow-color: #FAEADB;
$light-blue-color: #DFE8F4;
$light-pink-color: #FCE4E3; 
$light-red-color: #FCEEEE;
$light-green-color: #68DD59;

// social icons
$facebook-color: #145ECD;
$youtube-color: #FF0000;
$likedin-color: #0276B7;
$twitter-color:#000;
$instagram-color: linear-gradient(210.43deg, #BC25A8 12.75%, #FF516C 50.21%, #FDCF27 86.89%);
$telegram-color:#229ED9;
$light-green-color: #00aa4429;



///// Border radius ///////

$radius: 16px;

$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;