@import "../../../scss/index.scss";

.card {
  background-color: #fff;
  /* Light background for the cards */
  border: 1px solid #ddd;
  /* Light border */
  border-radius: 8px;
  /* Rounded corners */
  padding: 20px;
  /* Inner spacing */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  transition: transform 0.3s;
  /* Smooth transition for hover effect */
  display: flex;
  /* Flexbox for content alignment */
  flex-direction: column;
  /* Align content vertically */
  justify-content: space-between;
  /* Space out content evenly */
  height: auto;
  /* Allow height to adjust based on content */
  overflow: hidden;
  /* Prevent overflow */
}

.cardh6 {
  margin-left: 33px;
  /* Align description text */
  font-weight: 600;
  /* Bold description */
  font-size: 15px;
  /* Description font size */
  white-space: normal;
  /* Allow text to wrap */
  overflow: hidden;
  /* Prevent overflow */
  text-overflow: ellipsis;
  /* Add ellipsis for overflowing text */
}
