@import "../../../scss/index.scss";

.free_resources {
	&.toppers_copies {
		.banner {
			background-image: url("../../../assets/images/topperscopies.png");
		}
	}
}

.toppers_copies {
	.topperscopies_content {
		.filter_section {
			display: flex;
			justify-content: space-between;
			@include break-point(mobile) {
				flex-direction: column-reverse;
			}
			.select_section {
				width: 100%;
				max-width: 80%;
				padding-right: 30px;
				display: flex;
				align-items: center;
				@include break-point(mobile) {
					max-width: 100%;
					flex-direction: column;
					padding: 0;
				}
				.form_field {
					padding: 0 20px 0;
					width: 100%;
					@include break-point(mobile) {
						padding: 0 0 10px 0;
					}
				}
			}
			.filter_button_wrap {
				width: 100%;
				max-width: 20%;
				justify-content: flex-end;

				@include break-point(mobile) {
					margin-bottom: 10px;
					max-width: 100%;
					justify-content: flex-start;
				}
			}
		}
	}

	.toppers_card_wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.toppers_card {
			width: 100%;
			border-radius: 16px;
			background-color: #fef3e9;
			margin: 10px;
			display: flex;
			position: relative;
			flex: 1 1;
			min-width: 372px;
			&:last-child {
				max-width: 48%;
			}

			@include break-point(tablet) {
				min-width: 360px;
			}

			@include break-point(mobile) {
				min-width: unset;
				max-width: 100%;
				flex-grow: unset;
				flex-shrink: unset;
				flex-basis: unset;
			}

			&:nth-child(2n + 2) {
				background-color: #ecf2fa;
				h3 {
					small {
						color: #4971b2;
					}
				}
			}
			&:nth-child(3n + 3) {
				background-color: #fff1f1;
				h3 {
					small {
						color: #dd5d59;
					}
				}
			}
			&:nth-child(4n + 4) {
				background-color: #ecf2fa;
				h3 {
					small {
						color: #4971b2;
					}
				}
			}
			&:nth-child(5n + 5) {
				background-color: #fef3e9;
			}
			h3 {
				font-size: 25px;
				display: flex;
				align-items: center;
				margin: 0;
				padding-bottom: 5px;

				small {
					font-size: 16px;
					padding-left: 15px;
					color: #b39071;
				}
				@include break-point(tablet) {
					flex-direction: column;
					align-items: unset;
					small {
						padding: 0;
						font-size: 15px;
					}
				}
			}
			.content {
				padding: 20px;
				width: 100%;

				p {
					font-size: 17px;
					@include break-point(tablet) {
						font-size: 15px;
					}
					b {
						display: block;
					}
				}
				.button_wrap {
					text-align: left;
					padding-bottom: 0;
				}
			}

			.image_wrap {
				img {
					position: absolute;
					bottom: 0;
					right: 0;
					width: 150px;
					height: 150px;
					object-fit: cover;
					border-bottom-right-radius: 16px;
				}
			}
		}
	}
}
