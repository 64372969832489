@import "../../../scss/index.scss";

.video_resource {
	.banner {
		background-image: url("../../../assets/images/VideoResource.png");
	}
	.video_resource_content {
		.tab-content {
			.page_title {
				padding-top: 30px;
			}

			.card_wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				.card_content {
					width: 100%;
					max-width: 300px;
					background: #ffffff;
					border: 1px solid #dfd6e5;
					border-radius: 16px;
					margin: 15px 10px;
					.cover_image {
						width: 100%;
						height: 190px;
						overflow: hidden;
						border-top-right-radius: 16px;
						border-top-left-radius: 16px;
						position: relative;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						a {
							position: absolute;
							width: 100%;
							height: 100%;
							text-align: center;
							top: 0;
							font-size: 30px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
					.content {
						padding: 15px 15px;
						.course_tag {
							text-transform: uppercase;
							background-color: $red-color;
							color: $white-color;
							font-size: 12px;
							padding: 4px 8px;
							border-radius: 6px;
							margin-bottom: 10px;
						}
						h5 {
							padding: 10px 0;
							margin: 0;
							font-size: 18px;
							font-weight: 500;
						}
						ul {
							display: flex;
							padding-bottom: 10px;

							li {
								display: flex;
								align-items: center;
								color: #000;
								&:first-child {
									margin-right: 10px;
								}
								img {
									margin-right: 7px;
								}
							}
						}
					}
				}
			}
		}
		.sub_courseTitle {
			.courseTitle {
				.nav-pills {
					.nav-item {
						a {
							border-color: transparent;
                            
							&.active {
								border-radius: 8px;
                                border-color: $blue-color;
							}
						}
					}
				}
			}
		}
	}
}
