@import "../../../scss/index.scss";

.subsection-prelims-syllabus-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin: -8px auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  padding: 12px 38px 12px 38px;
  max-height: 545px;
  overflow-y: auto;


}
.syllabus-container {
  position: relative; /* To position the back arrow inside this container */
}

.mobile-back-arrow {
  font-size: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  color: #000; /* Set your desired arrow color */
}

// .syllabus-button {
//   margin-top: 20px;  /* Adds space between the container and button */
//   padding: 10px 20px;
//   background-color: #FFFFFF; /* Green background */
//   color: #DD5D59;
//   border: 1px solid #DD5D59;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 14px;
//   font-weight: 600;
//   padding: 10px;
// }

// .syllabus-button:hover {
//   background-color: #FFFFFF; /* Green background */
//   color: #DD5D59;
//   border: 1px solid #DD5D59;
//   border-radius: 5px;
//   font-size: 14px;

//  /* Darker green on hover */
// }


// .syllabusp   {
//   margin-bottom: 15px;
//   font-size: 15px;
//   font-weight: 500;
//   text-align: center;

// }

// .syllabusul {
//   padding: 6px 2px 2px 2px;
//   list-style: disc;

//   li {
//     padding: 2px;
//   }
// }

// .syllabush3 {
//   font-size: 19px;
//   text-align: center;
// }

// .syllabush4 {
//   font-size: 19px;
//   text-align: center;

// }

// .points-list {
//   list-style-type: none; // Remove default list bullets
//   padding: 0; // Remove padding
//   margin: 20px 0; // Add margin for spacing

//   li {
//     text-align: left; // Align text left within list items
//     margin-bottom: 10px; // Add space between list items
//   }

//   ul {
//     list-style-type: disc; // Use disc bullets for nested lists
//     padding-left: 20px; // Add padding to nested lists for indentation
//   }
// }