@import "../../../scss/index.scss";

.header_wrapper {
  min-height: 72px;
  @include break-point(desktop) {
    min-height: 62px;
  }
}
.userHeader {
  box-shadow: 0px 3px 36px #0000000a;
  padding: 15px 0;
  z-index: 99;
  background-color: $white-color;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #aaa6;
  @include break-point(desktop) {
    padding: 10px 0;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    width: 100%;
    max-width: 230px;
    a {
			display: block;

			img {
				width: 100%;
			}
		}

    @include break-point(mobile) {
      max-width: 160px;
    }
    img {
      width: 100%;
    }
  }

  .mobile-button {
    font-size: 23px;
    width: 27px;
    height: 27px;
    color: $red-color;
    margin-left: 13px;
    @media (min-width: 993px) {
      display: none;
    }
    .open {
      display: block;
    }
    .close {
      display: none;
    }
  }

  .user_nav {
    width: 100%;
    @include break-point(tablet) {
      width: 250px;
      position: absolute;
      height: 100vh;
      background-color: $white-color;
      top: 100%;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      left: 0;
      border-top: 1px solid #f1f1f1;
      transition: all 0.3s cubic-bezier(1, 0.64, 1, 1);
      z-index: 10;
      transform: translateX(-100%);
    }
    ul {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding: 0 20px 0 40px;
      @include break-point(tablet) {
        flex-direction: column;
        padding-left: 0;
      }

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
        @include break-point(mobile) {
          margin-bottom: 5px;
        }
        a {
          padding: 4px 10px;
          color: $text-color;
          font-weight: 500;
          font-size: 16px;
          position: relative;
          &:hover {
            color: $red-color;
          }
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            margin: 0 auto;
            left: 0;
            right: 0;
            border-bottom: 2px solid $red-color;
            top: 100%;
            max-width: 0;
            transition: all 0.3s linear;
          }
          &.active,
          &:hover {
            &::after {
              max-width: 80%;
            }
          }
        }
        .button {
          display: none;
          @include break-point(tablet) {
            display: block;
            border-radius: 10px;
            margin-left: 5px;
            padding: 4px;
          }
        }
      }
    }
  }
  .user_section {
    width: 100%;
    // max-width: 300px;
    padding-left: 20px;
    @include break-point(tablet) {
      max-width: 100%;
    }
    ul {
      display: flex;
      align-items: center;
      font-size: 25px;
      justify-content: flex-end;
      li {
        color: $red-color;

        &:not(:last-child) {
          margin-right: 15px;
          @include break-point(mobile) {
            margin-right: 10px;
          }
        }

        .button {
          @include break-point(tablet) {
            display: none;
          }
        }
      }
    }
  }

  .position {
    position: relative;
    cursor: pointer;
  }
  .icon {
    position: relative;
    width: 56px;
  }
  .badge {
    position: absolute;
    top: 0px;
    right: -2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 15px;
    height: 15px;
    padding: 1px 5px 0 5px;
    border: 2px solid #f4fdff;
    border-radius: 500px;
    font-size: 11px;
    color: #fff;
    text-align: center;
    z-index: 1;
    transition: transform 0.15s, background-color 0.5s;
  }

  .green {
    background: #26b960;
  }

  .position:hover .badge {
    background-color: #000;
    transform: scale(1.08);
  }
  .position:active .badge {
    background-color: #555;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.75);
  }
  @include break-point(mobile) {
    .position:active .badge {
      transition: none;
    }
    .icon {
      width: 44px;
    }
    .badge {
      top: 0px;
      right: -2px;
      min-width: unset;
      height: 14px;
      width: 14px;
      padding: 0 5px;
      transition: transform 0.1s, background-color 0.2s;
    }
    .position:hover .badge {
      transform: scale(1);
    }
  }
}

.notification_wrapper {
  ul {
    .loading {
      span {
        color: #000c !important;
        background-color: #000c !important;
      }
    }
  }
  .notification_inner {
    h6 {
      text-align: left;
      color: #000;
      font-size: 13px;
      padding-top: 0 !important;
    }
    .notification {
      position: relative;
      background-color: #ffedee;
      border-radius: 5px;
      padding: 10px 8px;
      width: 100%;

      .ping {
        position: absolute;
        left: 5px;
        top: 5px;
      }

      .dot {
        position: relative;
        width: fit-content;
        background: linear-gradient(
          135deg,
          $blue-color,
          $blue-color 48%,
          $blue-color
        );
        border-radius: 40px;
        width: 8px;
        height: 8px;
        padding: 0;
      }

      .heartbeat {
        position: absolute;
        width: fit-content;
        background-color: $blue-color;
        border-radius: 40px;
        width: 8px;
        height: 8px;
        padding: 0;
        opacity: 0.75;
        animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
      }
      .del_notification {
        position: absolute;
        right: 4px;
        margin: 0;
        top: -2px;
        padding: 0;
        background-color: transparent;
        font-size: 16px;
        cursor: pointer;
      }
      span {
        display: block;
        margin: 0;
        word-break: break-all;
      }
      h6 {
        padding-top: 0;
        color: #000;
        text-align: left;
        font-size: 1rem;
      }
      p {
        font-size: 13px;
        color: #000;
      }

      @keyframes ping {
        75%,
        100% {
          transform: scale(2);
          opacity: 0;
        }
      }
    }
  }
}

.menuOpen {
  .user_nav {
    transform: translateX(0);
  }
  .mobile-button {
    .open {
      display: none;
    }
    .close {
      display: block;
    }
  }
  .mobile_overlay {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

/* In your CSS file */
.hide-on-mobile {
  display: block; /* Default display style */
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none; /* Hide on mobile screens */
  }
}


.upcomingTag {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #00AA44;
  color: #fff;
  padding: 0 6px;
  border-radius: 2px;
  border-bottom-left-radius: 10px;
  margin: 0;
  padding-bottom: 2px;
  font-size: 11px;
}

.platform_indicator {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.platformTag {
  // background-color:red;
  // color: #fff;
  // padding: 0 6px;
  // margin-top: 2px;
  // border-radius: 2px;
  // font-size: 11px;
  // line-height: normal;
  position: absolute;
  top: -4px;
  right: 1px;
  background-color: red;
  color: #fff;
  padding: 0 6px;
  border-radius: 2px;
  border-top-right-radius: 10px;
  margin: 0;
  padding-bottom: 2px;
  font-size: 11px;
}

 .mycourses_listing li {
  position: relative;
}
