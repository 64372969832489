@import "../../scss/index.scss";

.testimonials_section {
  .testimonials {
    padding: 0 50px;
    @include break-point(mobile){
      padding: 0;
    }
    .testimonial_card {
      width: 100%;
      // max-width: 380px;
      width: 165px;
      margin: 0 auto;
      @include break-point(landscapeTab) {
        width: 135px;
      }
      @include break-point(tablet) {
        width: 95%;
      }
      @include break-point(mobile) {
        width: 100%;
      }
      .active_card {
        .image_wrap {
          position: relative;
          border-radius: 16px;
          height: 165px;
          overflow: hidden;
          @include break-point(landscapeTab) {
            height: 140px;
          }
          @include break-point(tablet) {
            height: 290px;
          }
          img {
            width: 100%;

            @include break-point(landscapeTab) {
              height: 100%;
              object-fit: cover;
            }
          }

          .playicon {
            display: none;
          }
        }

        p {
          display: none;
        }
      }

      .content {
        text-align: center;
        @include break-point(landscapeTab) {
          font-size: 13px;
        }
        H5 {
          margin-bottom: 0;
          font-size: 16px;
          padding: 5px 0;
          font-weight: bold;
          @include break-point(landscapeTab) {
            font-size: 13px;
          }
        }
      }

      .playicon {
        font-size: 20px;
        .videoplay_icon {
          width: 40px;
          height: 40px;
        }
      }
    }

    .slick-slider {
      .slick-list {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
        @include break-point(desktop){
          padding-top: 55px !important;
          padding-bottom: 50px !important;
        }
        @include break-point(mobile) {
          padding-top: 20px !important;
          padding-bottom: 20px !important;
        }
      }

      .slick-slide {
        opacity: 0;
        &.slick-cloned {
          display: block;
        }
        &.slick-active{
          opacity: 1;
        }

        &.slick-active.slick-center.slick-current {
          display: block;

          .testimonial_card {
            transform: scale(1.6);
            // @include transition;
            transition: all 0.7s linear;

            @include break-point(desktop) {
              transform: scale(1.3);
            }
            @include break-point(tablet) {
              transform: scale(1.2);
            }
            @include break-point(mobile){
                transform: unset;
            }

            .active_card {
              .image_wrap {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;

                img {
                }

                .playicon {
                  display: block;
                }
              }

              p {
                display: block;
                font-size: 12px;
                text-align: center;
                border: 1px solid rgba(223, 214, 229, 0.6);
                border-radius: 15px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                margin: 0;
                padding: 5px;
                color: #4971B2;
                line-height: 1.2;
              }
            }

            .content {
              text-align: center;

              @include break-point(landscapeTab) {
                font-size: 13px;
              }
              h5 {
                font-size: 13px;
                padding: 0;
                line-height: 1;
                padding-top: 4px;

                @include break-point(landscapeTab) {
                  font-size: 13px;
                  padding-bottom: 0;
                }
              }

              span {
                font-size: 10px;
              }
            }
          }
        }
        // div div {
        //   @include break-point(landscapeTab) {
        //     height: 100%;
        //   }
        //   a {
        //     @include break-point(landscapeTab) {
        //       height: 100%;
        //     }
        //   }
        // }
      }

      .slick-arrow {
        &.slick-prev {
          top: 42%;
        }
        &.slick-next {
          top: 42%;
        }
      }
    }
  }
}
