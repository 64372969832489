@import "../../../scss/index.scss";


.upload_answer_sheet{
    ._box{

        table{
            text-align: center;
            thead{
                th{
                    text-align: center;
                }
            }
            tbody{
                tr{
                    td{
                        .dashboard_button{
                            font-size: 13px;
                            padding: 3px 10px;
                            display: flex;
                            align-items: center;
                            margin: 0 auto;
                            justify-content: center;
                            min-width: 99px;
                            svg{
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.input-appointment-date-picker {
    margin-top: 0px;
    width: 148px;
    height: 38px;
    text-align: center;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.05);
    position: relative;
    display: flex;


}

.appointment-date-picker {
    position: relative;
}


.input-with-icon {
    width: 100%;
    padding: 3px 30px 8px 10px; /* Add padding to make space for the icon */
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    cursor: pointer;
}

.calendar-icon {
    position: absolute;
    right: 10px;
    top: 7px;
    color: #000000b5;
    font-size: 16px;
    cursor: pointer;
    margin-left: 20px;
}

/* Optional: Add focus and hover effects to the input */
.input-with-icon:focus {
    border-color: #000000b5;
}

.input-with-icon:focus + .calendar-icon {
    color: #000000b5;
}

.clear-icon {
    cursor: pointer;
    position: absolute;
    right: -11px; /* Align the clear icon to the right of the input field */
    top: 50%;
    transform: translateY(-50%); /* Center it vertically */
    color: #888; /* Optional: Change color of the clear icon */
}


.student-details-btn{
    padding: 5px 10px;
    border: 1.5px solid $red-color;
    background-color: $white-color;
    border-radius: 5px;
    transition: 1s;
    a{
        color: $red-color;
    }
}

.student-details-btn:hover{
    border: none;
    background-color: $red-color;
    a{
        color: $white-color;
    }
}
