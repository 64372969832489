@import "./index.scss";

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 15px;
  margin: 0 auto;

  @include break-point(desktop) {
    max-width: 1250px;
  }

  @include break-point(mobile) {
    padding: 0 10px;
  }
}

// Buuton //

.enquiry_btn {
  // position: sticky !important;
  // bottom: 20% !important;
  // z-index: 9 !important;
  // left: 100% !important;
  // background: transparent !important;
  // padding: 0 !important;
  position: fixed !important;
  bottom: 97px !important; /* Adjust as needed */
  right: 30px !important; /* Adjust as needed */
  z-index: 999 !important;
  background: transparent !important;
  padding: 0 !important;
  img {
    height: 55px;
    width: 55px;
    border-radius: 10px 0px 0px 10px;
    padding: 0;
  }
}

.enquiry_btn1 {
  // position: sticky !important;
  // bottom: 20% !important;
  // z-index: 9 !important;
  // left: 100% !important;
  // background: transparent !important;
  // padding: 0 !important;
  position: fixed !important;
  bottom: 150px !important; /* Adjust as needed */
  right: 30px !important; /* Adjust as needed */
  z-index: 999 !important;
  background: transparent !important;
  padding: 0 !important;
  img {
    height: 55px;
    width: 55px;
    border-radius: 10px 0px 0px 10px;
    padding: 0;
  }
}

.button,
button.button,
.btn.button {
  border: 1px solid $red-color;
  padding: 9px 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: $red-color;
  display: inline-block;
  border-radius: 28px;
  background-color: $white-color;
  position: relative;
  overflow: hidden;
  z-index: 1;
  // text-transform: capitalize;
  min-width: 132px;
  @include transition;
  @include break-point(desktop) {
    font-size: 14px;
  }
  @include break-point(largeTab) {
    padding: 10px 20px;
    font-size: 15px;
  }

  .icon {
    margin-left: 15px;
    transition: all 0.3s linear;
    position: relative;
    top: -1px;
    color: inherit;

    &.arrow_left {
      padding-right: 10px;
    }
  }

  &:hover {
    color: $white-color;
    background-color: $red-color;
    // -webkit-animation: pulse 1s;
    // animation: pulse 1s;
    // box-shadow: 0 0 0 7px rgb(255 255 255 / 0%);
  }

  &:focus,
  &:active,
  &:focus-visible,
  &.active {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 9px rgb(255 255 255 / 0%);
    border-color: $red-color !important;
    background-color: $red-color !important;
    color: $white-color;

    .icon {
      transform: translateX(17px);
      color: inherit;

      @include break-point(largeTab) {
        transform: translateX(8px);
      }
      &.arrow_left {
        transform: translateX(-17px);

        @include break-point(largeTab) {
          transform: translateX(-8px);
        }
      }
    }
  }
}

// disable button
.button:disabled,
button.button:disabled,
.btn.button:disabled {
  cursor: not-allowed;
  outline: none;
  opacity: 0.5;
}

.image_wrap {
  img {
    &.circle {
      border-radius: 50%;
    }

    &.dShape {
      mix-blend-mode: luminosity;
      border-radius: 10px 100px 100px 10px;
    }
    &.radius {
      border-radius: 10px;
    }
  }
}

.button_wrap {
  &.padding {
    padding: 25px 0;
    text-align: center;

    @include break-point(tablet) {
      padding: 0;
      padding-top: 30px;
    }
  }

  &.fill {
    .button {
      background-color: $red-color;
      color: $white-color;
    }
  }
  &.center {
    text-align: center;
  }
  &.padding_bottom {
    padding-bottom: 0;
  }
}

//////////////////////////////////// Regional Centres ////////////////
.regional_cetners {
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    li {
      margin: 10px;

      @include break-point(tablet) {
        margin: 5px;
      }
    }
  }
  .other_mode {
    text-align: center;
    margin: 0;
    padding-top: 20px;
  }
}

////////////////////// Form INput style //////////////////

textarea.input {
  height: 140px;

  @include break-point(mobile) {
    min-height: 145px;
  }
}

.form_field {
  padding-bottom: 20px;

  &.has_icon {
    position: relative;

    input[type="password"] {
      padding-right: 30px;
    }
  }

  .eye_icon {
    position: absolute;
    font-size: 30px;
    right: 4px;
    cursor: pointer;
    top: 32px;
  }

  .label {
    display: block;
    padding-bottom: 10px;
    font-weight: 600;
    text-align: left;
    text-transform: capitalize;
    @include break-point(desktop) {
      padding-bottom: 5px;
    }
    .required {
      color: $red-color;
      font-weight: 600;
    }
  }

  .input {
    outline: none;
    width: 100%;
    background-color: rgba(73, 113, 178, 0.1);
    opacity: 0.8;
    border: 1px solid rgba(223, 214, 229, 0.6);
    border-radius: 4px;
    min-height: 50px;
    padding: 5px 15px;
    color: $black-color;
    font-weight: 500;
    font-size: inherit;
    @include transition;
    @include break-point(desktop) {
      min-height: 42px;
    }
    &:focus {
      box-shadow: 0 0 0px 1px $blue-color;
    }
    &.is-invalid {
      border-color: #dc3545;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}
.checkbox_wrap {
  display: flex;
  align-items: center;
  input {
    margin-right: 10px;
  }
}
.forget-password_text {
  margin: 0;
  padding: 0px 0;
  text-align: right;
  font-weight: 500;
}

.form_field_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;

  @include break-point(tablet) {
    flex-wrap: wrap;
    padding: 0;
  }

  .form_field {
    width: 100%;
    max-width: 49%;
    padding: 0;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }

    @include break-point(tablet) {
      max-width: 100%;
      padding-bottom: 20px;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.check-box {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  @include break-point(mobile) {
    align-items: flex-start;
  }
  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    padding: 0 5px;
  }
}

/////////////////////

.spacing {
  padding: 40px 0;
  @include break-point(desktop) {
    padding: 30px 0;
  }
}

.page_title {
  padding-bottom: 40px;
  text-align: center;

  @include break-point(mobile) {
    padding-bottom: 30px;
  }

  h3 {
    font-size: 32px;
    text-transform: capitalize;
    margin-bottom: 0;
    @include break-point(desktop) {
      font-size: 30px;
    }
    @include break-point(mobile) {
      font-size: 22px;
    }
  }

  p {
    font-size: 15px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  &.padding {
    padding-bottom: 15px;

    @include break-point(mobile) {
      padding-bottom: 0;
    }
  }
}

// Date calender tag //////////////

.date-tag {
  background-color: $red-color;
  color: $white-color;
  position: absolute;
  right: 15px;
  text-align: center;
  padding: 7px 12px;
  border-radius: 8px;
  font-weight: 500;
  top: -45px;
  font-size: 12px;
  line-height: 1.4;

  span {
    display: block;
  }

  strong {
    font-size: 22px;
  }
}

////////////////////////////////////// Filter Button /////////////////////////////////////

.filter_button_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  .filter_button {
    background: $red-color;
    border: 1px solid $red-color;
    border-radius: 16px;
    color: $white-color;
    display: flex;
    align-items: center;
    padding: 6px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .remove_filter {
    font-size: 20px;
    background-color: $red-color;
    color: $white-color;
    margin-left: 5px;
    padding: 0 7px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    height: 37px;
    width: 55px;
    justify-content: center;
    cursor: pointer;
    @include break-point(mobile) {
      height: 35px;
      width: 47px;
      font-size: 18px;
    }
  }
}

.filter_section {
  display: flex;
  width: 100%;

  .multiselect_wrap {
    width: 100%;
    padding: 10px;
  }
}

////////////////////////////////////// Upcoming Exam and Course /////////////////////////////////////

// .coursecard_wrap{
// display: flex;
// flex-wrap: wrap;
// }
.course_card {
  width: 100%;
  max-width: 320px;
  background: #ffffff;
  border: 1px solid #dfd6e5;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include break-point(tablet) {
    max-width: 280px;
  }
  // @include break-point(tablet) {
  //   max-width: 360px;
  // }
  .image_wrap {
    width: 100%;
    height: 210px;
    overflow: hidden;
    border-radius: 15px 15px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s cubic-bezier(0.23, 0.5, 1, 1);
    }

    &:hover {
      img {
        transform: scale(1.01);
      }
    }
  }

  .content {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include break-point(mobile) {
      padding: 15px 10px;
    }
    .course_detail {
      @include break-point(mobile) {
        padding: 0;
      }

      h5 {
        font-size: 17px;
        font-weight: bold;
        margin: 0;
        padding-bottom: 0;
        min-height: 56px;
      }

      ul {
        padding-top: 15px;

        li {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 15px;
          font-size: 14px;
          align-items: center;

          img {
            display: inline-block;
            width: 25px;
            margin-right: 17px;
          }
          b {
            margin-left: 5px;
          }
          h6 {
            color: $blue-color;
            font-weight: 700;
            margin-bottom: 0;
            font-size: 18px;
          }

          span {
            display: block;
            color: $green-color;
            width: 100%;

            @include break-point(landscapeTab) {
              width: auto;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .button_wrap {
      padding-top: 15px;
      text-align: center;
      @include break-point(mobile) {
        padding-top: 15px;
      }
    }
  }
}

.upcomming_classrooms {
  .coursecard_wrap {
    display: flex;
    justify-content: center;
    height: 100%;
    .course_card {
      margin: 10px;
      @include break-point(miniTab) {
        max-width: 300px;
      }
      .content {
        .course_detail {
          ul {
            li {
              span {
                width: auto;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

////////////////////// Upcoming Workshops /////////////////////////////

.upcoming_workshop {
  .container {
    max-width: 1200px;
  }

  .upcoming_workshop_content {
    .workshop_card {
      border: 1px solid #dfd6e5;
      border-radius: $radius;
      width: 100%;
      max-width: 350px;
      margin: 10px;

      .image_card {
        width: 100%;
        height: 210px;
        position: relative;
        overflow: hidden;

        @include break-point(miniTab) {
          // height: auto;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)
          );
          border-radius: 16px 16px 0 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
        }
      }

      .content {
        padding: 16px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .date {
          background-color: $red-color;
          color: $white-color;
          position: absolute;
          right: 15px;
          text-align: center;
          padding: 7px 12px;
          border-radius: 8px;
          font-weight: 500;
          top: -45px;
          font-size: 12px;

          span {
            display: block;
          }

          strong {
            font-size: 22px;
            line-height: 1;
          }
        }

        h4 {
          font-size: 25px;
          margin-bottom: 5px;
        }

        p {
          b {
            display: block;
          }
          .centerName {
            padding-right: 10px;
          }
        }

        .button_wrap {
          padding-top: 15px;
        }
      }
    }
  }
}

// //////////////////// Annoucement //////////////////

.annoucement {
  .card_wrap {
    display: flex;
    justify-content: center;
    width: 100%;

    @include break-point(miniTab) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @include break-point(mobile) {
      justify-content: center;
    }
    .annoucement_card {
      margin: 10px;
      width: 100%;
      max-width: 350px;
      background: #ffffff;
      border: 1px solid #dfd6e5;
      border-radius: 16px;

      @include break-point(miniTab) {
        max-width: 300px;
      }
      .image_wrap {
        height: 214px;
        overflow: hidden;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card_body {
        padding: 15px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .calender {
          position: absolute;
          top: -50px;
          right: 8px;
          background-color: $red-color;
          color: $white-color;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          display: inline-block;
          text-align: center;
          padding: 5px 18px;

          small {
            font-size: 12px;
            display: block;
            line-height: 1;
          }
          h4 {
            color: $white-color;
            margin: 0;
            padding: 4px 0;
            line-height: 1;
            font-size: 20px;
          }
          span {
            display: block;
            border: 1px solid $red-color;
            color: $red-color;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            position: absolute;
            width: 100%;
            left: 0;
            font-size: 10px;
            font-weight: bold;
            top: 100%;
            padding: 3px;
          }
        }
        h4 {
          font-size: 22px;
          line-height: 1.2;
          font-weight: 500;
          padding: 10px 0;
          margin: 0;
        }
        .cardtag {
          color: $blue-color;
          font-weight: 500;
          padding-bottom: 5px;
        }

        .button_wrap {
          padding-top: 20px;
        }
      }
    }
  }
}

////////////////////////////////////// Common Banner ///////////////////////////////

.common_banner {
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  position: relative;

  @include break-point(tablet) {
    background-position: center;
    min-height: 340px;
  }
  @include break-point(mobile) {
    min-height: 300px;
  }
  .banner_content {
    width: 100%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h3 {
      text-transform: uppercase;
    }
  }
}

////////////////////////////////////// Our Programmes ///////////////////////////////

.our_programmes {
  .programmes_cardwrap {
    // display: flex;
    // justify-content: center;
    // @include break-point(mobile) {
    //   flex-direction: column;
    // }
    .slick-slider {
      .slick-list {
        .slick-track {
          display: flex;

          .slick-slide {
            display: flex;
            height: unset;
          }
          .slick-slide > div {
            height: 100%;
            width: 100%;
          }

          .programme_card {
            height: 100%;
            display: flex !important;
          }
        }
      }
    }
    .programme_card {
      width: 100%;
      margin-right: 15px;
      border-radius: 15px;
      overflow: hidden;
      max-width: 390px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include break-point(tablet) {
        margin-right: 7px;
      }
      &:last-child {
        margin-right: 0;
      }

      @include break-point(mobile) {
        max-width: 100%;
        margin: 0 0 10px 0;
      }
      .content {
        background-color: #ecf2fa;
        padding: 15px;
        position: relative;
        height: 100%;
        @include break-point(tablet) {
          padding: 20px;
        }
        .image_wrap {
          text-align: center;
          height: 100px;
          overflow: hidden;
          margin-bottom: 10px;
          img {
            width: 100%;
            max-width: 115px;
            margin: 0 auto;
          }
        }
        .head {
          // padding-right: 50px;
          // position: relative;

          @include break-point(miniTab) {
            padding-right: 0;
          }
          @include break-point(mobile) {
            padding-right: 99px;
          }

          .card_top_icons {
            font-size: 75px;
            position: absolute;
            top: -25px;
            right: -10px;
            color: #7986cb;
          }
        }
        h5 {
          letter-spacing: 1px;
          line-height: 1.2;
          margin-bottom: 0;
          font-weight: bold;
          font-size: 21px;
          @include break-point(mobile) {
            font-size: 18px;
          }
        }

        .sub_title {
          font-size: 15px;
          line-height: 1.2;
          margin-bottom: 10px;
          @include break-point(mobile) {
            font-size: 13px;
          }
        }

        .course_price {
          display: block;
          color: $blue-color;
          font-weight: 800;
          font-size: 18px;
          line-height: 24px;
          padding: 0 0 10px 0;

          @include break-point(miniTab) {
            font-size: 18px;
          }
        }

        ul {
          li {
            padding-left: 30px;
            margin-bottom: 15px;
            position: relative;
            @include break-point(desktop) {
              margin-bottom: 10px;
            }
            @include break-point(mobile) {
              margin-bottom: 8px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &::after {
              position: absolute;
              background-image: url("../assets/icons/list-active.svg");
              content: "";
              top: 5px;
              left: 0;
              height: 15px;
              width: 15px;
            }
          }
        }

        .linkOverlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          // disabled link
          &.disabled {
            cursor: not-allowed;
          }
        }

        .reginal_center_link {
          margin-top: 10px;
          text-align: center;

          // disabled link
          &.disabled {
            cursor: not-allowed;
          }
        }
      }
      select {
        border-radius: 6px;
        border-color: $red-color;
        color: $red-color;
        padding: 2px 9px;
        font-size: 13px;
        outline: none;
      }

      .learnmore {
        text-align: center;
        padding: 10px 5px;
        border: 1px solid rgba(223, 214, 229, 0.6);
        border-radius: 0px 0px 16px 16px;
      }
      &.online {
        .content {
          .head {
          }
        }
      }

      &.classroom {
        // background-color: #FEF3E9;
        .content {
          background-color: #fef3e9;

          .course_price {
            color: #b29071;
          }
        }
      }

      &.regional {
        // background-color: #FFF1F1;
        .content {
          background-color: #fff1f1;

          .course_price {
            color: #dd5d59;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// Our Course ///////////////////////////////////////////////////

.our_courses {
  .course_wrap {
    .nav_tab {
      padding-bottom: 35px;
      @include break-point(mobile) {
        padding-bottom: 8px;
      }

      > .nav-pills {
        justify-content: center;

        .nav-item {
          margin: 0 15px;
          @include break-point(mobile) {
            margin: 0 0 8px 0;
            width: 100%;
            text-align: center;
            border: 1px solid $blue-color;
            border-radius: 16px;
            overflow: hidden;
          }
          a {
            color: $text-color;
            font-weight: 600;
            border-radius: 0;
            font-size: 18px;
            @include break-point(mobile) {
              font-size: 16px;
            }
            &.active {
              color: $blue-color;
              background-color: transparent;
              border-bottom: 2px solid;
              @include break-point(mobile) {
                border: 0;
                color: $white-color;
                background-color: $blue-color;
              }
            }
          }
        }
      }
    }

    .navtab_content {
      .general_study_tab {
        .nav-pills {
          justify-content: center;
          @include break-point(mobile) {
            flex-wrap: nowrap;
            overflow-x: auto;
            justify-content: unset;
            &::-webkit-scrollbar {
              height: 2px;
            }
          }
          > .nav-item {
            a {
              font-size: 15px;
              color: #909090;
              font-weight: 600;
              border: 1px solid #4971b240;
              border-color: transparent;
              border-radius: 20px;
              @include break-point(mobile) {
                white-space: nowrap;
                margin-bottom: 5px;
              }
              &.active {
                color: $red-color;
                background-color: transparent;
                border-color: #dd5d5938;
              }
            }
          }
        }
      }

      .general_study_content {
        .content {
          .course_list {
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 25px;
            justify-content: center;
            li {
              width: 100%;
              max-width: 200px;
              margin: 10px 10px;
              border-radius: 7px;
              display: flex;
              overflow: hidden;
              @include break-point(tablet) {
                max-width: 22%;
              }

              @include break-point(mobile) {
                max-width: 26%;
              }
              a {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                img {
                  width: 100%;
                  border-radius: 7px;
                  height: 100%;
                  object-fit: cover;
                  @include transition;
                }

                strong {
                  display: block;
                  text-align: center;
                  color: #5b5b5b;
                  padding: 10px 0;
                }

                &:hover,
                :active {
                  img {
                    transform: scale(1.06);
                  }
                }
              }
            }
          }

          .button_wrap {
            // padding: 25px 0;
            text-align: center;
          }
        }
      }
    }
  }
}

//////////////////// Title slider //////////////////////////////////////////

.courseTitle {
  padding-bottom: 35px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .nav-pills {
    text-align: center;
    display: block;

    .slick-slide {
      padding: 0;
    }
    .slick-slider {
      .slick-arrow {
        background-color: $white-color;
        padding: 0 25px;
        @include break-point(mobile) {
          display: none !important;
        }
      }
      .slick-track {
        display: flex;
        justify-content: center;
      }
    }

    .nav-item {
      // margin: 0 10px;
      a {
        font-size: 17px;
        font-weight: 600;
        color: $text-color;
        border-bottom: 1px solid #00000026;
        border-radius: 0;
        padding: 8px 4px;
        text-align: center;
        margin: 0 15px;
        @include break-point(desktop) {
          font-size: 15px;
        }
        &.active {
          background-color: transparent;
          color: $blue-color;
          border-bottom: 3px solid;
        }

        @include break-point(landscapeTab) {
          font-size: 13px;
          padding-left: 0;
          padding-right: 0;
        }
      }

      &:hover {
        a {
          color: $blue-color;
        }
      }
    }
  }

  .slick-slider .slick-arrow,
  .slick-slider .slick-arrow.slick-disabled {
    background: transparent;
    box-shadow: none;
  }

  .slick-slider .slick-arrow.slick-prev:after,
  .slick-slider .slick-arrow.slick-next:after {
    background-image: url("../assets/icons/redGreater.svg") !important;
  }
}

////////////////////// Courrent Affairs ///////////////////////////

.currrent_affairs_wrap {
  .currrent_affairs_card {
    .content {
      border: 1px solid #ece6ef;
      border-radius: 16px;
      display: flex;
      overflow: hidden;
      width: 100%;
      max-width: 650px;
      margin: 0 auto;
      position: relative;
      @include break-point(landscapeTab) {
        flex-direction: column;
      }
      .image_wrap {
        width: 100%;
        max-width: 190px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        @include break-point(landscapeTab) {
          max-width: 100%;
          // padding-bottom: 15px;
          height: 200px;
          overflow: hidden;
        }
      }

      .content_details {
        padding: 20px 20px;
        width: calc(100% - 190px);
        @include break-point(landscapeTab) {
          width: 100%;
        }

        h6 {
          color: $text-color;
          font-weight: 600;
          font-size: 19px;
          line-height: 1.3;
          margin-bottom: 0;
        }

        p {
          margin: 0;

          &.list {
            font-weight: 500;
            color: #8b8b8b;
            padding: 10px 0;

            span {
              margin-right: 10px;
              color: $black-color;
            }
          }

          a {
            font-weight: 600;
            padding-left: 5px;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// VIdeo player icon and animation
///////////////////////////////////////////////////

.playicon {
  @include transformCenter;
  color: #fff;
  font-size: 30px;
  margin: 0;
  cursor: pointer;
  .videoplay_icon {
    text-decoration: none;
    border: 2px dotted white;
    color: aliceblue;
    border-radius: 50%;
    padding: 0;
    position: relative;
    width: 60px;
    height: 60px;

    &::after {
      position: absolute;
      content: "";
      bottom: -7px;
      top: -7px;
      left: -7px;
      right: -7px;
      background-color: transparent;
      border-radius: 50%;
      border: 4px solid #fff;
      margin: 0px;
      opacity: 1;
      transform-origin: center;
      animation: anim 1000ms linear infinite;
    }

    &::before {
      position: absolute;
      content: "";
      bottom: -7px;
      top: -7px;
      left: -7px;
      right: -7px;
      background-color: transparent;
      border-radius: 50%;
      border: 4px solid #fff;
      margin: 0px;
      opacity: 0.8;
      transform-origin: center;
      animation: anim 1000ms linear 600ms infinite;
    }

    svg {
      @include transformCenter;
    }
  }
}

.video_popup {
  .styles_playButton__1Q3EF {
    background-color: transparent;

    &:after {
      display: none;
    }
  }

  a {
    display: block;
    background: #f2f6f9;
    border-radius: 25px;
  }

  .styles_poster__1JohY {
    object-fit: cover;
    border-radius: 25px;
  }
}

//////////////////////////////////////// Book Mark ///////////////////////////////////////////////////

.bookmark {
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 20px;
}

//////////////////////////////////////// Slick Slider ///////////////////////////////////////////////////

.slick-slider {
  .slick-slide {
    padding: 0 10px;

    @include break-point(mobile) {
      padding: 0 5px;
    }
    > div {
      text-align: center;
      div {
        text-align: left;
      }
    }
  }

  .slick-arrow {
    border: 0;
    background: #4971b2;
    box-shadow: 4px 4px 12px rgb(0 0 0 / 5%);
    border-radius: 32px;
    height: 33px;
    width: 33px;
    z-index: 1000;

    &::before {
      display: none;
    }

    &.slick-disabled {
      background: #ffffff;
      box-shadow: 4px 4px 12px rgb(0 0 0 / 5%);
    }
  }

  .slick-arrow.slick-prev {
    transform: translateY(-50%) rotate(180deg);
    left: -10px;
    z-index: 9;

    &:after {
      content: "";
      background-image: url("../assets/icons/arrowActive.png");
      width: 8px;
      height: 13px;
      @include transformCenter;
    }
  }

  .slick-arrow.slick-prev.slick-disabled {
    &::after {
      background-image: url("../assets/icons/arrowGrey.png");
    }
  }

  .slick-arrow.slick-next {
    z-index: 9;
    right: -10px;
    &:after {
      content: "";
      background-image: url("../assets/icons/arrowActive.png");
      width: 8px;
      height: 13px;
      @include transformCenter;
    }
  }

  .slick-arrow.slick-next.slick-disabled {
    &::after {
      background-image: url("../assets/icons/arrowGrey.png");
    }
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li button {
    background-color: #dfd6e5;
    opacity: 0.5;
    text-indent: 999px;
    height: 8px;
    width: 15px;
    border: 0;
    border-radius: 20px;
    transition: 0.5s ease;
    font-size: 0;
    @include break-point(mobile) {
      width: 10px;
    }
  }

  .slick-dots li.slick-active button {
    background-color: #4971b2;
    opacity: 1;
    width: 35px;
    border-radius: 50px;

    @include break-point(mobile) {
      width: 30px;
    }
  }

  .slick-dots li {
    width: auto;
    height: auto;
    margin-right: 6px;
    @include break-point(mobile) {
      margin: 0 2px;
    }
  }

  .slick-dots {
    padding-top: 30px;
    display: block;
    position: static;

    @include break-point(mobile) {
      padding-top: 0;
    }
  }
}

/////////////////// Breadcrumb ////////////////////

.breadcrumb {
  display: block;
  ul {
    display: flex;
    align-items: center;

    li {
      padding-right: 20px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        background-image: url("../assets/icons/arrowGrey.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 7px;
        width: 15px;
        height: 15px;
        top: 50%;
        transform: translateY(-50%);
        right: 2px;
      }
      a {
        color: $blue-color;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 11px;
      }
      // span work as link
      .link {
        color: $blue-color;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 11px;
        cursor: pointer;
      }

      &:last-child {
        padding-right: 0;
        &:after {
          display: none;
        }
        a {
          color: $blue-color;
          font-weight: 500;
        }
      }
    }
  }
}

/////////////////// Pagination /////////////////

.pagination_wrap {
  padding: 20px 0;
  padding-bottom: 0;
  width: 100%;
  ul.pagination {
    flex-wrap: wrap;
    text-align: right;
    justify-content: center;
    li {
      display: inline-block;
      margin: 0 2px;
      text-align: center;
      a {
        border: 1px solid $blue-color;
        border-radius: 5px;
        color: $blue-color;
        display: block;
        font-size: 15px;
        padding: 5px 8px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        width: 35px;

        @include break-point(mobile) {
          font-size: 12px;
          padding: 3px 5px;
          width: 23px;
        }
        &.active {
          background-color: $blue-color;
          border-color: $blue-color;
          color: $white-color;
          font-weight: 500;
        }
      }
    }
  }
}

/////////////// Multi Tabs /////////////

.tab_content.multitab {
  .courseTitle {
    .nav-pills {
      display: flex;
      justify-content: center;
      .nav-item {
        a {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    .sub_courseTitle {
      .nav-pills {
        .nav-item {
          a {
            border-radius: 6px;
          }
        }
      }
    }
  }
  .navtab_content {
    padding-top: 35px;
  }
}

/////// Calendar ///////

.calendarWrapper {
  .modeWrapper {
    font-size: 25px;
    @include break-point(tablet) {
      font-size: 20px;
    }
  }
  .calendarHeader {
    h1 {
      font-size: 28px;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: $red-color;
      color: #fff;
      @include break-point(tablet) {
        font-size: 15px;
      }
    }
    .calendarHeaderButtons {
      font-size: 25px;
      color: $blue-color;
      @include break-point(tablet) {
        font-size: 20px;
      }
    }
  }
  .calendarDayOfWeek {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid #aaaaaa38;
    background: #aaaaaa4d;
    @include break-point(tablet) {
      font-size: 15px;
    }
    div {
      text-align: center;
    }
  }
  .weekRow {
    .dayCell {
      .dayDate {
        text-align: center;
      }
    }
  }
}

// Button Hover

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #dd5d59;
  }
}

@keyframes anim {
  from {
    margin: 0px;
    opacity: 1;
  }

  to {
    margin: -15px;
    opacity: 0;
  }
}

// ____________________________ Course Not found _______________________ //

.listing_card.not_found {
  width: 100%;
  text-align: center;
  .image_wrap {
    width: 100%;
    img {
      width: 100%;
      max-width: 500px;
    }
  }
}

// ________________________ File Upload _________________________//

.test_upload.ewqTBN {
  width: 100%;
  height: auto;
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  min-width: unset;
  max-width: 90%;
  margin: 35px auto;
  input {
    width: 100%;
  }
  svg {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }
  .kyUZVo {
    align-items: center;
    span {
      font-family: inherit;
      font-size: 16px;
    }
  }
}

.test_upload.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.dropdown_select {
  border: 1px solid $red-color;
  color: $red-color;
  outline: none;
  border-radius: 7px;
  padding: 5px 10px;
  min-width: 140px;
}

// Text Editor //

.text_editor {
  display: block;
  img {
    width: 305px !important;
    height: 165px !important;
    object-fit: cover;
    margin-bottom: 10px;
  }
  ul {
    list-style: initial;
  }
  a {
    text-decoration: initial;
  }
}

.text-right {
  text-align: right;
}

.modal-dialog-centered {
  @include break-point(mobile) {
    margin-left: 0;
  }
}

.search_bar {
  width: 100%;
  max-width: 300px;
  position: relative;

  @include break-point(mobile) {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .input {
    width: 100%;
    min-height: 38px;
    padding: 3px 33px 3px 10px;
    background: rgba(73, 113, 178, 0.1);
    border: 1px solid rgba(223, 214, 229, 0.6);
    border-radius: 8px;
    outline: none;
  }
  svg {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 28px;
    color: $white-color;
    padding: 2px;
    z-index: 7;
    cursor: pointer;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-color: $red-color;
    width: 40px;
    height: 100%;
    border-radius: 0 8px 8px 0;
  }
}

.link_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
}

img {
  @include transition;
}
.link_overlay:hover + .image_Wrap > .thumbnail_img {
  transform: scale(1.02);
}

.video_play_icon_img {
  max-width: 31px;
  height: auto !important;
  z-index: 99;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  position: absolute;
}

.react-datepicker__month-container {
  select {
    width: 100%;
    font-size: 13px;
    padding: 4px;
    border-radius: 4px;
  }
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    90deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    90deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
}

.placeholder {
  cursor: auto !important;
  opacity: 0.3 !important;
}

.not_found {
  width: 100%;
  .not_fount_image {
    width: 100%;
    max-width: 330px;
    text-align: center;
    margin: 0 auto;
    @include break-point(mobile) {
      max-width: 200px;
    }
    img {
      width: 100%;
    }
  }
  h5 {
    font-size: 18px;
  }
}

.red {
  color: $red-color;
}

// File Uploader //

body .file_uploader {
  width: 100%;
  height: auto;
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  min-width: unset;
  max-width: 100%;
  input {
    width: 100%;
  }
  svg {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }
  .kyUZVo {
    align-items: center;
    span {
      font-family: inherit;
      font-size: 16px;
    }
  }
}

.breadcrumb {
  margin: 0;
}

// ________________________ Push notification __________________//

.push_notification {
  position: relative;
  h6 {
    color: $blue-color;
    margin-bottom: 5px;
  }
  P {
    margin: 0;
    font-size: 13px;
    color: $title-color;
  }
  button {
    color: $blue-color;
    outline: none;
    border: 0;
    position: absolute;
    right: -8px;
    top: -8px;
    background: transparent;
    z-index: 9;
  }
}

.back_button {
  padding-left: 10px;
  margin-bottom: 10px;

  button,
  a {
    color: $red-color;
    border: 0;
    outline: none;
    background: transparent;
    font-size: 25px;
  }
}

.rpn-notification-card {
  background: #fff;
  box-shadow: none;
  border: 1px solid #aaaaaa6e;
  border-radius: 4px;

  .rpn-notification-card-top.light {
    background: transparent !important;
    color: blue;
    border-bottom: 1px solid;
    font-size: 14px;
    word-break: break-all;
  }

  .rpn-notification-card-bottom.light {
    background: #fff;
    word-break: break-all;
  }
  .rpn-notification-card-close {
    font-size: 12px;
    border: 1px solid;
    padding: 2px 5px;
    border-radius: 6px;
  }
}

/////////////////////////////////////////////// Not Found ///////////////////

.not_found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  text-align: center;
  font-size: 18px;

  img {
    width: 100%;
    max-width: 250px;
  }
  h6 {
    font-size: 20px;
    color: $black-color;
  }
  &.no_notification {
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 190px;
    }
  }
}

// hide toaster
// .toastify > div {
//   transform: none !important;
// }

.tooltip-toggle {
  cursor: pointer;
  position: relative;

  //Tooltip text container
  &::before {
    position: absolute;
    top: -100%;
    left: -100%;
    background-color: #000;
    color: #fff;
    content: attr(
      aria-label
    ); //This pulls in the text from the element with the tooltip
    padding: 10px;
    transition: all 0.5s ease;
    width: 300px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    border-radius: 0.375rem;
  }

  //Tooltip arrow
  &::after {
    position: absolute;
    top: -10%;
    left: 50%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    content: " ";
    font-size: 12px;
    line-height: 0;
    margin-left: -5px;
    width: 0;
  }

  //Setting up the transition
  &::before,
  &::after {
    color: #efefef;
    font-family: monospace;
    font-size: 12px;
    opacity: 0;
    pointer-events: none;
    text-align: center;
  }

  //Triggering the transition
  &:focus::before,
  &:focus::after,
  &:hover::before,
  &:hover::after {
    opacity: 1;
    transition: all 0.75s ease;
  }
}




