@import "../../scss/index.scss";

.development_slider {
	.development_slider_card {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include break-point(mobile) {
			flex-direction: column-reverse;
			background: #ffffff;
			border: 1px solid #dfd6e5;
			border-radius: 16px;
			margin-bottom: 10px;
		
		}
		.content {
			width: 100%;
			max-width: 50%;
			padding: 0 20px 0 20px;
			padding-left: 0;
			position: relative;
			@include break-point(landscapeTab) {
				padding: 0 20px 20px;
			}
			@include break-point(mobile) {
				max-width: 100%;
				padding: 0 10px 10px;
				height: 438px;
				text-align: center;
			}

			h6 {
				color: $blue-color;
				margin-bottom: 30px;
				font-size: 22px;
				@include break-point(desktop){
					margin-bottom: 5px;
				}
				@include break-point(mobile) {
					margin-bottom: 10px;
					font-size: 18px;
				}
			}
			h3{
				word-wrap: break-word;
			}
			p {
				font-size: 20px;
				margin-bottom: 20px;
				word-wrap: break-word;
				@include break-point(desktop){
					font-size: 15px;
				}
				@include break-point(tablet) {
					margin-bottom: 40px;
				}
				@include break-point(mobile) {
					margin-bottom: 15px;
					// font-size: 17px;
				}
			}

			.button_wrap {
				text-align: left;
				padding-bottom: 0;
				@include break-point(mobile) {
					text-align: center;
					position: absolute;
					bottom: 5px;
					left: 0;
					right: 0;
				}
			}
		}

		.image_wrap {
			width: 100%;
			max-width: 50%;
			position: relative;
			padding: 0;
			@include break-point(mobile) {
				max-width: 100%;
				padding: 0;
				margin-bottom: 20px;
			}
			img {
				width: 100%;
				border-radius: 16px;
				height: 300px;
				object-fit: cover;

				@include break-point(mobile){
					max-width: 100%;
					margin: 0 auto;
					height: 250px;
				}
			}
		}
	}

	.slick-slider .slick-dots {
		position: static;
		padding: 0;
		@include break-point(tablet) {
			position: static;
			padding-top: 0;
		}
		@include break-point(mobile) {
			left: 0;
			bottom: -20px;
			width: 100%;
			text-align: center;
		}
	}
	.slick-slider{
		.slick-slide{
			padding: 0;
		}
	}
}
