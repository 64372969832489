@import "../../scss/index.scss";

.terms_condition {
	h1 {
		font-size: 40px;
		text-align: center;
        @include break-point(mobile){
            font-size: 32px;
        }
	}
	.terms_condition_content {
		p {
			line-height: 1.6;
		}
		h5 {
			color: $blue-color;
			font-weight: bold;
			margin: 35px 0;
			padding: 0;
            @include break-point(mobile){
                margin: 22px 0;
            }
		}

		ol {
			padding-left: 15px;

			ol {
				margin: 20px 0;
				padding-left: 25px;
				li {
					margin-bottom: 8px;
				}
			}
			&.alphaBate {
				list-style: lower-alpha;
			}

			li {
				padding-left: 15px;
				margin-bottom: 12px;
			}
		}
	}
}


.faqPage{
    .page_search{
        padding: 25px;
        display: flex;
        justify-content: center;

        .search_box{
            position: relative;
            width: 100%;
            max-width: 800px;
            padding: 5px 35px 5px 15px;
            border: 1px solid #aaa;
            border-radius: 8px;
            display: flex;
            align-items: center;
            input{
                width: 100%;
                outline: none;
                height: 45px;
                border: none;
            }
            svg{
                position: absolute;
                right: 10px;
                top: 50%;
                font-size: 25px;
                transform: translateY(-50%);
            }
        }
    }
}

.faqs_section {
	.faqs {
		padding: 60px 75px;
		background: #ffffff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
		border-radius: 16px;
		@include break-point(desktop) {
			padding: 30px 30px;
		}
		@include break-point(tablet) {
			padding: 30px 30px;
		}
		@include break-point(mobile) {
			padding: 20px 15px;
		}
		.page_title {
			h3 {
				text-align: left;
				@include break-point(desktop){
					padding: 0;
				}
			}
		}
		.accordion {
			.accordion-item {
				border: 0;
				border-bottom: 1px solid #4971b26e;
				padding: 10px 0;
				border-radius: 0;
				@include break-point(miniTab) {
					padding: 5px 0;
				}
				.accordion-header {
					padding-bottom: 0;
					.accordion-button {
						background-color: transparent;
						box-shadow: none;
						font-size: 20px;
						font-weight: 500;
						color: $blue-color;
						@include break-point(desktop){
							padding: 5px 10px;
							font-size: 17px;
						}
						// @include break-point(miniTab) {
						// 	padding-top: 10px;
						// 	padding-bottom: 10px;
						// }
						@include break-point(mobile) {
							padding: 7px 0;
							font-size: 16px;
						}
						&:after {
							background-image: none;
							transform: none;
							content: "-";
							font-size: 30px;
							font-weight: 500;
							width: 30px;
							height: 30px;
							display: flex;
							align-items: center;
							justify-content: center;

							@include break-point(miniTab) {
								font-size: 30px;
							}
						}
						&.collapsed {
							color: $text-color;

							&:after {
								color: $blue-color;
								content: "+";
								background-image: none;
								transform: none;
							}
						}
					}
				}
				.accordion-body {
					padding-top: 0;

					@include break-point(mobile) {
						padding: 0;
					}
				}
			}
		}
	}
}