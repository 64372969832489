@import "../../scss/index.scss";

.landingpage {
  .landingpage_banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 80px 0;

    @include break-point(miniTab) {
      padding: 35px 0;
      background-size: cover;
    }
    .container {
      display: flex;
      justify-content: space-between;
      @include break-point(mobile) {
        flex-direction: column-reverse;
      }
      .content {
        width: 100%;
        max-width: 50%;
        padding-right: 30px;

        @include break-point(mobile) {
          max-width: 100%;
          padding: 0;
          margin-bottom: 20px;
          text-align: center;
        }
        h1 {
          font-size: 45px;
          @include break-point(mobile) {
            font-size: 30px;
          }
        }
        p {
          font-size: 16px;
        }

        .banner_button {
          padding: 20px 0;

          .button {
            width: 100%;
            @include break-point(mobile) {
              width: 100%;
            }
          }

          .dropdown {
            margin: 20px 0;
            @include break-point(mobile) {
              margin: 10px;
            }
            .dropdown-menu {
              a {
                &:hover {
                  background-color: transparent;
                }
                label {
                  width: 100%;
                  cursor: pointer;
                  input {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
      .slider_section {
        width: 100%;
        max-width: 630px;
        // border-radius: 36px;
        overflow: hidden;
        @include break-point(mobile) {
          max-width: 100%;
        }
        .item {
          width: 100%;
          img {
            border-radius: 36px;
            width: 100%;
          }
        }
      }
    }
  }

  .cetner_address_section {
    .center_address {
      width: 100%;
      display: flex;
      justify-content: center;

      @include break-point(mobile) {
        flex-wrap: wrap;
      }
      .address_card {
        width: 100%;
        max-width: 500px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #dfd6e5;
        border-radius: 16px;
        margin: 10px;
        @include break-point(mobile) {
          max-width: 100%;
        }
        .map {
          border-radius: 16px;
          width: 100%;
          height: 300px;
        }

        .address {
          padding: 20px;

          h3 {
            color: $blue-color;
            font-size: 25px;
            margin: 0;

            @include break-point(mobile) {
              font-size: 20px;
            }
          }

          h5 {
            @include break-point(mobile) {
              font-size: 17px;
            }
          }
          .rating {
            padding-bottom: 7px;

            .stars {
              color: #fdcf27;
              padding-right: 6px;
            }
          }

          ul {
            li {
              display: flex;
              align-items: flex-start;
              margin-bottom: 10px;
              img {
                margin-right: 12px;
                position: relative;
                top: 2px;
              }
            }
          }
        }
      }
    }
  }

  .upcomming_classrooms {
    .coursecard_wrap {
      @include break-point(tablet) {
        flex-wrap: wrap;
      }
    }
  }
}
