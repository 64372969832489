@import "../../scss/index.scss";

.ourStory {
	.common_banner {
		background-image: url("../../assets/images/ourstory.png");
		background-position: center;
		min-height: 300px;
	}

.topperdiv1{
  width: 100%;background-color: #ecf2fa;border-radius: 10px;padding: 10px;  
}
.innerdiv{
  float: left;
  width: 50%;
}

.height150{
  min-height: 150px;
}
.height100{
  min-height: 100px;
}
.height200{
  min-height: 200px;
}
.height175{
  min-height: 185px;
}

.height125{
  min-height: 125px;
}

.topperdiv2{
  width: 100%;background-color: #fff1f1;border-radius: 10px;padding: 10px;
}

.sepdiv{
  width: 100%; clear: both;
}
.pdftesticoncls{
  height: 32px;
  width: 32px;
  padding: 5px;
}
	.number_data {
		background-color: $red-color;
		h3 {
			color: $white-color;
			text-align: center;
		}
		p {
			text-align: center;
			color: $white-color;
			color: $white-color;
		}
		ul {
			padding-top: 20px;
		}
	}

  @include break-point(mobile){
    margin-bottom: 10px;
    .pdftesticoncls{
      height: 24px;
      width: 24px;
      padding: 5px;
    }
  }

	
	.our_beliefs {
		.our_beliefs_wrap {
			width: 100%;
			justify-content: center;
			display: flex;

			@include break-point(mobile){
				flex-direction: column;
			}
			.our_beliefs_card {
				width: 100%;
				padding: 30px;
				background-color: #fef3e9;
				border-radius: 16px;
				padding-right: 0;
				position: relative;
				&:not(:last-child) {
					margin-right: 20px;
				}

        .card_content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 178px);
          padding: 20px 0px;
  
          @include break-point(tablet) {
            padding: 15px 15px;
          }
  
          ul {
            padding-bottom: 20px;
  
            li {
              padding-left: 5px;
              margin-bottom: 15px;
              position: relative;
  
              &::after {
                position: absolute;
                // background-image: url("../../assets/icons/list-active.svg");
                content: "";
                top: 5px;
                left: 0;
                height: 15px;
                width: 15px;
              }
            }
          }
        }

				@include break-point(tablet) {
					padding: 20px;
				}

				@include break-point(miniTab){
					padding: 15px;
				}
				@include break-point(mobile){
					margin-bottom: 10px;
				}

				&.online {
					background-color: #fef3e9;
				}
				&.classroom {
					background-color: #ecf2fa;
				}
				&.regional {
					background-color: #fff1f1;
				}

				h4{
					@include break-point(miniTab){
						font-size: 23px;
					}
				}

				.content {
					p {
						margin: 0;
						width: calc(100% - 180px);
						@include break-point(tablet){
							width: 100%;
							padding-bottom: 80px;
						}
						img {
							position: absolute;
							right: 0;
							bottom: 0;
							width: 190px;
							@include  break-point(tablet){
								width: 115px;
							}
						}
					}
				}
			}
		}
	}

	
}
