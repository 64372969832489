@import "../../scss/index.scss";

////////////////////////////////////// institute cards slider ///////////////////////////////

.institute_toppers {
	// .toppers_card_wrap {
	// }

	.toppers_card {
		background-color: #fef3e9;
		border-radius: 16px;
		display: flex;
		align-items: center;
		padding-left: 25px;
		width: 100%;
		position: relative;
		max-width: 450px;
		overflow: hidden;
		height: 150px; /* Fixed height */
	  
		.content {
		  width: 100%;
		  max-width: 65%;
		  padding: 15px 0px; /* Adjust padding */
		  display: flex;
		  flex-direction: column;
		  justify-content: space-between; /* Evenly distribute content */
	  
		  h4 {
			color: #b29071;
			margin-bottom: 0px;
			padding-bottom: 0px;
		  }
	  
		  b.name {
			display: block;
			padding-bottom: 5px;
			font-size: 18px;
		  }
	  
		  span.exam {
			color: $text-color;
			opacity: 0.7;
			display: block;
			font-size: 14px;
			line-height: 1.2;
		  }
	  
		  span.course_name {
			color: $text-color;
			opacity: 0.7;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* Limit to 2 lines */
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis; /* Show ellipsis for overflowed text */
			font-size: 12px; /* Adjust font size */
			line-height: 1.4; /* Adjust line height */
		  }
		}
	  
		.image {
		  width: 100%;
		  max-width: 35%;
		  display: inline-block;
	  
		  img {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 135px;
			height: 100%;
			object-fit: cover;
			border-bottom-right-radius: 16px;
		  }
		}
	  }
	  
	  

	.slick-slide {
		&:nth-child(1n + 1) {
			.toppers_card {
				background-color: #fef3e9;

				h4 {
					color: #b29071;
				}

			}
		}

		&:nth-child(2n + 2) {
			.toppers_card {
				background-color: #ecf2fa;

				h4 {
					color: #4971b2;
				}

			}
		}

		&:nth-child(3n + 3) {
			.toppers_card {
				background-color: #fff1f1;

				h4 {
					color: #dd5d59;
				}

			}
		}

	}

	.bottom_detail {
		width: 100%;
		max-width: 1100px;
		margin: 0 auto;
		text-align: center;
		padding: 20px 0;
		line-height: 1.5;

		@include break-point(mobile) {
			line-height: 1.3;
		}
	}
}