@import "../../scss/index.scss";

.common_modal {
  .modal-lg {
    .modal-dialog {
      max-width: 700px;
    }
  }
  .modal-md {
    .modal-dialog {
      max-width: 600px;
    }
  }
  .modal-content {
    padding: 15px;
    position: relative;
    min-height: 290px;
    .modal_close_button {
      position: absolute;
      top: 0px;
      right: 8px;
      font-size: 20px;
      cursor: pointer;
    }
    .modal_title {
      text-align: center;
    }

    .button_wrap {
      padding: 30px 0;
    }

    .logout {
      text-align: center;
      .logout_icon {
        font-size: 70px;
        color: $red-color;
      }
      h3 {
        margin-bottom: 0;
      }

      .button_wrap {
        .dashboard_button {
          min-width: 140px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .course_listing {
    ul {
      max-height: 350px;
      overflow: auto;
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        padding: 5px 10px;
        border: 1px solid #aaaaaa3d;
        border-radius: 5px;
        align-items: center;
        .sr_number {
          width: 30px;
          text-align: left;
          font-weight: bold;
        }
        p {
          width: 100%;
          text-align: left;
          margin: 0;
        }
        .button {
          font-size: 20px;
          font-weight: bold;
          border: 1px solid $blue-color;
          display: inline-flex;
          padding: 0px 6px;
          border-radius: 3px;
          background-color: $blue-color;
          color: $white-color;
          margin-left: 10px;
          min-width: unset;
          padding: 2px 9px;
          text-align: center;
          img {
            width: 25px;
          }
        }
      }
    }
  }

  &.welcome_wrapper {
    .modal-dialog {
      max-width: 900px;
    }
    .modal-content {
      padding: 0;
      border-radius: 10px;
      overflow: hidden;
      .image_wrap {
        height: 470px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .modal_close_button {
        border: 1px solid;
        top: 3px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &.reupload_loader {
    .testPage_upload_paper {
      position: relative;

      .percentage_loader {
        left: 0;
        right: 0;
        height: 162px;
        width: 100%;
        z-index: 9;
        background-color: #fff;
        &:after {
          background-color: transparent;
        }
      }
    }
  }
}
