@import "../../scss/index.scss";

.not_found {
  img {
    width: 100%;
    height: auto;
    margin: 0;
    max-width: 550px;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
}
