.percentage_loader {
	width: 100%;
	text-align: center;
	position: absolute;
	top: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: #f1f1f1f1;
	}

	.CircularProgressbar {
		max-width: 100px;
		margin: 0 auto;
		z-index: 99;
		position: absolute;
	}
}
