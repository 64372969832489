@import "../../../scss/index.scss";

.dailyQuiz {
	.common_banner {
		background-image: url("../../../assets/images/dailyquizbg.png");
	}

	.dailyQuiz_section {
		.quiz_wrapper {
			width: 100%;
			max-width: 1100px;
			margin: 0 auto;
			display: flex;
			@include break-point(mobile) {
				flex-wrap: wrap;
			}
			.date_section {
				width: 100%;
				max-width: 300px;
				padding: 30px;
				@include break-point(tablet) {
					padding: 20px;
					max-width: 250px;
				}

				@include break-point(mobile) {
					max-width: 100%;
					padding: 10px;
				}
				h5 {
					text-transform: uppercase;
				}
			}
			.question_section {
				padding: 30px;
				border-left: 2px solid rgba(223, 214, 229, 0.6);
				width: 100%;
				@include break-point(tablet) {
					padding: 20px;
				}
				@include break-point(mobile) {
					border: none;
					padding: 10px;
				}
				.content {
					border: 1px solid rgba(223, 214, 229, 0.6);
					border-radius: 16px;

					.question_header {
						background-color: #edf1f7;
						display: flex;
						align-items: center;
						padding: 20px;
						border-radius: 16px 16px 0 0;
						@include break-point(tablet) {
							padding: 10px;
						}
						.icon {
							font-size: 44px;
							width: 100%;
							max-width: 76px;
							text-align: center;
							margin-right: 20px;
							@include break-point(mobile) {
								max-width: 55px;
								margin-right: 14px;
							}
						}
						.text {
							h5 {
								margin-bottom: 0;
								color: $blue-color;
							}
							p {
								margin: 0;
							}
						}
					}
					.questions {
						padding: 20px;
						@include break-point(mobile) {
							padding: 25px 10px;
						}
						b {
							color: $red-color;
						}
						p {
							margin: 0;
							padding: 10px 0;
						}
						ul {
							flex-direction: column;
							display: inline-flex;
							li {
								padding: 10px;
								display: inline-block;
								border-radius: 12px;
								display: flex;
								align-items: center;
								input {
									margin-right: 10px;
									width: 16px;
									height: 16px;
								}
							}
						}
						.button_wrap {
							text-align: right;
							@include break-point(mobile) {
								padding-top: 30px;
							}
						}
					}

					// Quiz summery //

					.quiz_result {
						text-align: center;
						width: 100%;
						max-width: 500px;
						margin: 0 auto;
						h5 {
							margin: 0;
							font-weight: bold;
							color: $blue-color;
						}
						ul {
							li {
								padding: 0 0 15px 0;
								span {
									width: 18px;
									height: 18px;
									margin-right: 10px;
									&.box_green {
										background-color: #00d361;
									}
									&.box_red {
										background-color: $red-color;
									}
								}
							}
						}
						.button_wrap {
							.button {
								width: 100%;
								background-color: $red-color;
								color: $white-color;
								margin-top: 30px;
							}
						}
					}

					.Quiz_summery_wrap {
						.quiz_result {
							.button_wrap {
								display: none;
							}
						}

						.Quiz_summery {
							padding: 20px;
							border-radius: 20px;
							background-color: #fcefee;
							@include break-point(mobile) {
								padding: 15px;
							}
							.question {
								margin-bottom: 20px;
								padding-bottom: 15px;
								border-bottom: 1px solid #aaaa;
							}
							.option {
								margin: 25px 0;
								display: flex;
								flex-wrap: wrap;
								li {
									width: 48%;
									margin: 10px 5px;
									padding: 15px;
									border-radius: 16px;
									background-color: $white-color;
									border: 1px solid $white-color;
									text-align: center;
									@include break-point(miniTab) {
										width: 100%;
									}
									@include break-point(mobile) {
										padding: 10px;
										border-radius: 10px;
									}
									&.correct_ans {
										color: $green-color;
										border-color: $green-color;
									}
									&.selected_ans {
										color: $red-color;
										border-color: $red-color;
									}
								}
							}
						}
					}
				}
			}
		}
		.quizNotFound {
			padding: 20px;
			text-align: center;
			.image_wrap {
				img {
					width: 100%;
					max-width: 400px;
					margin: 0 auto;
				}
			}
		}
	}
}
