@import "../../scss/index.scss";

.course_subscription {
  .subscription_content {
    display: flex;
    justify-content: space-between;
    @include break-point(tablet) {
      //flex-direction: column-reverse;
      flex-direction: column;
      //  / Mobile view ke liye /
    }
    .personal_details {
      order: -1;
      width: 100%;
      max-width: 60%;
      @include break-point(tablet) {
        max-width: 100%;
      }
    }

    .form_group {
      padding: 20px;
      border-radius: 16px;
      @include boxShadow;
      margin-bottom: 15px;

      ._title {
        display: block;
        margin: 10px 0 20px 0;
      }
    }

    .course_detail {
      width: 100%;
      max-width: 39%;
      position: sticky;
      top: 87px;
      height: calc(100vh - 42px);
      overflow-y: auto;
      @include break-point(tablet) {
        max-width: 100%;
        position: static;
      }

      .course_bill_summary {
        width: 100%;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
          @include break-point(desktop) {
            padding: 0 0 8px 0;
          }
          b {
            text-align: right;
          }
        }
      }
      .couponcode {
        padding: 15px 0;
        .applyCoupon {
          align-items: center;
          justify-content: space-between;
          display: flex;
          b {
            margin-right: 15px;
            width: 85%;

            @include break-point(tablet) {
              max-width: 100px;
            }
          }
          .input_wrap {
            display: flex;
            width: 100%;
            input {
              border: 1px solid #a2a2a2;
              min-height: 40px;
              border-radius: 10px 0 0 10px;
              outline: none;
              padding: 5px 10px;
              width: 100%;
            }
            button {
              border: 1px solid #dd5d59;
              background-color: #fceeee;
              color: $red-color;
              padding: 0 20px;
              border-radius: 0 10px 10px 0;
              border-left: 0;

              @include break-point(mobile) {
                padding: 0 10px;
              }
            }
          }
        }
        .couponCodeListing {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          p {
            text-align: right;
            a,
            span {
              font-weight: 500;
              color: $blue-color;
              cursor: pointer;
              &:hover {
                color: $red-color;
              }
            }
          }
          ul {
            width: 100%;
            max-width: 254px;
            font-size: 14px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              font-size: 12px;

              span.coupon {
                color: $blue-color;
                border: 1px dashed $blue-color;
                padding: 3px 7px;
                min-width: 100px;
                text-align: center;
              }
              .button_wrap {
                padding: 0;
              }
              button {
                background-color: $light-red-color;
                color: $red-color;
                border: 1px solid $red-color;
                outline: none;
                outline: none;
                padding: 2px 8px;
                border-radius: 5px;
                width: 60px;
                position: relative;
              }

              &.applied {
                button {
                  border-color: #2ed573;
                  background-color: #4cd137;
                  color: $white-color;
                  border-style: dashed;
                }
                span {
                  color: #b2bec3;
                  border-color: #b2bec3;
                }
                span.delete_coupon {
                  font-size: 15px;
                  margin-left: 2px;
                  color: $red-color;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .total_amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        padding: 15px 20px;
        border-radius: 12px;
        background-color: #f1f1f1;
        margin: 15px 0;
        @include break-point(desktop) {
          margin: 0;
          font-size: 18px;
        }
        @include break-point(mobile) {
          font-size: 16px;
          margin: 10px 0;
          padding: 10px 15px;
        }
      }

      .check_box_wrap {
        display: flex;
        align-items: center;
        padding: 20px 0;
        flex-wrap: wrap;
        @include break-point(mobile) {
          font-size: 14px;
        }
        input {
          width: 17px;
          height: 17px;
          margin-right: 10px;
        }
        a,
        .link {
          padding-left: 5px;
          font-weight: 500;
          color: #4971b2;
          transition: all 0.3s linear;
          cursor: pointer;
          &:hover {
            color: $red-color;
          }
        }
        // css for addon courses
        .addon {
          display: flex;
          flex-direction: column;
          padding-top: 14px;
          .addon-title {
            font-weight: 700;
          }
          .addon-price {
            color: $blue-color;
            font-weight: 400;
          }
        }
      }
      .button_wrap {
        text-align: center;
        padding: 20px 0;
      }

      .already_purchased {
        text-align: center;
        color: $blue-color;
        img {
          width: 100%;
          max-width: 300px;
        }
        h4 {
          color: $blue-color;
          font-size: 25px;
          @include break-point(mobile) {
            font-size: 18px;
          }
        }
      }

      .tab_devices_list {
        padding-top: 20px;

        .tab_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 5px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid #f1f1f1;

          &.inactive {
            opacity: 0.4;
          }
          b {
            font-weight: 400;
          }
          svg {
            margin-right: 10px;
            font-size: 18px;
          }
          .IMEINumber {
            color: $blue-color;
            margin: 0 10px;
          }
          .status {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #4cd137;

            &.inactive {
              background-color: $red-color;
            }
          }
        }
      }
    }
  }


  .edit_profile {
    .edit_profile_content {
      width: 100%;

      .profile_content {
        padding-left: 0;
        width: 100%;

        /* Add a class for handling non-editable state */
        &.disabled-upload {
          .upload_document {
            .id_document {
              /* Disable pointer events and user selection for non-editable state */
              .disabled-interactions {
                pointer-events: none;
                user-select: none;
              }
            }
          }
        }

        .upload_document {
          width: 100%;

          .id_document {
            margin-right: 10px;
            padding: 20px 20px;

            @include break-point(minTab) {
              margin-top: 2px;
            }

            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.terms_condition_modal {
  .modal-title {
    padding-bottom: 0;
  }
  .modal-dialog {
    max-width: 80%;

    @include break-point(minTab) {
      max-width: 90%;
    }

    .modal-content {
      .modal-body {
        padding: 25px;
        @include break-point(mobile) {
          padding: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  // / Laptop view ke liye /
  .subscription_content {
    flex-direction: row;
    // / Flex direction change karna /
  }

  .personal_details {
    order: 0;
    // / Original order restore karna /
  }
}
