@import "../../../scss/index.scss";

.LectureVideo {
  .container {
    display: flex;
    justify-content: space-between;
    @include break-point(miniTab) {
      flex-direction: column-reverse;
    }
  }
  h5 {
    margin: 0;
    @include break-point(desktop) {
      font-size: 16px;
    }
  }
  .courseList {
    width: 100%;
    max-width: 30%;
    padding: 20px;
    @include boxShadow;
    margin-right: 20px;
    overflow: auto;
    height: 548px;
    @include break-point(tablet) {
      padding: 10px;
    }
    @include break-point(miniTab) {
      max-width: 100%;
      margin: 20px 0 0 0;
    }
    ul {
      display: block;

      li {
        display: block;
        position: relative;
        margin-bottom: 15px;
        padding: 10px;
        @include boxShadow;
        h5 {
          padding-bottom: 5px;
          font-size: 18px;
          @include break-point(desktop) {
            font-size: 16px;
          }
        }
        p {
          margin: 0;
          span {
            &:first-child {
              padding-right: 20px;
            }
          }
        }

        &.lecture-card.disabled {
          opacity: 0.6;
          .play_btn,
          h6 {
            color: inherit;
          }
          .date.red {
            color: inherit;
          }
        }

        .upcomingTag {
          position: absolute;
          top: 1px;
          right: 1px;
          background-color: $green-color;
          color: $white-color;
          padding: 0 6px;
          border-radius: 2px;
          border-bottom-left-radius: 10px;
          margin: 0;
          padding-bottom: 2px;
          font-size: 11px;
        }
      }
    }
  }

  .course_video {
    width: 100%;
    max-width: 70%;
    padding: 20px;
    position: relative;
    @include boxShadow;
    @include break-point(tablet) {
      padding: 10px;
    }
    @include break-point(miniTab) {
      max-width: 100%;
    }
    .back_arrow {
      // display: inline-block;
      margin: 0 auto;
      text-align: center;
      a {
        display: inline-block;
      }
    }
    .video_wrap {
      .image_wrap {
        position: relative;
        img {
          width: 100%;
          max-width: 750px;
          height: 370px;
          border-radius: 16px;
          object-fit: cover;
        }
        .loader {
          position: absolute;
          color: #4971b2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        svg {
          position: absolute;
          color: #4971b2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 50px;
          cursor: pointer;
        }
      }
      video {
        width: 100%;
        // max-width: 767px;
        height: 400px;
        border-radius: 16px;
        position: relative;
      }
    }
    .about_video {
      padding: 20px 0;
      width: 100%;
      display: flex;
      align-items: center;
      @include break-point(landscapeTab) {
        flex-direction: column;
      }
      .content {
        width: calc(100% - 233px);
        @include break-point(landscapeTab) {
          width: 100%;
        }
      }
      .button_wrap {
        width: 232px;
        padding-left: 20px;
        @include break-point(landscapeTab) {
          width: 100%;
          padding: 20px 0 0 0;
        }
        .dashboard_button {
          svg {
            margin-right: 7px;
            font-size: 20px;
          }
        }
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    @include break-point(mobile) {
      flex-wrap: wrap;
    }
    li {
      padding-right: 15px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        color: #7f9bc9;
      }
      &.red {
        color: $red-color;
        svg {
          color: $red-color;
        }
      }
    }
  }
  &.liveLecture_video {
    .course_video {
      margin: 0 auto;
    }
  }
}
