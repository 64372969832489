@import "../../scss/index.scss";

.dashboard_home {
  padding-top: 15px;
  .mycourses {
    .form_field {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .input_selection {
      padding: 8px 15px;
      background-color: #ecf0f7;
      border-radius: 8px;
      border: 1px solid $blue-color;
      color: $blue-color;
      cursor: pointer;
    }
    .mycourses_inner {
      display: flex;
      justify-content: space-between;
      // padding: 18px;
      border-radius: 16px;
      // background-color: #ecf0f7;
      // padding-bottom: 0;
      @include break-point(landscapeTab) {
        flex-wrap: wrap;
      }
      @include break-point(tablet) {
        flex-direction: column;
        overflow-x: auto;
      }
      @include break-point(miniTab) {
        padding: 10px;
      }
      .upcoming_test {
        width: 100%;
        max-width: 50%;
        background-color: $white-color;
        padding: 20px;
        border-radius: 16px;
        margin-right: 20px;
        @include break-point(tablet) {
          margin: 0 0 10px 0;
          max-width: 100%;
          min-width: 600px;
        }
        h5 {
          font-weight: bold;
        }
        ul {
          li {
            display: flex;
            align-items: center;
            padding: 15px 0;
            border-bottom: 1px solid rgba(170, 170, 170, 0.2196078431);
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 15px;
            }
            .content {
              ul {
                display: flex;
                li {
                  padding: 0;
                  border: 0;
                  padding-right: 25px;
                  padding-top: 5px;
                  svg {
                    margin-right: 10px;
                    color: $blue-color;
                  }
                }
              }
            }
          }
        }
      }
      .continue_watching {
        width: 100%;
        max-width: 50%;
        background-color: $white-color;
        padding: 20px;
        border-radius: 16px;
        @include break-point(landscapeTab) {
          max-width: 100%;
          margin-top: 10px;
        }
        @include break-point(tablet) {
          max-width: 100%;
          min-width: 600px;
        }
        h5 {
          font-weight: bold;
        }
        .continue_watching_content {
          ul {
            li {
              display: flex;
              align-items: center;
              padding: 12px;
              border-bottom: 1px solid rgba(170, 170, 170, 0.2196078431);
              img {
                width: 115px;
                border-radius: 5px;
                height: 32px;
                margin-right: 15px;
                object-fit: cover;
              }
              h6 {
                width: 100%;
                position: relative;
                span {
                  position: absolute;
                  bottom: 100%;
                  width: 100%;
                  right: 0;
                  background-color: $blue-color;
                  background-color: $light-pink-color;
                  padding: 5px 10px;
                  border-radius: 5px;
                  font-size: 12px;
                  font-weight: 400;
                  color: $black-color;
                  transition: all 0.3s linear;
                  opacity: 0;
                  visibility: hidden;
                }
                &:hover,
                &:active {
                  span {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }
              .item {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                max-width: 100px;
                font-size: 13px;

                strong {
                  display: block;
                  width: 100%;
                }

                .progress {
                  width: 100%;
                  max-width: 150px;
                  background-color: #fceeee;
                  height: 6px;
                  margin-top: 4px;
                  .progress-bar {
                    background-color: $red-color;
                    .visually-hidden {
                      @include break-point(mobile) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.widthAuto {
          min-width: unset;
          width: 100%;
        }
      }
      .daily_lecture {
        background-color: #fff;
        padding: 20px;
        border-radius: 16px;
        margin-right: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: 100%;
        max-width: 52%;
        @include break-point(landscapeTab) {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }

        .lecture_card {
          box-shadow: none;
        }
      }
      .refresh_button_wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }
  h4 {
    font-size: 25px;
    @include break-point(desktop) {
      font-size: 20px;
    }
  }

  ._progress_wrapper {
    width: 100%;
    max-width: 45%;
    background-color: $white-color;
    padding: 20px;
    border-radius: 16px;
    margin-right: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow-y: auto;
    @include break-point(landscapeTab) {
      max-width: 100%;
      margin-right: 0;
    }

    .form_field {
      @include break-point(mobile) {
        flex-direction: column;

        .dashboard_button {
          width: 100%;
        }
      }
      .input_selection {
        padding: 5px 15px;
        font-size: 14px;
        @include break-point(mobile) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    h5 {
      font-weight: bold;
    }

    .time_tag {
      padding: 5px 13px;
      background-color: $light-pink-color;
      color: $blue-color;
      border-radius: 8px;
      font-weight: bold;
      min-width: 64px;
      font-size: 14px;
    }
    .nav-pills {
      .nav-item {
        .nav-link {
          color: $text-color;
          border-bottom: 1px solid transparent;
          background-color: transparent;
          padding: 5px 10px;
          border-radius: 0;
          &.active {
            color: $red-color;
            border-color: $red-color;
          }
        }
      }
    }

    //////////
    ///
    .content {
      padding-top: 15px;
      .D_flex {
        padding: 10px;
        border: 1px solid #ffe5e5;
        background-color: #fff2f2;
        margin-bottom: 13px;
        border-radius: 11px;
        h6 {
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
 
  }

  .yours_programmes {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 0 0 16px 16px;
    background-color: #ecf0f7;
    @include break-point(landscapeTab) {
      flex-wrap: wrap;
    }
    @include break-point(miniTab) {
      padding: 10px;
    }
    h5 {
      font-weight: bold;
    }
    // ._progress {

    // 	width: 100%;
    // 	max-width: 50%;
    // 	background-color: $white-color;
    // 	padding: 20px;
    // 	border-radius: 16px;
    // 	margin-right: 10px;
    // 	@include break-point(landscapeTab) {
    // 		max-width: 100%;
    // 		margin-right: 0;
    // 	}
    // 	.time_tag {
    // 		padding: 5px 13px;
    // 		background-color: $light-pink-color;
    // 		color: $blue-color;
    // 		border-radius: 8px;
    // 		font-weight: bold;
    // 	}
    // 	.nav-pills {
    // 		.nav-item {
    // 			.nav-link {
    // 				color: $text-color;
    // 				border-bottom: 1px solid transparent;
    // 				background-color: transparent;
    // 				padding: 5px 10px;
    // 				border-radius: 0;
    // 				&.active {
    // 					color: $red-color;
    // 					border-color: $red-color;
    // 				}
    // 			}
    // 		}
    // 	}
    // }
    .topper_copies {
      width: 100%;
      max-width: 25%;
      background-color: $white-color;
      padding: 20px;
      border-radius: 16px;
      margin-right: 10px;
      @include break-point(landscapeTab) {
        max-width: 48%;
        margin: 15px 0;
      }
      @include break-point(mobile) {
        max-width: 100%;
      }
      .topper_copies_content {
        ul {
          li {
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid rgba(170, 170, 170, 0.2196078431);
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
  .recommended_course {
    .recommended_card_wrap {
      margin: 5px 0;
    }
    .D_flex {
      h5 {
        font-weight: bold;
      }
    }
    .recommended_card_wrap {
      .recommended_card {
        width: 100%;
        max-width: 300px;
        border: 1px solid #b6e0da;
        padding: 20px;
        border-radius: 16px;
        .image_wrap {
          width: 100%;
          height: 100px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }
        h5 {
          font-size: 17px;
          line-height: 1.3;
          margin: 0;
        }

        b {
          font-weight: bold;
          font-size: 18px;
        }
        p {
          margin: 10px 0;
          span {
            color: $red-color;
            margin-left: 5px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .not_found {
    width: 100%;
    .not_fount_image {
      width: 100%;
      max-width: 230px;
      text-align: center;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    h5 {
      font-size: 15px;
    }
  }

  .group_meeting_listing {
    ul {
      height: 430px;
      overflow-y: auto;
      li {
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 10px;
        background-color: #f0f2f9;
        border-radius: 5px;
        padding: 10px;
        .top_content {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
          h6 {
            padding: 0 10px;
            color: $blue-color;
            margin: 0;
            font-size: 15px;
          }
          .dashboard_button {
            padding: 0px 15px;
            border-radius: 5px;
          }
        }
        .bottom_content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;
          strong {
            display: block;
          }
          span {
          }
        }
      }
    }
  }

  ._box {
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    margin-right: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 15px 0;
  }
  .course_wise_progress {
    .form_field {
      align-items: center;
      display: flex;
      @include break-point(mobile) {
        flex-direction: column;
      }
    }
    .input_selection {
      background-color: #ecf0f7;
      border: 1px solid #4971b2;
      border-radius: 5px;
      color: #4971b2;
      position: relative;
      padding: 10px 20px 10px 12px;
      cursor: pointer;
      min-width: 120px;
      @include break-point(mobile) {
        width: 100%;
        margin-bottom: 5px;
        margin-right: 0;
      }
      &:focus {
        outline: none;
        background-color: inherit;
      }
      svg {
        font-size: 14px;
        right: 2px;
        position: absolute;
        top: 12px;
      }
      &:first-child {
        margin-right: 15px;

        @include break-point(mobile) {
          margin-right: 0;
        }
      }
    }

    .button_wrap {
      text-align: center;
      font-size: 20px;
      padding-top: 20px;
      @include break-point(mobile) {
        font-size: 14px;
      }
      a {
        text-decoration: underline;
        color: $red-color;
      }
    }
    ._progress_card_wrap {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      // border: 1px solid #f1f1f1;
      // padding: 30px 20px;
      border-radius: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    ._progress {
      .content {
        .today_spent {
          justify-content: center;
          padding-bottom: 20px;
          font-size: 18px;
          @include break-point(mobile) {
            font-size: 16px;
          }
          span {
            margin-right: 10px;
          }
        }
        ._progress_card {
          display: flex;
          justify-content: space-between;

          .D_flex {
            width: 50%;
            justify-content: center;
            align-items: center;
            @include break-point(mobile) {
              width: 100%;
              justify-content: center;
              flex-direction: column;
            }
            .content_inner {
              @include break-point(mobile) {
                width: 100%;
                margin-top: 10px;
                text-align: center;
              }
            }
            img {
              margin-right: 20px;
              width: 58px;
              height: 58px;

              @include break-point(mobile) {
                margin: 0;
                width: 46px;
                height: 46px;
              }
            }
            h6 {
              font-size: 18px;
              @include break-point(mobile) {
                font-size: 16px;
              }
            }
            &:first-child {
              padding-right: 30px;
              border-right: 1px solid #f2f2f2;
              margin-bottom: 10px;

              @include break-point(mobile) {
                padding-right: 0;
                border-right: 0;
              }
            }
            &:last-child {
              padding-left: 30px;
              @include break-point(mobile) {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .chart_wrapper {
    display: flex;
    justify-content: space-between;
    @include break-point(miniTab) {
      flex-wrap: wrap;
    }
    ._chart {
      width: 100%;
      max-width: 49%;
      @include break-point(miniTab) {
        max-width: 100%;
        margin-bottom: 10px;
      }
      .my_score {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          padding-right: 15px;
          border-right: 2px solid;
          &:first-child {
            font-weight: bold;
            border-right: 0;
          }
          &:last-child {
            border-right: 0;
          }

          a {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

// Lecture cards //

.daily_lecture {
  .lecture_card {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    border-radius: 16px;
    margin: 10px;
    border: 1px solid #ecf0f7;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    @include break-point(mobile) {
      margin: 10px 0;
    }

    .image_Wrap {
      width: 100%;
      border-radius: 16px;
      overflow: hidden;
      height: 180px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include transition;

        &:hover {
          // transform: scale(1.02);
        }
      }

      .live_tag {
        position: absolute;
        color: $white-color;
        background-color: $red-color;
        border-radius: 7px;
        padding: 3px 15px;
        top: 10px;
        left: 10px;
        font-size: 14px;
      }
    }
    .content {
      padding: 8px;
      padding-top: 15px;
      @include break-point(mobile) {
        font-size: 14px;
      }
      h5 {
        font-size: 18px;
        font-weight: 600;
        @include break-point(desktop) {
          font-size: 16px;
          min-height: 55px;
        }
      }
      p {
        font-weight: 500;
        @include break-point(desktop) {
          margin-bottom: 5px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          font-size: 20px;
          margin-right: 15px;
          @include break-point(desktop) {
            font-size: inherit;
            margin-right: 10px;
          }
        }
        &.disabled {
          color: $red-color;
        }
      }
    }
  }
  &.spacing {
    padding-bottom: 0;
  }
}

// End //

.D_flex {
  display: flex;
  justify-content: space-between;
  // padding-bottom: 20px;

  a {
    font-size: 16px;
    font-weight: 500;
    color: $red-color;
  }
}

.dashboard_button {
  color: $red-color;
  border: 1px solid $red-color;
  background-color: #fceeee;
  border-radius: 6px;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 14px;
  &.fill {
    background-color: $red-color;
    color: $white-color;
  }
  &.green {
    color: $green-color;
    border-color: $green-color;
    background-color: $light-green-color;
  }
  &.has_arrow {
    display: flex;
    align-items: center;
    @include break-point(mobile) {
      padding: 5px;
    }
    &.prev {
      svg {
        margin-right: 5px;
      }
    }
    &.next {
      svg {
        margin-left: 5px;
      }
    }
    &:first-child {
      margin-right: 15px;
      @include break-point(mobile) {
        margin-right: 5px;
      }
    }
    &:last-child {
      @include break-point(mobile) {
        margin-right: 5px;
      }
    }
  }
  // disable button
  &:disabled {
    cursor: not-allowed;
    outline: none;
    opacity: 0.5;
  }
}

.dashboard_banner {
  background-color: $purple-color;
  background-image: url("../../assets/images/lectureBanner.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 900px;
  min-height: 200px;
  color: #fff;
  border-radius: 12px;
  margin-top: 20px;
  @include break-point(landscapeTab) {
    margin: 0;
    border-radius: 0;
    background-size: 750px;
  }
  @include break-point(mobile) {
    min-height: 145px;
    padding: 15px 0;
    background-image: none !important;
  }
  &.container {
    max-width: calc(1440px - 30px);
    @include break-point(desktop) {
      max-width: calc(1250px - 30px);
    }
  }
  .content {
    padding: 0;
    padding-right: 20px;
    width: 100%;
    max-width: 600px;
    @include break-point(landscapeTab) {
      max-width: 450px;
    }
    @include break-point(miniTab) {
      padding: 0;
    }
    h3 {
      color: $white-color;
    }

    .button_wrap {
      padding: 20px 0;
      display: flex;
      align-items: center;
      @include break-point(mobile) {
        flex-direction: column;
      }
      button {
        color: $white-color;
        background-color: #626fb4;
        border-color: #626fb4;
        display: flex;
        align-items: center;
        @include break-point(mobile) {
          width: 100%;
          text-align: center;
          display: block;
        }
        &:first-child {
          margin-right: 10px;
          @include break-point(mobile) {
            margin: 0 0 10px 0;
          }
        }
        svg {
          margin-right: 7px;
        }
      }
    }
  }
  &.text_banner {
    min-height: unset;
    background-image: unset;
    padding-top: 5px;
    padding-bottom: 5px;

    h3 {
      color: $white-color;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }
}

.meeting_content {
  // overflow-x: auto;
  ul {
    @include break-point(tablet) {
      overflow-x: auto;
    }
    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      align-items: center;
      background-color: $white-color;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      @include break-point(miniTab) {
        min-width: 750px;
      }
      img {
        border-radius: 50%;
        height: 50px;
        object-fit: cover;
        width: 50px;
        margin-right: 10px;
      }

      h6 {
        color: $blue-color;
        cursor: pointer;
        a {
          display: block;
        }
        @include break-point(mobile) {
          font-size: 15px;
        }
      }

      .date {
        width: 400px;
        padding-right: 20px;

        @include break-point(desktop) {
          font-size: 13px;
        }
        &:last-child {
          padding-right: 0;
        }
        span {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
  &.group_meeting {
    width: 100%;
    ul {
      li {
        padding: 4px 0;
        h6 {
          margin: 0;
        }
      }
    }
  }
}

// Dashboard //
.dashboard {
  .meeting_content {
    ul {
      li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        align-items: center;
        border-bottom: 1px solid #aaaaaa38;
        h6 {
          //   padding: 0 25px;
          width: 100%;
          font-weight: bold;
          max-width: 500px;
          margin: 0;
          padding-right: 25px;
        }
        .date {
          width: 260px;
          padding-right: 20px;
          &:last-child {
            padding-right: 0;
          }
          span {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .dashboard_tab {
    .nav-pills {
      display: flex;
      justify-content: center;

      @include break-point(mobile) {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: unset;
      }
      .nav-item {
        margin-right: 5px;
        margin-bottom: 5px;
        margin-top: 5px;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: inherit;
          border: 1px solid #b6c6e0;
          @include break-point(mobile) {
            white-space: nowrap;
          }
          &.active {
            background-color: $red-color;
            color: $white-color;
            border-color: $red-color;
          }
        }
      }
    }
    .tab-content {
      padding-top: 30px;
      @include break-point(mobile) {
        padding-top: 0;
      }
      h5 {
        font-weight: bold;
      }
    }
  }
}
.disabled {
  color: darkgray; /* or any other style to visually indicate disabled state */
  /* Add any other styles as needed */
}
