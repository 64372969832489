@import "../../scss/index.scss";

.payment_status {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 140px;
    text-align: center;
    color: $red-color;
  }
  &.success{
    .icon{
      color: $green-color;
    }
  }
  &.failed{
    h3{
      color: $red-color;
    }
  }
  .content{
    padding: 85px 35px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
    .image_wrap{
      text-align: center;
      margin-bottom: 35px;
      img{
        width: 100%;
        max-width: 165px;
        margin: 0 auto;
      }
    }
    p{
      a{
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}
