@import "../../scss/index.scss";


.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(73, 113, 178, 0.05) !important;
    width: 300px;
    margin-left: 35px;
    border: 1px solid rgba(223, 214, 229, 0.6);
    border-radius: 16px;
    font-size: 15px;
    @include break-point(landscapeTab) {
        display: none;
    }

    @include break-point(tablet) {
        display: none;
    }

    @include break-point(mobile) {
        display: none;
    }
}

/* FreeResources.scss */
.arrow-icon {
    font-size: 1.5rem;
    /* Increase the size of the arrow icon */
    float: right;
    /* Push the icon to the right */
}

.arrow-iconright {
    font-size: 1.5rem;
    /* Increase the size of the arrow icon */
    float: left;
    /* Push the icon to the right */
}

.mt-4 {
    flex-wrap: nowrap;
}

.nav-scroll-container-free-resources {
    max-height: 80vh; // Adjust this height as necessary
    overflow-y: auto; // Enable vertical scrolling
    padding-right: 20px; // Increase right padding for scrollbar space
    margin-right: -5px; // Negative margin to shift the content left, allowing the scrollbar to appear more centered


    // Navbar customization
    .custom-nav {
        .nav-link {
            color: rgba(0, 0, 0, 0.7);
            background-color: rgba(73, 113, 178, 0.05) !important;
            border-radius: 10px; // Changed border-radius position
            padding: 15px 28px;
            margin-bottom: 7px;
            font-size: 15px;
            font-weight: 500;

            &:hover {
                background-color: rgb(78 121 190 / 15%) !important; // Darker red on hover
            }

            &:focus {
                box-shadow: none; // Remove the focus shadow
            }
        }

        .active-nav {
            background-color: rgb(78 121 190 / 15%) !important; // Background for active nav
            color: rgba(0, 0, 0, 0.7) !important; // Font color for active nav
            font-weight: bold;
            border: 1px solid rgba(223, 214, 229, 0.6);
            border-radius: 7px;
            font-size: 15px;
        }

        // Active state for nav pills
        .nav-pills {
            .nav-link.active,
            .nav-pills .show > .nav-link {
                color: rgba(0, 0, 0, 0.7) !important;
                background-color: rgb(78 121 190 / 15%) !important;
            }
        }
    }

    .imgFreeResources {
        width: 23px;
    }

    // Commented out SVG style
    // svg {
    //     opacity: 0.7;
    // }
}


// Optional: To add some custom styling for scrollbar in Webkit browsers
.nav-scroll-container-free-resources::-webkit-scrollbar {
    width: 3px;
}

.nav-scroll-container-free-resources::-webkit-scrollbar-thumb {
    background-color: #888; // Customize the scrollbar color
    border-radius: 4px;
}

.nav-scroll-container-free-resources::-webkit-scrollbar-thumb:hover {
    background-color: #555; // Change color on hover
}