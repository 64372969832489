@import "../../scss/index.scss";

.free_resources {
	padding-bottom: 30px;
	.banner {
		background-image: url("../../assets/images/freeresourcebg.png");
		background-position: 100% 100%;
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 450px;
		position: relative;

		@include break-point(tablet) {
			background-position: center;
			min-height: 340px;
		}
		@include break-point(mobile) {
			min-height: 300px;
		}
		.banner_content {
			width: 100%;
			max-width: 600px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@include break-point(mobile) {
				max-width: 320px;
				padding: 0 10px;
			}
			h3{
				text-transform: uppercase;
			}
		}
	}

	.content_home {
		.subject_card_wrap {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			max-width: 1200px;
			margin: 0 auto;
			.subject_card {
				margin: 10px;
				text-align: center;
				background: rgba(73, 113, 178, 0.05);
				border-radius: 15px;
				padding: 20px;
				width: 100%;
				max-width: 275px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				position: relative;
				.link {
					display: none;
				}
				h5 {
					margin: 0;
					padding: 10px 0;
					color: $blue-color;
				}

				@include break-point(mobile) {
					max-width: 44%;
					padding: 20px 5px;
					margin: 10px 5px;
					.link {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: transparent;
					}
					.content {
						img {
							width: 40px;
						}
						h5 {
							padding: 5px 0;
							font-size: 18px;
						}
						p {
							margin: 0;
						}
					}
					.button_wrap {
						display: none;
					}
				}
			}
		}
	}

	.class_subjects {
		display: flex;
		justify-content: center;
		max-width: 900px;
		margin: 0 auto;
		flex-wrap: wrap;
		.item {
			padding: 15px;
			width: 100%;
			max-width: 150px;
			a {
				display: block;

				img {
					width: 120px;
					height: 120px;
					border-radius: 12px;
					transition: all 0.3s linear;
				}
				strong {
					padding: 10px 0;
					display: block;
					text-align: center;
				}
				&:hover {
					img {
						transform: scale(1.07);
					}
				}
			}
		}
	}

	.article_container {
		max-width: 1100px;
		margin: 0 auto;
		padding: 0 15px;

		@include break-point(mobile) {
			padding: 0 10px;
		}
	}

	.search_box {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
	}
}

.download_course {
	.download_links {
		width: 100%;
		max-width: 1030px;
		margin: 0 auto;
		.image_wrap {
			width: 100%;
			img {
				width: 100%;
			}
		}

		.content {
			// padding: 25px 0;
			ul {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 25px 0;
				font-size: 18px;
				font-weight: 500;
				@include break-point(miniTab){
					flex-direction: column;
					width: 100%;
					align-items: flex-start;
				}
				li {

					a {
						svg {
							margin-right: 3px;
						}
					}
					h4 {
						margin: 0;
						padding: 0;
					}
				}
			}
			.course_time {
				span {
					margin-right: 10px;
					color: #000;
					font-weight: 500;
					font-size: 16px;
					img {
						margin-right: 6px;
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
}

.course_pdf_listing {
	display: flex;
	flex-wrap: wrap;
	@include break-point(mobile) {
		justify-content: center;
	}
	.item {
		position: relative;
		width: 100%;
		max-width: 23%;
		background: $white-color;
		border: 1px solid rgba(223, 214, 229, 0.6);
		border-radius: 16px;
		padding: 20px 20px 20px 40px;
		margin-right: 15px;
		margin-bottom: 15px;
		overflow: hidden;
		min-width: 270px;
		flex: 1 1;
		max-width: 320px;
		@include break-point(tablet) {
			min-width: 30%;
		}
		@include break-point(miniTab) {
		}
		@include break-point(mobile) {
			min-width: 50%;
		}
		&:before {
			content: "";
			position: absolute;
			background-color: #faeadb;
			left: 0;
			top: 0;
			width: 30px;
			height: 100%;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
		}
		a {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			right: 0;
			background-color: transparent;
		}
			svg {
				position: absolute;
				right: 10px;
				top: 0;
				font-size: 20px;
				cursor: pointer;
			}
		h5 {
			margin-bottom: 0;
			font-weight: 500;
			font-size: 18px;
			
		}
		p {
			margin-bottom: 0;
		}
	}
}
