@import "../../../scss/index.scss";

.subsection-prelims-pyq-container {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 545px;
  overflow-y: auto;
  margin: -50px auto;

}

.question_block_prelims-pyq {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  line-height: 22px;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
}

.question_header_prelims-pyq {
  font-weight: 600;
  margin-bottom: 15px;
}
.pagetitle{
  color:#00239c;
  text-align: center;font-size: 26px;
  }
  .pquestion{
		color:#00239c;
		}
.options_container_prelims-pyq {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between options */
  margin-bottom: 10px;
}

.option_box {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f1f1f1;
  }
}

.toggle_answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  color: #4971b2;
  font-weight: 600;

  &:hover {
    color: #0056b3;
  }

  svg {
    font-size: 18px;
  }
}

.answer_section_prelims-pyq {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #ddd;

  .correct_option {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .explanation {
    margin-top: 10px;
    word-wrap: break-word;
    white-space: normal;
    color: #00239c;
  }
}



