@import "../../../scss/index.scss";

.scrollheader header.header {
	position: fixed;
	top: 0;
	border-bottom: 1px solid #dfd6e5;
	transition: all 0.3s linear;
}

.header_wrapper.scrollheader {
	min-height: 88px;
}

.header {
	padding: 20px 0;
	z-index: 99;
	position: relative;
	transition: all 0.3s cubic-bezier(0.03, 0.82, 1, 1);
	background: #fff;
	width: 100%;
	border-bottom: 1px solid #aaaaaa59;
	@include break-point(desktop) {
		padding: 10px 0;
	}

	.container {
		@include d-flex;
	}

	.mobile_button {
		display: none;

		@include break-point(tablet) {
			display: block;
			padding: 5px 10px;
		}
		@include break-point(mobile){
			padding: 5px;
		}
	}

	.logo {
		width: 100%;
		max-width: 230px;
		text-align: center;

		a {
			display: block;

			img {
				width: 100%;
			}
		}

		@include break-point(desktop) {
			max-width: 200px;
		}

		@include break-point(landscapeTab) {
			max-width: 180px;
		}
	}

	.navigation {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		> ul {
			padding: 0 20px;
			display: flex;
			align-items: center;

			@include break-point(desktop) {
				// padding: 0 5px;
			}

			@include break-point(tablet) {
				display: none;
			}

			li {
				// position: relative;
				a.nav_link {
					display: flex;
					align-items: center;
					font-size: 16px;
					font-weight: 500;
					padding: 5px 18px;
					color: $nav-color;
					position: relative;
					@include break-point(desktop) {
						font-size: 15px;
					}

					@include break-point(largeTab) {
						font-size: 14px;
					}

					.icon {
						display: flex;
						align-items: center;
						padding-left: 10px;
					}

					&::before {
						border-bottom: 25px solid hsla(0deg, 0%, 67%, 0.278);
						border-left: 16px solid transparent;
						border-right: 16px solid transparent;
						border-top: 0;
						content: "";
						height: 0;
						left: 50%;
						position: absolute;
						top: 104%;
						width: 0;
						z-index: -1;
						transform: translateX(-50%);
						transition: all 0.3s cubic-bezier(0, -0.33, 0.58, 1);
						visibility: hidden;
						opacity: 0;
						filter: alpha(opacity=0);
					}
				}

				.megamenu {
					background: #fafafa;
					border: 1px solid #d6dbde;
					border-radius: 16px;
					box-shadow: 0 0 23px rgba(0, 0, 0, 0.1);
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
					filter: alpha(opacity=0);
					font-size: 16px;
					left: 0;
					margin: 0 auto;
					position: absolute;
					right: 0;
					top: 100%;
					transition: all 0.3s cubic-bezier(0, -0.33, 0.58, 1);
					visibility: hidden;
					opacity: 0;
					width: 100%;

					&::before {
						border-bottom: 25px solid hsla(0, 0%, 67%, 0.278);
						border-left: 16px solid transparent;
						border-right: 16px solid transparent;
						border-top: 0;
						content: "";
						height: 0;
						left: 25%;
						position: absolute;
						top: -19px;
						width: 0;
						z-index: -1;
						display: none;
					}

					.row {
						margin: 0;
					}

					.menu_left {
						background: #fafafa;
						padding: 0;
						border-top-left-radius: 16px;
						overflow: hidden;
						border-bottom-left-radius: 16px;
						max-height: 375px;
						overflow-y: auto;
						.nav_item {
							display: flex;
							align-items: center;
							padding: 20px 15px;

							img {
								width: 32px;
								height: auto;
							}

							p {
								padding-left: 20px;
								margin: 0;

								b {
									display: block;
									font-weight: 700;
									color: $title-color;
									font-size: 16px;
								}

								span {
									color: $text-color;
									font-size: 13px;
									line-height: 1.2;
								}
							}

							&.active,
							&:hover {
								background: rgba(73, 113, 178, 0.1);

								img {
									filter: grayscale(1%);
								}

								p {
									b {
										color: $blue-color;
									}
								}
							}
						}

						.nav_variant_wrap {
							display: flex;
						}
					}

					.menu_right {
						padding: 20px 50px;
						background-color: $white-color;
						border-top-right-radius: 16px;
						max-height: 375px;
						overflow-y: auto;
						.megamenu_content {
							height: 100%;
							.col {
								padding: 20px 35px;
							}

							h5 {
								border-bottom: 3px solid $blue-color;
								position: relative;

								&:after {
									content: "";
									background-image: url("../../../assets/icons/drop_down-arrow.svg");
									width: 15px;
									background-size: 12px;
									font-weight: 300;
									height: 20px;
									background-repeat: no-repeat;
									position: absolute;
									right: 0;
									transform: rotate(-90deg);
									top: 0;
									@include transition;
								}

								a {
									color: $blue-color;
									font-size: 16px;
									display: block;
									text-transform: uppercase;
								}

								span._title {
									color: $title-color;
									font-size: 16px;
									display: block;
									text-transform: uppercase;
									font-weight: 700;
								}

								span {
									color: $text-color;
									font-size: 14px;
									font-weight: 400;
								}
							}

							ul {
								li {
									position: relative;
									padding: 20px 0 20px 30px;

									&::before {
										content: "";
										background-image: url("../../../assets/icons/right-red-arrow.svg");
										width: 15px;
										background-size: 14px;
										font-weight: 300;
										height: 20px;
										background-repeat: no-repeat;
										position: absolute;
										left: 0;
										top: 25px;
										display: none;
									}

									a,
									span {
										color: $text-color;
										font-weight: 700;
									}
								}

								&._span {
									li {
										padding: 10px 0 10px 10px;

										&::before {
											top: 20px;
										}
									}
								}

								&._location {
									li {
										display: flex;
										align-items: center;

										&:before {
											display: none;
										}

										p {
											width: 23%;
											margin-bottom: 0;
										}

										a {
											padding: 5px 20px 5px 5px;
											position: relative;
											min-width: 126px;

											&:after {
												content: "";
												position: absolute;
												background-image: url("../../../assets/icons/blue-arrow.svg");
												right: 0px;
												background-repeat: no-repeat;
												background-size: 7px;
												width: 15px;
												height: 15px;
												top: 8px;
											}
										}
									}
								}

								&._exam {
									display: flex;
									align-items: center;
									flex-wrap: wrap;

									li {
										width: 32%;
										.dropdown {
											.dropdown-toggle {
												min-width: 90px;
												position: relative;
												color: $blue-color;
												border-color: $blue-color;
												background-color: transparent;
												word-wrap: normal;
												white-space: break-spaces;

												&:hover {
													background-color: $blue-color;
													color: $white-color;
												}
												svg {
													position: absolute;
													right: 3px;
													top: 14px;
												}
												&:after {
													display: none;
												}
											}
										}
										span {
											font-weight: 500;
										}
									}
								}
							}

							.button_wrap {
								padding: 50px 0 20px;
								text-align: center;

								.button {
									.icon {
										margin-left: 15px;
										@include transition;
										position: relative;
										top: -1px;
									}

									&:hover {
										.icon {
											transform: translateX(18px);
										}
									}
								}
							}
						}
					}

					.tab_content {
						padding: 0;
						text-align: center;
						background-color: $white-color;

						.menu_banner {
							padding: 20px;
							background-color: $blue-color;
							display: flex;
							align-items: center;
							text-align: center;
							color: $white-color;
							border-top-right-radius: 16px;
							overflow: hidden;

							.image_wrap {
								width: 100%;
								max-width: 40%;
							}

							.content {
								text-align: left;
								color: $white-color;

								span {
									padding-bottom: 10px;
								}

								h3 {
									color: $white-color;
									line-height: 1.2;

									@include break-point(landscapeTab) {
										font-size: 25px;
									}
								}
							}
						}

						p {
							font-weight: 700;
							font-size: 18px;
							width: 100%;
							max-width: 264px;
							margin: 0 auto;
							padding: 50px 0;

							.blue {
								color: $blue-color;
							}
						}

						.button_wrap {
							padding-bottom: 30px;

							.button {
								.icon {
									margin-left: 15px;
									@include transition;
									position: relative;
									top: -1px;
								}

								&:hover {
									.icon {
										transform: translateX(18px);
									}
								}
							}
						}
					}

					&.course {
						max-width: 1150px;

						&::before {
							left: 42%;

							@include break-point(largeTab) {
								left: 24%;
							}
						}
						.menu_right {
							.megamenu_content {
								display: flex;
								flex-wrap: wrap;
								.col {
									padding: 20px;
									width: 100%;
									max-width: 45%;
									flex: unset;
									h5 {
										&:after {
											right: -25px;
										}
									}
								}
							}
						}
					}

					&.exam {
						max-width: 900px;
						.menu_right {
							.megamenu_content {
								.col {
									padding-left: 0;
									padding-right: 0;
								}
							}
						}
						&::before {
							left: 53%;
							@include break-point(desktop) {
								left: 50%;
							}
							@include break-point(largeTab) {
								left: 25%;
							}
						}

						.menu_left {
							.nav_item {
								padding: 25px 15px;
							}
						}
					}

					&.resource {
						max-width: 1150px;

						&::before {
							left: 43%;
						}
					}

					&.current_affairs {
						max-width: 750px;
						right: 0;
						left: auto;
						&::before {
							left: 70%;
						}

						.menu_banner {
							.content {
								h3 {
									font-size: 27px;
								}
							}
						}
					}

					// Hover Menu //
					.menu-level-1 {
						margin-bottom: 5px;
						// position: relative;
						a {
							padding: 5px 10px;
						}

						ul {
							position: absolute;
							display: none;
						}

						&:hover {
							> .submenu-1 {
								display: block;
							}
						}
					}
				}

				&.active {
					> a {
						color: $blue-color;
					}
					> .megamenu {
						-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
						filter: alpha(opacity=100);
						opacity: 1;
						visibility: visible;

						// display: block;
						// animation: rotateMenu 400ms ease-in-out forwards;
						// transform-origin: top center;
					}
				}

				&.has-menu {
					&.active {
						a {
							&:before {
								-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
								filter: alpha(opacity=100);
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
		}

		.header_button {
			text-align: center;
			@include break-point(mobile){
				display: flex;
			}
			.button {
				text-align: center;
				@include break-point(landscapeTab) {
					padding: 10px 10px;
					font-size: 14px;
				}
				@include break-point(mobile){
					font-size: 13px;
					min-width: auto;
					padding: 5px 10px;
				}
			}
		}
	}

	// mobile Menu ////

	.mobile_menu {
		position: absolute;
		top: 100%;
		background-color: #fff;
		height: calc(100vh - 55px);
		left: 0;
		padding: 5px 30px;
		display: none;
		max-width: 300px;
		width: 100%;
		overflow-y: auto;
		padding-bottom: 30px;
		transform: translateX(-100%);
		transition: all 0.3s linear;

		@include break-point(tablet) {
			display: block;
		}

		.menu_title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 22px;

			h3 {
				font-size: 20px;
				margin: 0;
				line-height: 1.1;
				color: $blue-color;
			}
		}
		.mobile_menu_title{
			li{
				padding: 12px 0;
				a{
					font-weight: 500;
					color: rgba(0, 0, 0, 0.7);
					font-size: 15px;
				}
			}
		}

		.accordion {
			.accordion-item {
				border: 0;
				.accordion-header {
					padding: 12px 0;
					.accordion-button {
						padding: 0;
						font-size: 15px;
						background-color: transparent;
						border-bottom: 0;
						box-shadow: none;
						font-weight: 500;
						color: $blue-color;

						&.collapsed {
							background-color: transparent;
							color: $text-color;
						}

						&:after {
							background-size: 15px;
						}
					}
				}

				.accordion-body {
					padding: 0 10px;

					ul {
						padding-bottom: 10px;

						li {
							margin-bottom: 18px;

							a {
								width: 100%;
								color: $text-color;
								font-size: 14px;

								img {
									width: 22px;
									margin-right: 6px;
								}
							}
						}
					}
				}
			}
		}

		.button_wrap {
			padding-top: 15px;
		}
	}
}

.subHeader {
	background: #edf5ff;
	padding: 8px 15px;

	@include break-point(mobile) {
		padding: 5px 8px;

	}

	p {
		margin-bottom: 0;
		font-weight: 600;
		font-size: 14px;
		@include break-point(mobile){
			text-align: center;
			font-size: 13px;
			margin: 0 !important;
		}
		&.left-fingure {
			transform: scaleX(-1);
		}

		a {
			text-decoration: underline;
		}
	}

	.justify-content-center {
		@include break-point(mobile) {
			align-items: center;
		}
	}
}

.language_menu {
	width: 100%;
	max-width: 85px;
	display: flex;
	align-items: center;
	margin-left: 15px;

	img {
		width: 24px;

		@include break-point(largeTab) {
			width: 22px;
		}
	}

	select {
		border: navajowhite;
		font-size: 18px;
		font-weight: 600;
		opacity: 0.6;
		margin-left: 5px;
		border: none;
		outline: none;
		background-color: transparent;

		@include break-point(largeTab) {
			font-size: 16px;
		}
	}
}

.mobile_overlay {
	display: none;

	@include break-point(tablet) {
		display: block;
		width: 100%;
		height: 100vh;
		position: fixed;
		background-color: rgb(0 0 0 / 58%);
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s linear;
		top: 0;
		left: 0;
		right: 0;
		z-index: 2;
	}
}

.mobile_open {
	overflow: hidden;

	.mobile_overlay {
		visibility: visible;
		opacity: 1;
	}

	.header .mobile_menu {
		transform: translateX(0);
	}

	.subHeader {
		display: none;
	}
}

@keyframes rotateMenu {
	0% {
		transform: rotateX(-90deg);
	}

	70% {
		transform: rotateX(20deg);
	}

	100% {
		transform: rotateX(0deg);
	}
}

.subHeader1 {
	background: #edf5ff;
	padding: 5px -2px;

	@include break-point(mobile) {
		padding: 5px 8px;

	}

	p {
		margin-bottom: 0;
		font-weight: 600;
		font-size: 14px;
		@include break-point(mobile){
			text-align: center;
			font-size: 13px;
			margin: 0 !important;
		}
		&.left-fingure {
			transform: scaleX(-1);
		}

		a {
			text-decoration: underline;
		}
	}

	.justify-content-center {
		@include break-point(mobile) {
			align-items: center;
		}
	}
}
.hide-on-mobile {
	display: block;
  
	@include break-point(mobile) {
	  display: none;
	}
  }
.mobileHeader {
	display: none;
	background: #edf5ff;
}

@media (max-width: 768px) {

	.mobileHeader {
	  display: block;
	}
	
}

