@import "../../scss/index.scss";

.announcement_wrap {
	&.workshop_page {
		.common_banner {
			background-image: url("../../assets/images/announcement.png");
		}

		.annoucement {
			.filter_button_wrap {
				justify-content: flex-end;
				max-width: 1100px;
				margin: 0 auto;
			}
			.card_wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
                @include break-point(mobile){
                    justify-content: center;
                }
				.annoucement_card {
					margin: 10px;
					max-width: 30%;
					@include break-point(miniTab) {
						max-width: 235px;
						margin: 10px 5px;
					}
					@include break-point(mobile) {
						max-width: 320px;
					}
					h4 {
						@include break-point(miniTab) {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}
