@import "../../scss/index.scss";

.subjectPage {
	.common_banner {
		background-image: url("../../assets/images/subjectPage.png");
	}

	.aboutThe_subject {
		.aboutThe_subject_content {
			width: 100%;
			max-width: 991px;
			margin: 0 auto;
			p {
				text-align: center;
				a {
					display: block;
				}
			}
			.subjectSyllabus {
				background-color: #f2f6f9;
				border-radius: 16px;
				font-weight: bold;
				font-size: 18px;
				margin-top: 35px;
				@include break-point(miniTab) {
					margin-top: 25px;
				}
				p {
					svg {
						font-size: 22px;
						margin-right: 10px;
					}
					a {
						display: inline-block;
						text-decoration: underline;
					}
				}
			}
		}
	}
	.subjectBooks_subject {
		.slick-next,
		.slick-prev {
			top: 40%;
		}
		.item {
			img {
				margin: 0 auto;
				width: 100%;
			}
			p {
				text-align: center;
				margin: 5px 0;
				em {
					display: inline-block;
					padding-top: 5px;
				}
			}
		}
	}

	.subjectVideos {
		.subjectVideos_Slider {
			.subjectVideo_card {
				width: 100%;
				background: #ffffff;
				border: 1px solid #dfd6e5;
				border-radius: 16px;
				overflow: hidden;
				.image_wrap {
					width: 100%;
					height: 200px;
					position: relative;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.videoPlay_icon {
						position: absolute;
						width: 100%;
						height: 100%;
						right: 0;
						top: 0;
						background-color: #f1f1f175;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 30px;
						}
					}
				}
				.content {
					padding: 15px 14px;

					p {
						display: flex;
						margin: 15px 0;
						align-items: center;
						img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
	.subjectNotes {
		.tab_content {
			.nav-pills {
				justify-content: center;
				margin-bottom: 40px;
				.nav-item {
					a {
						color: $text-color;
						background-color: transparent;
						border-bottom: 2px solid transparent;
						border-radius: 0;
						font-size: 18px;
						font-weight: 500;
						&.active {
							background-color: transparent;
							color: $blue-color;
							border-color: $blue-color;
						}
					}
				}
			}

			.tab-content {
			}
		}
	}

	.subjectCourseListing {
		.listing_content {
			.course_card_section {
				.card_wrapper {
					justify-content: space-around;
					.listing_card {
						max-width: 30%;

						@include break-point(landscapeTab) {
							max-width: 32%;
							margin: 0 5px 20px;
						}
						@include break-point(tablet){
							max-width: 45%;
						}
						@include break-point (mobile){
							max-width: 100%;
						}
						.image_wrap {
							height: auto;
						}
					}
				}
			}
		}
	}
}
