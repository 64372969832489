@import "../../../scss/index.scss";


.previous_paper_section{
    .common_banner{
        background-image: url("../../../assets/images/previousbanner.png");
    }

    .filter_section{
        display: flex;
        width: 100%;

        .form_field{
            width: 100%;
            max-width: 300px;

            &:first-child{
                margin-right: 10px;
            }
        }
    }

    .tab_container{
        .tabNav{
            padding-bottom: 30px;
            .nav-item{
                a{
                    font-size: 20px;
                    color: $text-color;
                    padding: 12px 16px;
                    @include break-point(mobile){
                        font-size: 18px;
                    }
                    &.active{
                        background-color: $white-color;
                        color: $blue-color;

                        border-bottom: 3px solid $blue-color;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}