.ourcoursesscrollContainer {
  display: flex;
  gap: 20px;
  padding: 10px 0;
  justify-content: center;
  max-width: 95%;
  margin: 0 auto;
  margin-top: -10px;

  @media (max-width: 768px) {
      display: grid;
      gap: 20px;
      padding: 10px 0;
      justify-content: center;
      max-width: 95%;
      margin: 0 auto;
  }

  table {
      width: 100%;
      overflow: hidden; /* Hide overflow outside table bounds */
      
      tbody {
          display: flex;
          flex-direction: column;
          max-height: 800px; /* Set a fixed height for the scrolling area */
          animation: scrollCourses 15s linear infinite; /* Continuous scrolling animation */
      }

      tbody tr {
          display: block;
          width: 100%;
      }

      /* Pause the scrolling animation on hover */
      tbody:hover {
          animation-play-state: paused;
      }
  }
}

/* Define the scroll animation with smooth looping */
@keyframes scrollCourses {
  0% {
      transform: translateY(0); /* Start at the top */
  }
  100% {
      transform: translateY(-100%); /* Scroll to the end of the duplicated content */
  }
}

@media (max-width: 768px) {
  .ourcoursesscrollContainer table tbody {
      animation: scrollCourses 20s linear infinite; /* Adjust speed for mobile if necessary */
  }
}
