@import "../../scss/index.scss";

.animated_banner {
  padding-top: 40px;
  @include break-point(desktop) {
    padding-top: 10px;
  }
  @include break-point(mobile) {
    padding-top: 10px;
  }
  .container {
    display: flex;
    // justify-content: space-between;
    @include break-point(mobile) {
      flex-direction: column;
    }
    .image_section {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      width: 100%;
      max-width: 45%;

      @include break-point(landscapeTab) {
        padding-right: 15px;
        max-width: 50%;
      }
      @include break-point(mobile) {
        max-width: 100%;
        margin-bottom: 0;
        padding: 0;
      }

      .image_wrap {
        width: 100%;
        max-width: 85%;
        display: flex;
        align-items: center;
        // height: 500px;
        overflow: hidden;
        @include break-point(landscapeTab) {
          max-width: 100%;
          height: auto;
        }
        @include break-point(mobile) {
          padding: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-width: 90%;
          @include break-point(landscapeTab) {
            height: auto;
          }
          @include break-point(mobile){
            max-width: 100%;
          }
        }
      }
    }

    .content_section {
      width: 100%;
      max-width: 55%;
      font-size: 18px;
      font-weight: 600;
      max-width: 600px;
      @include break-point(desktop){
        font-size: 16px;
      }
      @include break-point(landscapeTab) {
        max-width: 50%;
      }

      @include break-point(mobile) {
        max-width: 100%;
        text-align: center;
      }

      span.text {
        color: #555555;
      }

      h1 {
        // text-transform: capitalize;
        width: 100%;
        // max-width: 600px;
        font-size: 72px;
        line-height: 1.2;
        padding: 14px 0;

        @include break-point(desktop) {
          font-size: 60px;
        }

        @include break-point(tablet) {
          font-size: 35px;
        }
        @include break-point(mobile) {
          font-size: 30px;
        }
      }

      p {
        padding-bottom: 15px;
        color: $blue-color;
        @include break-point(mobile) {
          margin-bottom: 0;
        }
      }

      .button_wrap {
        .button {
          &:first-child {
            margin-right: 30px;

            @include break-point(tablet) {
              margin: 0 0 5px 0;
            }
          }

          @include break-point(tablet) {
            min-width: 235px;
          }
          @include break-point(mobile) {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

////////////////////// Banner ///////////////////////////
.home_banner {
  .slick-slider {
    .slick-slide {
      padding: 0;
    }
    .slick-dots {
      padding-top: 0;
    }
  }
  .banner_image {
    width: 100%;
    height: 33rem;
    @include break-point(mobile) {
      height: auto;
    }
    img {
      width: 100%;
    }
  }
}

// .Home .our_courses {
// 	display: none;
// }

////////////////////// Exam Courses ///////////////////////////

.exam_courses {
  background-image: url("../../assets/images/RectangleBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;

  @include break-point(landscapeTab) {
    background-color: #fffbf4;
  }
}
.humanity_courses {
  background-color: #eaf9f566;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;

  @include break-point(landscapeTab) {
    background-color: #eaf9f566;
  }
}

////////////////////// Courses Categories  ///////////////////////////

.courses_categories {
  .coursecard_wrap {
    .upcom_course {
      text-align: center;
      padding-top: 55px;

      @include break-point(mobile) {
        padding-top: 25px;
      }
    }
  }
}

////////////////////// Daily Quiz/////////////////////////////

.daily_quiz {
  background: #252d34;
  color: $white-color;
  margin-top: 20px;
  @include break-point(tablet) {
    margin: 10px 0;
  }
  .daily_quiz_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @include break-point(mobile) {
      flex-wrap: wrap;
    }
    .content {
      text-align: center;
      @include break-point(mobile) {
        width: 68%;
      }
      h4 {
        color: $white-color;
        font-size: 30px;

        @include break-point(mobile) {
          font-size: 22px;
          padding-bottom: 0;
        }
      }

      p {
        font-size: 18px;
        margin: 0;

        @include break-point(mobile) {
          font-size: 14px;
        }
      }
    }

    .image_wrap {
      position: relative;
      width: 190px;
      height: 200px;
      position: relative;
      @include break-point(mobile) {
        height: auto;
        width: 30%;
        position: relative;
        top: 20px;
      }
      // &:after {
      //   content: "";
      //   background-image: url("../../assets/images/profilebg.png");
      //   background-repeat: no-repeat;
      //   position: absolute;
      //   height: 100%;
      //   width: 100%;
      //   bottom: -16px;

      //   @include break-point(mobile) {
      //     right: 0;
      //     left: 0;
      //     width: 100%;
      //     bottom: -4px;
      //     background-size: 100% 100%;
      //   }
      // }

      img {
        width: 100%;
        // position: absolute;
        // top: -48px;

        @include break-point(mobile) {
          position: static;
          width: 100%;
        }
      }
    }
    .button_wrap {
      @include break-point(mobile) {
        width: 100%;
        text-align: right;
        margin-right: 67px;
      }
    }
  }
}

////////////////////// Number data /////////////////////////////

.number_data {
  background-color: #4971b2;
  @include break-point(mobile){
    padding: 0;
  }
  .number_data_content {
    ul {
      display: flex;
      width: 100%;
      @include break-point(mobile) {
        flex-direction: column;
      }
      li {
        width: 100%;
        max-width: 32%;
        flex: 1 1 auto;
        text-align: center;
        padding: 0 20px;
        color: $white-color;
        @include break-point(tablet) {
          padding: 0 15px;
        }
        @include break-point(mobile) {
          max-width: 100%;
          padding: 10px 10px;
          border-bottom: 1px solid $white-color;
        }
        h3 {
          color: $white-color;
          text-align: center;
          @include break-point(tablet) {
            font-size: 25px;
            margin: 0;
          }
          @include break-point(mobile){
            font-size: 16px;
          }
        }

        span {
          font-size: 18px;
          @include break-point(tablet) {
            font-size: 15px;
          }
          @include break-point(mobile){
            font-size: 14px;
          }
        }
      }
    }
  }
}

/* ////////////////////// self Assessment test /////////////////// */

.self_assessment {
  .container {
    background: #fff1f1;
    border-radius: 16px;
    max-width: 1200px;

    @include break-point(desktop) {
      max-width: 1270px;
    }
  }

  .assessment_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 30px 10px;
    @include break-point(mobile) {
      flex-direction: column;
      align-items: center;
    }

    .content {
      text-align: center;

      h5 {
        margin-bottom: 0;
        font-size: 25px;

        @include break-point(miniTab) {
          font-size: 20px;
        }
      }
    }
  }
}

/* ////////////////////// Blog and More /////////////////// */

.blog_more {
  .container {
    max-width: 1200px;
  }

  .blog_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include break-point(tablet) {
      flex-direction: column;
    }
    .content_left {
      width: 35%;
      border: 1px solid #dfd6e5;
      border-radius: 16px 0px 0px 16px;
      border-right: 0;
      @include break-point(tablet) {
        width: 100%;
        border: 0;
      }
      .blog_card {
        @include break-point(tablet) {
          border: 1px solid #dfd6e5;
          border-radius: 16px;
        }
        .image_wrap {
          width: 100%;
          height: 255px;

          @include break-point(mobile) {
            height: 210px;
          }
        }
      }
    }

    .content_right {
      width: 65%;
      border: 1px solid #dfd6e5;
      border-radius: 0 16px 16px 0;
      @include break-point(tablet) {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 16px;
        margin-top: 15px;
        border: 0;
      }
      .blog_card {
        display: flex;
        // align-items: center;
        justify-content: space-between;

        @include break-point(tablet) {
          flex-direction: column;
          border-radius: 16px;
          border: 1px solid #dfd6e5;
        }
        .image_wrap {
          width: 100%;
          max-width: 210px;

          @include break-point(mobile) {
            max-width: 100%;
            height: 210px;
          }
        }

        .content {
          padding-left: 50px;
          max-width: 600px;
          width: 100%;
          @include break-point(tablet) {
            padding-left: 15px;
            max-width: 100%;
          }
        }

        &:nth-child(odd) {
          @include break-point(tablet) {
            margin-bottom: 15px;
          }
          .content {
            order: 1;
            padding-left: 0;
            padding-right: 50px;

            @include break-point(tablet) {
              padding-right: 15px;
            }
          }

          .image_wrap {
            order: 2;

            @include break-point(tablet) {
              order: 1;
            }
          }

          border-bottom: 1px solid #dfd6e5;
        }
      }
    }

    .blog_card {
      padding: 20px;

      .image_wrap {
        border-radius: 16px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;
        @include break-point(tablet) {
          padding-top: 10px;
          padding-bottom: 5px;
        }
        h4 {
          position: relative;

          img {
            position: absolute;
            top: 7px;
            right: 0;
          }
        }
      }
    }

    .button_wrap {
      width: 100%;
      padding-top: 40px;

      @include break-point(mobile) {
        padding: 0;
      }
    }
  }
}

/* ////////////////////// Our Location /////////////////// */

.our_location {
  .our_location_inn {
    display: flex;
    justify-content: space-between;
    background: #ecf2fa;
    border-radius: 16px;
    @include break-point(miniTab) {
      flex-direction: column-reverse;
    }
    .content {
      width: 100%;
      max-width: 50%;
      padding: 60px;
      @include break-point(tablet) {
        padding: 25px 20px;
      }
      @include break-point(miniTab) {
        max-width: 100%;
        padding: 15px 10px;
      }
      h4 {
        margin-bottom: 0;
      }

      b {
        font-size: 20px;
        margin-bottom: 20px;
        display: inline-block;
      }

      p {
        width: 100%;
        max-width: 350px;
        margin: 0;
      }
      ul {
        li {
          display: flex;
          margin-bottom: 14px;
          .icon {
            margin-right: 10px;
            font-size: 24px;
            display: flex;
          }
        }
      }

      form {
        textarea {
          width: 100%;
          background: $white-color;
          border-radius: 4px;
          padding: 10px;
          min-height: 110px;
          border: 0;
          margin-top: 25px;
          outline: none;
        }
        .button_wrap {
          padding-top: 30px;
        }
      }
    }

    .map {
      width: 100%;
      max-width: 50%;
      position: relative;
      @include break-point(miniTab) {
        max-width: 100%;
        height: 350px;
      }

      .center_selector {
        position: absolute;
        top: 5px;
        left: 10px;
        z-index: 9;
      }
    }
  }
}
