@import "../../../scss/index.scss";

.testUnit {
	.testHeader_wrap {
		min-height: 75px;
	}
	.testHeader {
		background-color: $light-red-color;
		position: fixed;
		width: 100%;
		z-index: 99;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 15px;

			.content_right {
				display: flex;
				align-items: center;

				.logo {
					width: 100%;
					max-width: 191px;

					@include break-point(mobile) {
						display: none;
					}

					img {
						width: 100%;
					}
				}

				.duration {
					font-size: 18px;
					font-weight: 500;
					width: 200px;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;

					@include break-point(mobile) {
						width: 110px;
						border: 1px solid $blue-color;
						color: $blue-color;
						border-radius: 8px;
					}

					svg {
						color: $blue-color;
						margin-right: 7px;
						font-size: 20px;
					}
				}
			}
		}
	}

	.testUnit_content {
		.container {
			display: flex;
			justify-content: flex-start;

			@include break-point(mobile) {
				flex-direction: column;
			}
		}

		.question_content_wrap {
			width: 100%;
			max-width: calc(100% - 375px);
			@include break-point(tablet) {
				max-width: calc(100% - 340px);
			}
			@include break-point(mobile) {
				max-width: 100%;
			}

			.question_content {
				.questionNumber_wrap {
					min-height: 45px;
					position: relative;
					@include break-point(mobile) {
						z-index: 99;
					}
					&:before {
						content: "";
						position: fixed;
						width: 100%;
						background-color: $white-color;
						bottom: 100%;
						height: 55px;
						top: 75px;

						@include break-point(mobile) {
							top: 70px;
						}
					}
				}
				.questionNumber {
					color: $red-color;
					display: flex;
					justify-content: space-between;
					position: fixed;
					width: calc(100% - 430px);
					background-color: $white-color;
					@include break-point(mobile) {
						left: 0;
						right: 0;
						padding: 0 10px;
					}
					select {
						border-radius: 6px;
						border-color: $red-color;
						color: $red-color;
						padding: 2px 9px;
						font-size: 13px;
						outline: none;
					}
				}

				.question {
					b {
						margin-right: 10px;
					}
				}

				.ansOption {
					padding: 20px 0;
					width: 100%;
					max-width: 800px;
					display: flex;
					flex-wrap: wrap;

					@include break-point(miniTab) {
						flex-direction: column;
					}

					li {
						width: 100%;
						max-width: 40%;
						padding-right: 20px;
						margin: 20px 0;

						@include break-point(miniTab) {
							max-width: 100%;
							margin: 10px 0;
						}

						input {
							opacity: 0;
							visibility: hidden;
							width: 0;
						}

						label {
							font-weight: 500;
							border: 1px solid #aaaaaa52;
							padding: 7px 20px;
							border-radius: 4px;
							min-width: 190px;
							cursor: pointer;

							span {
								margin-right: 15px;
							}
						}

						// input[type="radio"]:checked ~ label {
						//   // background-color: $light-red-color !important;
						//   color: $green-color !important;
						//   border-color: $green-color !important;
						// }
						label.checked {
							color: $green-color !important;
							border-color: $green-color !important;
						}
					}
				}
			}

			.button_section {
				padding: 30px 0;
				display: flex;
				justify-content: space-between;
				@include break-point(mobile) {
					flex-direction: column;
				}

				.button_wrap {
					.dashboard_button {
						margin: 5px;
						min-width: 120px;
						&:last-child {
							margin-right: 0;
						}

						&.green {
							background-color: $light-green-color;
						}

						&.blue {
							background-color: $light-blue-color;
							color: $blue-color;
							border-color: $blue-color;
						}
					}
				}
			}
		}

		.question_attempt {
			width: 100%;
			max-width: 340px;
			background-color: #f5f7fb;
			padding: 15px 20px;
			border-radius: 8px;
			height: calc(100vh - 137px);
			overflow: auto;
			position: fixed;
			right: 0;
			// top: 0;

			@include break-point(mobile) {
				margin: 0 auto;
				max-width: 100%;
				position: static;
			}

			.legend {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				li {
					width: 100%;
					max-width: 45%;
					padding-bottom: 10px;
					display: flex;
					align-items: center;

					span {
						width: 20px;
						height: 20px;
						border-radius: 4px;
						margin-right: 10px;

						&.Attempted {
							background-color: #75ed7e;
						}

						&.Review {
							background-color: #ff9d00;
						}

						&.Skip {
							background-color: #a2a2a2;
						}
						&.Current {
							border: 1px solid #75ed7e;
						}
					}
				}
			}

			.attempt_status {
				padding: 15px 0;

				ul {
					padding-top: 20px;

					li {
						display: inline-block;
						width: calc(100% / 7);
						text-align: center;
						padding: 10px 0;

						span {
							width: 30px;
							height: 30px;
							background-color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 5px;

							&.attempted {
								background-color: #75ed7e;
							}
							&.review {
								background-color: #ff9d00;
							}
							&.skip {
								background-color: #a2a2a2;
							}
							&.current {
								border: 1px solid #75ed7e;
							}
						}
					}
				}
			}
		}
	}
}

.endtestModal {
	.modal-dialog {
		max-width: 375px;

		.content {
			text-align: center;
			img {
				margin-bottom: 20px;
				width: 140px;
			}
			h4 {
				margin: 0;
				padding: 0;
				line-height: 1;
				font-size: 23px;
			}

			.score_button_wrap {
				display: flex;
				flex-wrap: wrap;

				.score_button {
					border: 1px solid #f1bebc;
					background-color: #fceeee;
					border-radius: 8px;
					width: 30%;
					margin: 5px;
					padding: 10px;
					min-height: 90px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					b {
						display: block;
					}
					&:first-child {
						width: 100%;
						max-width: 100%;
					}
					&.green {
						border-color: #75ed7e;
						background-color: #f0fdf1;
					}
					&.yellow {
						border-color: #ff9d00;
						background-color: #fff4e4;
					}
					&.white {
						border-color: #a2a2a2;
						background-color: #e7e7e7;
					}
				}
			}
		}

		.button_wrap {
			padding: 20px 0;
			display: flex;
			justify-content: space-between;

			.dashboard_button {
				min-width: 120px;
				&.active {
					background-color: $red-color;
					color: $white-color;
				}
			}
		}
	}
}
