@import "../../scss/index.scss";

// Demo Lancture //

.demo_lacture {
	.lacture_card_wrap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@include break-point(landscapeTab) {
			justify-content: flex-start;
		}
		.lacture_card {
			width: 100%;
			max-width: 31%;
			margin: 10px;
			border: 1px solid rgba(223, 214, 229, 0.6);
			border-radius: 16px;
			position: relative;
			padding: 20px 20px 20px 55px;
			@include break-point(landscapeTab) {
				max-width: 45%;
			}
			@include break-point(mobile) {
				max-width: 100%;
				padding: 10px 10px 10px 44px;
			}
			&:before {
				content: "";
				position: absolute;
				background-color: $light-yellow-color;
				left: 0;
				top: 0;
				width: 35px;
				height: 100%;
				border-top-left-radius: 16px;
				border-bottom-left-radius: 16px;
			}
			.content {
				h5 {
					@include break-point(mobile) {
						font-size: 18px;
					}
				}
				.tag_name {
					padding: 5px 10px;
					color: $white-color;
					background-color: $red-color;
					border-radius: 4px;
					font-size: 12px;
				}
				ul {
					display: flex;
					padding: 20px 0;
					li {
						display: flex;
						align-items: center;
						img {
							margin-right: 10px;
							width: 20px;
						}
						&:first-child {
							padding-right: 10px;
						}
					}
				}
			}
			&:nth-child(2) {
				&::before {
					background-color: $light-blue-color;
				}
			}

			&:nth-child(3) {
				&::before {
					background-color: $light-pink-color;
				}
			}
		}
	}
}
