
.token-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    max-width: 100%;
    margin-right: 8%;
    margin-left: 8%;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 20px;
  }
  .success-message {
    color: green;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .center-select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .notes {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .notes p {
    font-weight: bold;
  }
  
  .notes ul {
    padding-left: 20px;
  }
  
  .generate-token-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 2%;
  }
  
  .generate-token-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  #terms-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  #terms-checkbox {
    margin-right: 10px;
    margin-bottom: 5px;
    transform: scale(1.5);
    width: 10px;   
    height: 10px;  
    cursor: pointer;
  }
  