@import "../../scss/index.scss";

.learnwith_toppers {
	.top_educators {
		padding: 40px 20px;
		background: rgba(221, 93, 89, 0.1);
		border-radius: 16px;
		@include break-point(mobile){
			padding: 20px;
		}
		ul {
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-wrap: wrap;
			@include break-point(mobile){
				flex-direction: column;

			}
			li {
				@include break-point(mobile){
					margin-bottom: 15px;
				}
				h4 {
					padding-bottom: 0;
					text-transform: capitalize;
					@include break-point(tablet) {
						font-size: 23px;
						padding-bottom: 10px;
					}
				}
				p {
					// margin: 0;
				}
				&:nth-child(2) {
					padding: 0 15px;

					@include break-point(mobile){
						padding: 0;
						text-align: center;
					}
				}
			}
		}
	}
}
