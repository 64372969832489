@import "../../scss/index.scss";

.lecture_page {
  .daily_lecture {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .lecture_card {
      max-width: 270px;
      h5 {
        font-weight: 600;
      }
    }
  }
  ::-webkit-scrollbar {
    height: 4px;
  }

  .meeting_content {
    width: 100%;
    .search_bar_wrap {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .search_bar {
        max-width: 290px;
        @include break-point(mobile) {
          margin-bottom: 0;
        }
      }
    }
    ul {
      margin-top: 15px;
      li {
        margin-bottom: 20px;
        padding: 15px 10px;
        border-radius: 7px;
        @include break-point(miniTab) {
          width: 750px;
        }
        h6 {
          @include break-point(landscapeTab) {
            max-width: 340px;
          }
          @include break-point(tablet) {
            max-width: 275px;
          }
        }
        .date {
          width: 285px;
          text-align: center;
          @include break-point(desktop) {
            width: 250px;
          }
          @include break-point(landscapeTab) {
            width: 200px;
          }
          @include break-point(tablet) {
            width: 140px;
            padding-right: 10px;
          }
          &.red {
            color: $red-color;
          }
        }
        .play_btn {
          .play-icon {
            color: $green-color;
            border: 0px solid #dd5d59;
            background-color: #00aa442b;
            border-radius: 50%;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            width: 38px;
            font-size: 20px;
          }
        }

        .image {
          position: relative;
          svg {
            position: absolute;
            color: $blue-color;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 22px;
          }
          &.has_youtube {
            .icon {
              display: block;
              width: 72px;
              height: 39px;
              border: 1px solid $red-color;
              border-radius: 6px;
            }
            svg {
              color: $red-color;
            }
          }
        }
        .button {
          border: 1px solid $red-color;
          padding: 1px 18px;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.3;
          color: $red-color;
          display: inline-block;
          border-radius: 28px;
          background-color: $white-color;
          position: relative;
          overflow: hidden;
          z-index: 1;
          text-transform: capitalize;
          &:focus,
          &:active {
            color: $white-color;
          }
        }
        &.lecture-card.disabled {
          opacity: 0.6;
          .play_btn,
          h6 {
            color: inherit;
          }
          .date.red {
            color: inherit;
          }
        }

        &.heading {
          padding-top: 10px;
          padding-bottom: 10px;
          box-shadow: none;
          border-bottom: 1px solid #aaaaaa82;
          border-radius: 0;
          h6 {
            cursor: default;
          }
        }
      }
    }

    .onDemandLectures {
      display: flex;
      flex-wrap: wrap;
      .onDemandCard_wrap {
        width: calc(100% / 5);
        padding: 15px;

        @include break-point(tablet) {
          padding: 5px;
        }
        @media (max-width: 800px) {
          width: calc(100% / 3);
        }
        @include break-point(mobile) {
          width: calc(100% / 2);
          width: 100%;
        }
      }
      .onDemandCard {
        padding: 10px;
        border-radius: 12px;
        border: 1px solid #ecf0f7;
        width: 100%;
        max-width: 300px;
        position: relative;
        overflow: hidden;
        padding-bottom: 50px;
        @include break-point(mobile) {
          margin: 0 auto;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #0009;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 1;
          bottom: 0;
          @include transition;
          display: none;
        }
        .image_wrap {
          width: 100%;
          height: 150px;
          border-radius: 12px;
          position: relative;
          overflow: hidden;
          a {
            cursor: pointer;
            height: 100%;
            display: block;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .iconImg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: auto;
            width: 35px;
          }
          .serial_number {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #dd5d59;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            width: 28px;
            height: 28px;
            padding: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .content {
          padding-top: 5px;
          ul {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            li {
              text-align: center;
              margin: 0;
              border: 0;
              justify-content: center;
              align-items: center;
              background-color: transparent;
              box-shadow: unset;
              width: 100%;
              min-width: unset;
              svg {
                margin-right: 5px;
              }
              &.light_blue {
                svg {
                  color: $blue-color;
                }
              }
              &.light_green {
                svg {
                  color: $green-color;
                }
              }
            }
          }
        }
        .View_details {
          background-color: #fceeee;
          border-radius: 16px 16px 0 0;
          padding: 10px;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          bottom: 0;
          transform: translateY(115px);
          border-radius: 0;
          transition: all 0.3s linear;
          z-index: 9;
          .view_text {
            text-align: center;
            width: 100%;
            padding: 10px 0;
            color: $red-color;
            img {
              position: absolute;
              top: -15px;
              width: 29px;
              left: 47%;
              z-index: 9;
            }
            span {
              padding-left: 16px;
            }
          }
          ul {
            flex-direction: column;
            margin: 0;
            li {
              justify-content: space-between;
              border-radius: unset;
              border: 0;
              background-color: transparent;
              box-shadow: none;
              margin-bottom: 5px;
              padding: 0;
              width: 100%;
              min-width: unset;
              span {
                text-align: left;
                &.text {
                  width: 75px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }
              svg {
                color: $blue-color;
              }
            }
          }
          &:hover {
            transform: translateY(0);
            border-radius: 12px 12px 0 0;

            .view_text {
              img {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
  .subject_list {
    width: 100%;

    .nav-pills {
      flex-wrap: nowrap;
      margin-bottom: 10px;
      overflow-x: auto;
      .nav-item {
        .nav-link {
          white-space: nowrap;
        }
      }
    }

    .subject_name {
      display: flex;
      align-items: center;
      padding-top: 25px;
      h5 {
        margin: 0;
        padding: 0;
        margin-left: 15px;

        span {
          font-weight: 400;
          font-size: 14px;
          display: block;
        }
      }
      strong {
        font-size: 24px;
        font-size: 22px;
        color: #9c27b0;
        padding: 5px 10px;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: #ede7f6;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .listing {
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 15px 20px;
        margin: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 8px;
        background-color: $white-color;
        flex: 1 1 auto;
        position: relative;
        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
        }
      }
    }
  }
  .syllabus_paper {
    padding: 30px 0;
    ._content {
      padding-bottom: 25px;
    }
    ul {
      display: flex;
      li {
        border: 0;
        padding: 0;
        display: block;
        text-align: left;
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        min-width: unset;
        display: flex;
        .info-icon {
          display: inline-block;
          color: #4971b2;
          transition: all 0.3s linear;
          font-size: 20px;
          cursor: pointer;
        }
        h6 {
          padding-left: 0;
          padding-right: 0;
        }
        &:last-child {
          text-align: right;
          .red {
            color: $red-color;
          }
        }
      }
    }
  }
}
.hide-on-mobile {
  display: block;
}

.show-on-mobile {
  display: none;
}

/* Default styles for desktop */
.pagination_search_class {
  flex-direction: column; /* Default to column layout for mobile */
  padding: 10px;
}

.search_bar_wrap {
  // width: 275px; /* Adjust as needed */
  // margin-bottom: 10px; /* Space between search bar and pagination on mobile */
}

/* Mobile styles */
@media (max-width: 767.98px) {
  .pagination_search_class {
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .search_bar_wrap {
    width: 100%; /* Full width for the search bar */
    margin-bottom: 10px; /* Space between search bar and pagination */
  }

  .pagination_wrap {
    width: 100%; /* Full width for pagination */
  }
}



@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: block;
  }
}
@keyframes dot-flashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-dots {
  display: inline-block;
  width: 80px;
  height: 20px;

  .dot {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 0 4px;
    background-color: #fff;
    border-radius: 50%;
    animation: dot-flashing 1.5s infinite ease-in-out;
  }

  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
}

