@import "../../scss/index.scss";

.test_series {
	.dashboard_banner {
		background-image: url("../../assets/images/testSeries.png");
		background-image: none;
	}

	.lecture_content {
		.dashboard_tab {
			.tab-content {
				small {
					font-size: 65%;
				}
			}

			.daily_lecture {
				background: #f9fafc 0% 0% no-repeat padding-box;
				border: 1px solid #ecf0f7;
				border-radius: 16px;
				padding: 20px;
				margin-bottom: 20px;

				h6 {
					small {
						display: block;
					}
				}
			}

			.meeting_content {
				ul {
					li {
						background-color: $white-color;
						box-shadow: $box-shadow;
						flex-wrap: wrap;

						.content {
							display: flex;
							align-items: center;
							width: 100%;

							.img {
								width: 100%;
								max-width: 72px;
							}
						}

						.button_wrap {
							display: flex;
							align-items: center;
							padding: 20px 0;
							border-top: 1px solid #f1f1f1;
							width: 100%;
							margin-top: 10px;

							.dashboard_button {
								border-color: $blue-color;
								color: $blue-color;
								display: flex;
								align-items: center;
								margin-right: 15px;
								background-color: transparent;
								font-size: 12px;

								&[disabled] {
									opacity: 0.3;
								}

								&.blue {
									background-color: $blue-color;
									color: $white-color;
								}

								&.red {
									background-color: $red-color;
									color: $white-color;
									border-color: $red-color;
								}

								&:last-child {
									margin-right: 0;
								}

								svg {
									margin-right: 5px;
								}
							}

							&.test_button {
								flex-wrap: wrap;

								.dashboard_button {
									display: block;
								}
							}
						}

						&.test-card.disabled {
							opacity: 0.5;

							h6 {
								color: inherit;
							}

							.date.red {
								color: inherit;
							}
						}

						&.heading {
							box-shadow: none;
						}
					}
				}
			}

			&.subject_list {
				.nav-item {
					.nav-link.active {
						background-color: $blue-color;
						border-color: $blue-color;
					}
				}
			}
		}
	}
}

.hide-on-mobile {
	display: block;
}

.show-on-mobile {
	display: none;
}

@media (max-width: 768px) {
	.hide-on-mobile {
		display: none;
	}

	.show-on-mobile {
		display: block;
	}
}

@keyframes dot-flashing {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.loading-dots {
	display: inline-block;
	width: 80px;
	height: 20px;

	.dot {
		display: inline-block;
		width: 13px;
		height: 13px;
		margin: 0 4px;
		background-color: #fff;
		border-radius: 50%;
		animation: dot-flashing 1.5s infinite ease-in-out;
	}

	.dot:nth-child(2) {
		animation-delay: 0.3s;
	}

	.dot:nth-child(3) {
		animation-delay: 0.6s;
	}
}