@import "../../scss/index.scss";

.otherFree_resource {
	.subject_card {
		width: 100%;
		max-width: 250px;
		background: $white-color;
		border: 1px solid #dfd6e5;
		border-radius: 15px;
		padding: 20px;
		margin: 10px;
		min-height: 205px;
		@include break-point(tablet){
			padding: 10px;
		}
		@include break-point(mobile) {
			padding: 10px 5px;
			margin: 5px;
		}
		.content {
			text-align: center;

			img {
				margin: 0 auto;

				@include break-point(mobile) {
					width: 40px;
				}
			}

			h5 {
				color: $blue-color;
				margin: 0;
				padding: 10px 0;
				@include break-point(mobile) {
					padding: 5px 0;
					line-height: 1.2;
					font-size: 18px;
				}
			}
			P {
				margin: 0;
			}
		}
	}
}
