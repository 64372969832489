@import "../../../scss/index.scss";

.drawer {
  width: 100%;
  --bs-offcanvas-width: 481px;
  .offcanvas-title {
    font-weight: bold;
    font-size: 22px;
    padding: 0;
  }

  .offcanvas-body {
    .mycourses_listing {
      .course_label {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #181818;
        margin: 0;
      }
      hr {
        margin: 10px 0px;
        border: 1px solid $red-color;
      }
      ul {
        li {
          margin-bottom: 17px;
          position: relative;
          a {
            color: #181818;
            font-weight: 600;
            display: block;
            padding: 10px;
            border-radius: 8px;
            background-color: #fff5e8;
            border: 1px solid #ffa726;
            box-shadow: 0px 6px 24px #ffa7261a;
            span {
              // color: #5c5c5c;
              font-weight: 400;
              display: block;
              margin-top: 5px;
              font-size: 90%;
            }
            .upcomingTag {
              position: absolute;
              top: 1px;
              right: 1px;
              background-color: $green-color;
              color: $white-color;
              padding: 0 6px;
              border-radius: 2px;
              margin: 0;
              padding-bottom: 2px;
              font-size: 11px;
              border-top-right-radius: 6px;
            }

            .description_wrapper {
              display: flex;
              justify-content: space-between;
              margin-top: 5px;

              .postalTag {
                background-color: #00aa44;
                color: #fff;
                padding: 0 6px;
                margin: 0;
                padding-bottom: 2px;
                font-size: 12px;
                border-radius: 4px;
              }
            }
          }
          &:nth-child(1n + 0) {
            a {
              background-color: #fff5e8;
              border: 1px solid #ffa726;
              box-shadow: 0px 6px 24px #ffa7261a;
            }
          }
          &:nth-child(2n + 0) {
            a {
              box-shadow: 0px 6px 24px #66bb6a1a;
              border: 1px solid #66bb6a;
              background-color: #eef7ef;
            }
          }
          &:nth-child(3n + 0) {
            a {
              box-shadow: 0px 6px 24px #5c6bc01a;
              border: 1px solid #5c6bc0;
              background-color: #f0f2f9;
            }
          }

          &.active {
            a {
              background-color: $red-color;
              background-color: $blue-color;
              color: $white-color;
              border-color: $blue-color;

              span {
                color: inherit;
              }
            }
            .view_all {
              color: inherit;
            }
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            a {
              cursor: not-allowed;
            }
          }

          .view_all {
            border: 0;
            background-color: transparent;
            padding: 0;
            margin: 0;
            float: right;
            bottom: 13px;
            // position: relative;
            color: inherit;
            font-size: 91%;
            color: $blue-color;
          }
        }
      }

      // courses not available css
      .course-not-available {
        .container {
          height: 80vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }

  // Edit Profile //
  .form {
    h6 {
      color: $black-color;
      margin-bottom: 30px;
    }

    textarea.input {
      height: 120px;
    }
    .checkbox_wrap {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
    }
    .button_wrap {
      text-align: left;
      padding: 15px 0;
    }
  }

  // Help //
  &.help_offcanvas {
    --bs-offcanvas-width: 390px;
    h6 {
      padding-top: 25px;
    }
    ul {
      padding: 25px 0;

      li {
        display: flex;
        align-items: flex-start;
        a {
          color: inherit;
          &:hover {
            color: $red-color;
          }
        }
        span {
          color: $red-color;
          background-color: $light-red-color;
          padding: 3px 6px;
          margin-right: 12px;
          font-size: 18px;
          border-radius: 5px;
        }
      }
    }
  }
}
