@import "../../../scss/index.scss";

.PDFnotes {
	.banner {
		background-image: url("../../../assets//images/courseNotes.png");
	}

	.notes-section {
		.courseFor_class {
			display: flex;
			justify-content: center;
			.classNAme {
				background: rgba(221, 93, 89, 0.05);
				border-radius: 15px;
				padding: 30px 25px;
				text-align: center;
				margin: 15px;
				width: 100%;
				max-width: 260px;
				@include break-point(mobile) {
					padding: 20px 5px;
					margin: 10px 5px;
				}
				img{
					width: 45px;
				}
				h5 {
					margin: 0;
					padding: 10px 0;
					color: $red-color;
				}

				.button_wrap{
					@include break-point(mobile){
						.icon{
							display: none;
						}
					}
				}
			}
		}
	}

	.class_subjectpdf {
		// .course_pdf_listing {
		// 	display: flex;
		// 	flex-wrap: wrap;
		// 	.item {
		// 		position: relative;
		// 		width: 100%;
		// 		max-width: 23%;
		// 		background: $white-color;
		// 		border: 1px solid rgba(223, 214, 229, 0.6);
		// 		border-radius: 16px;
		// 		padding: 20px 20px 20px 40px;
		// 		margin-right: 15px;
		// 		margin-bottom: 15px;
		// 		overflow: hidden;

		// 		&:before {
		// 			content: "";
		// 			position: absolute;
		// 			background-color: #faeadb;
		// 			left: 0;
		// 			top: 0;
		// 			width: 30px;
		// 			height: 100%;
		// 			border-top-left-radius: 16px;
		// 			border-bottom-left-radius: 16px;
		// 		}
		// 		a {
		// 			position: absolute;
		// 			height: 100%;
		// 			width: 100%;
		// 			top: 0;
		// 			right: 0;
		// 			background-color: transparent;
		// 		}
		// 		svg {
		// 			position: absolute;
		// 			right: 10px;
		// 			top: 0;
		// 			font-size: 20px;
		// 			cursor: pointer;
		// 		}
		// 		h5 {
		// 			margin-bottom: 0;
		// 			font-weight: 500;
		// 			@include break-point(desktop) {
		// 				font-size: 18px;
		// 			}
		// 		}
		// 		p {
		// 			margin-bottom: 0;
		// 		}
		// 	}
		// }
	}
}
