/* General Styles */
.table {
  width: 100%;
  margin-bottom: 1rem;
  //border: 1px solid #4971b2;
}

.text-danger {
  font-weight: bold;
}

h6 {
  margin-bottom: 0.5rem;
  text-align: left;
}

tr th {
  width: 300px;
  text-align: left;
 // color: #7986cb;
}

th,td {
  padding: 0.5rem;
}

/* Responsive Layout Adjustments */
@media (max-width: 768px) {
  /* Stack columns vertically for smaller screens */
  .form-group.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  .col-sm-6,
  .col-sm-12 {
    width: 100%; /* Full width on small screens */
    padding: 0.5rem;
  }

  .order-1 {
    order: 1;
  }

  tr th{
    width: 50%;
  }
  /* Adjust text alignment for better readability */
  th,
  td {
    text-align: left !important;
  }
}

@media (max-width: 576px) {
  /* Reduce padding for extra small screens */
  th,
  td {
    padding: 0.3rem;
    font-size: 0.9rem;
  }
  tr th{
    width: 50%;
  }
  h6 {
    font-size: 1rem;
  }

  .table {
    border-spacing: 0; /* Remove spacing for compact view */
  }
}
